import React from 'react';
import useCalendars from './useCalendars';
import RemoteCalendar from './RemoteCalendar';
import { ResponsiveModal } from 'components/common/Modals';
import { Modal } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import CalendarPicker from './CalendarPicker';
import ActionFooter from 'components/common/ActionFooter';
import { useDbState } from '../users/hooks/useUserPreferences';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default () => {
  const { data: calendarIds, isLoading } = useCalendars({
    useFilter: true,
    select: c => c.map(c => c.id)
  });
  const [displayedCalendarIds, setDisplayedCalendarIds] = useDbState<number[]>({
    domain: 'calendar',
    key: 'calendarIds',
    defaultValue: calendarIds
  });
  const [showSettings, setShowSettings] = React.useState(false);
  const handleSettingsChange = ({ calendarIds }: { calendarIds: number[] }) => {
    setDisplayedCalendarIds(calendarIds);
  };
  return (
    <>
      <RemoteCalendar
        isLoading={isLoading}
        calendarIds={displayedCalendarIds || []}
        config={{
          buttons: [
            {
              children: <FontAwesomeIcon icon="cog" />,
              size: 'sm',
              variant: 'falcon-default',
              onClick: () => setShowSettings(true)
            }
          ]
        }}
      />
      <SettingsModal
        show={showSettings}
        setShow={setShowSettings}
        defaultValues={{
          calendarIds: displayedCalendarIds || []
        }}
        onSave={handleSettingsChange}
      />
    </>
  );
};
type Settings = {
  calendarIds: number[];
};
const SettingsModal = ({
  show,
  setShow,
  defaultValues,
  onSave
}: {
  show: boolean;
  setShow: (show: boolean) => void;
  defaultValues?: Settings;
  onSave: (values: Settings) => void;
}) => {
  const methods = useForm<Settings>({ values: defaultValues });
  return (
    <ResponsiveModal show={show} onHide={() => setShow(false)}>
      <Modal.Body>
        <FormProvider {...methods}>
          <CalendarPicker multiple name="calendarIds" label="Calendars" />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <ActionFooter
          submitText="Apply"
          onSubmit={methods.handleSubmit(vals => {
            onSave(vals);
            setShow(false);
          })}
          onCancel={() => setShow(false)}
        />
      </Modal.Footer>
    </ResponsiveModal>
  );
};
