import React from 'react';
import Flex from 'components/common/Flex';
import { getValueFormatter, useHeadlineFigure } from '../helpers';
import { ReportConfig } from 'apis/flex/reporting';
import classNames from 'classnames';
import { ChartPlaceholder } from '../ReportTile';

export default ({
  config,
  isLoading,
  children,
  height,
  simple,
  showHeadline = true
}: {
  config?: ReportConfig;
  isLoading?: boolean;
  children?: React.ReactNode;
  height?: number | string;
  simple?: boolean;
  showHeadline?: boolean;
}) => {
  const headline = useHeadlineFigure(config);
  const headlineFormatter = getValueFormatter(config);
  return (
    <Flex alignItems="end" justifyContent="between">
      {/* {simple && ( */}
      {showHeadline && (
        <div>
          <h2
            className="fw-normal text-700 mb-1 lh-1"
            style={{ whiteSpace: 'nowrap' }}
          >
            {headlineFormatter(headline)}
          </h2>
        </div>
      )}
      {/* )} */}
      <div className={classNames('ps-xl-4 ps-3 flex-1')}>
        {isLoading ? (
          <ChartPlaceholder height={height} simple={simple} />
        ) : (
          children
        )}
      </div>
    </Flex>
  );
};
