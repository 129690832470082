import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
import Avatar, { AvatarProps } from 'components/common/Avatar';
import { format } from 'date-fns';
import { domainConfigs } from './config';
import {
  Notification as NotificationType,
  NotificationRolledUp
} from 'apis/flex/notifications';
import UserIcons from 'components/app/users/widgets/UserIcons';
import SoftBadge from 'components/common/SoftBadge';
import Skeleton from 'react-loading-skeleton';

export const NotificationAvatar = ({
  notification,
  avatar
}: {
  notification: NotificationRolledUp | NotificationType;
  avatar?: Partial<AvatarProps>;
}) => {
  const authorIds =
    (notification && 'authorIds' in notification && notification?.authorIds) ||
    (notification?.authorId && [notification?.authorId]);
  const uniqueAuthorIds = Array.isArray(authorIds)
    ? Array.from(new Set(authorIds))
    : [authorIds];
  return (
    <div className="notification-avatar me-2">
      {uniqueAuthorIds?.length > 0 ? (
        <UserIcons ids={uniqueAuthorIds} size="xl" />
      ) : (
        !!notification &&
        !!domainConfigs[notification.domain] && (
          <Avatar
            size="xl"
            emoji={
              !!notification &&
              domainConfigs[notification.domain] &&
              domainConfigs[notification.domain].icon
            }
            className={classNames(
              !!notification &&
                domainConfigs[notification.domain] &&
                `text-${domainConfigs[notification.domain].color}`
            )}
            {...avatar}
          />
        )
      )}
    </div>
  );
};
export const NotificationPlaceholder = ({
  unread = false,
  flush = false,
  className = ''
}) => (
  <div
    className={classNames(
      'notification',
      {
        'notification-unread': unread,
        'notification-flush': flush
      },
      className
    )}
  >
    <div className="notification-avatar me-2">
      <Avatar size="xl" isLoading />
    </div>
    <div className="notification-body">
      <div className="d-flex">
        <p className="mb-1">
          <Skeleton width={200} />
        </p>
      </div>
      <span className="notification-time">
        <Skeleton width={100} />
      </span>
    </div>
  </div>
);
export const notificationEvents = {
  NEW_APPLICANT: 'applicant-added'
};
const Notification = ({
  avatar,
  className,
  notification,
  flush,
  children
}: {
  avatar?: any;
  className?: string;
  flush?: boolean;
  emoji?: string;
  children?: any;
  notification?: NotificationRolledUp;
}) => (
  <Link
    className={classNames(
      'notification',
      {
        'notification-unread': !notification?.actioned,
        'notification-flush': flush
      },
      className
    )}
    to={'/notifications/' + notification?.id + '/' + notification?.ids}
  >
    <NotificationAvatar notification={notification} avatar={avatar} />
    <div className="notification-body">
      <div className="d-flex">
        {notification?.count > 1 && (
          <span>
            <SoftBadge className="rounded circle me-2">
              {notification.count}
            </SoftBadge>
          </span>
        )}
        <p className="mb-1" dangerouslySetInnerHTML={createMarkup(children)} />
      </div>
      <span className="notification-time">
        {format(new Date(notification.sent), 'dd/MM/yyyy HH:mm')}
      </span>
    </div>
  </Link>
);

Notification.propTypes = {
  avatar: PropTypes.shape(Avatar.propTypes),
  className: PropTypes.string,
  actioned: PropTypes.any,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
  children: PropTypes.node,
  sent: PropTypes.any,
  domain: PropTypes.string,
  data: PropTypes.object,
  eventName: PropTypes.string,
  author: PropTypes.object
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;
