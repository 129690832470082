import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  NINumberInput,
  PhoneInput,
  PostcodeInput
} from 'components/hiring/application-form/steps/PersonalForm';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import { FormQuestion } from 'apis/flex/customForms';
import { validate } from 'helpers/validation/validate';

const CustomInputSelector = ({ inputName, questionProps }) => {
  const {
    name,
    label: rawLabel,
    instruction,
    pluginProps,
    disabled,
    flush
  } = questionProps;
  const label = <span dangerouslySetInnerHTML={{ __html: rawLabel }} />;
  switch (inputName) {
    case 'postcode':
      return (
        <PostcodeInput
          inputProps={{
            name,
            label,
            instruction,
            pluginProps,
            disabled,
            flush
          }}
          registerProps={questionProps.registerProps}
        />
      );
    case 'phone':
      return (
        <PhoneInput
          inputProps={{
            name,
            label,
            instruction,
            pluginProps,
            disabled,
            flush
          }}
          registerProps={questionProps.registerProps}
        />
      );
    case 'ni':
      return (
        <NINumberInput
          inputProps={{
            name,
            label,
            instruction,
            pluginProps,
            disabled,
            flush
          }}
          registerProps={questionProps.registerProps}
        />
      );
    default:
      return <WizardInput type={inputName} {...questionProps} label={label} />;
  }
};
CustomInputSelector.propTypes = {
  inputName: PropTypes.string,
  questionProps: PropTypes.object
};

export const getCustomQuestionValidators = question => {
  return {
    min: question.min
      ? {
          value: question.min,
          message: 'Must be at least ' + question.min
        }
      : undefined,
    max: question.max
      ? {
          value: question.max,
          message: 'Cannot be more than ' + question.max
        }
      : undefined,
    required: { value: question.isRequired, message: 'Required' },
    minLength: {
      value: question.minLength,
      message: 'Must be at least ' + question.minLength + ' characters long.'
    },
    maxLength: {
      value: question.maxLength,
      message: 'Cannot be longer than ' + question.maxLength + ' characters.'
    },
    pattern: question.pattern && {
      value: new RegExp(question.pattern),
      message:
        question.patternError ||
        'Not valid. Please check your response and try again'
    }
  };
};
const CustomWizardInput = ({
  question,
  inputProps
}: {
  question: FormQuestion;
  inputProps?: WizardInputProps;
}) => {
  const pluginProps = question.pluginProps || ({} as any);
  switch (question.inputType) {
    case 'video':
    case 'audio': {
      pluginProps.capture = question.mediaCapture;
      pluginProps.upload = question.mediaUpload;
      break;
    }
    case 'file': {
      pluginProps.accept =
        question.accept &&
        question.accept.reduce((a, b) => {
          a[b] = [];
          return a;
        }, {});
      break;
    }
    case 'name': {
      pluginProps.middle = question.nameMiddle;
      break;
    }
    case 'range': {
      pluginProps.min = question.min;
      pluginProps.max = question.max;
      break;
    }
    case 'shifts': {
      pluginProps.shifts = question.shifts;
      break;
    }
    case 'transcribe': {
      pluginProps.src = question.audioFile;
      pluginProps.answer = question.audioTranscript;
      pluginProps.maxMediaPlays = question.maxMediaPlays;
      break;
    }
    case 'interview': {
      pluginProps.prompts = question.promptFiles?.flat() || [];
      break;
    }
    case 'speaking': {
      pluginProps.prompt = question.audioTranscript;
      break;
    }
    default:
      break;
  }
  const props = useMemo(
    () => ({
      name: question.id.toString(),
      label: question.questionText,
      instruction: (
        <div dangerouslySetInnerHTML={{ __html: question.instructions }} />
      ),
      options: question.options || [],
      ...inputProps,
      flush: false,
      formGroupProps: {
        noMb: true,
        className: 'mb-4',
        ...inputProps?.formGroupProps
      },
      registerProps: {
        // shouldUnregister: true,
        ...getCustomQuestionValidators(question),
        validate: v =>
          validate.trueIfAllPass(
            question.validation,
            () => question.inputType,
            v
          ),
        ...inputProps?.registerProps
      },
      pluginProps
    }),
    [question, inputProps]
  );
  return (
    <CustomInputSelector inputName={question.inputType} questionProps={props} />
  );
};
CustomWizardInput.propTypes = {
  question: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    inputType: PropTypes.string,
    questionText: PropTypes.string,
    instructions: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
      ])
    ),
    isRequired: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    pattern: PropTypes.any,
    patternError: PropTypes.string,
    validation: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.any, type: PropTypes.string })
    ),
    accept: PropTypes.array,
    shifts: PropTypes.object,
    mediaCapture: PropTypes.bool,
    mediaUpload: PropTypes.bool,
    nameMiddle: PropTypes.bool
  }).isRequired,
  inputProps: PropTypes.object
};
export default CustomWizardInput;
