import React, { useMemo } from 'react';
import useAppRoles from './useAppRoles';
import useUserRoles from 'hooks/useUserRoles';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch
} from 'react-hook-form';
import WizardInput, {
  WizardInputOptions,
  WizardInputProps
} from 'components/wizard/WizardInput';
import { startCase, truncate, uniqBy } from 'lodash';
import LoadingButton from 'components/common/LoadingButton';
import { UserView } from 'apis/flex/users';
import { replaceHandlebarsWithValues } from 'helpers/strings';
import useAccessGroups from './useAccessGroups';
import WithFormValues from 'components/wizard/WithFormValues';
import { useGuard } from 'hooks/useGuard';
import { AccessGroupRole, AppRole } from 'apis/flex/auth';
import useUsers from 'components/app/users/hooks/useUsers';
import { Accordion, Button, Col, Row } from 'react-bootstrap';
import DomainIcon from 'components/common/DomainIcon';
import Search from 'components/common/Search';
import Skeleton from 'react-loading-skeleton';
import Flex from 'components/common/Flex';
import CustomTooltip from 'components/common/Tooltip';
import { NamePrefix } from 'components/common/DomainInput';
import { PermissionBadge } from '../../accessGroups/AccessGroupsTable';
import { domainToSentence } from 'components/common/DomainTimeline';
import { EventPrefix } from 'apis/flex/notifications';
export const AccessGroupSelect = (props: Partial<WizardInputProps>) => {
  const { data: options } = useAccessGroups<WizardInputOptions>({
    useFilter: true,
    getPublic: true,
    select: d =>
      d
        .map(a => ({
          value: a.id,
          label: a.name,
          description: truncate(a.description, { length: 100 }),
          exclusive: undefined
        }))
        .concat([
          { value: 0, label: 'Custom', description: null, exclusive: true }
        ])
  });
  const { isAdmin } = useGuard({ roles: ['user'] });
  return (
    <WizardInput
      name="accessGroups"
      type="select"
      label="Flex Roles"
      multiple
      readOnly={!isAdmin}
      options={options}
      {...props}
    />
  );
};
export const useAppRoleOptions = () => {
  const { data: roles = [], isLoading } = useAppRoles();
  const { userRoles } = useUserRoles();
  const isAdmin = domain => userRoles[domain] && userRoles[domain].admin;
  const groupedOptions = useMemo(
    () =>
      uniqBy(roles, 'collectionId').map(collection => ({
        collectionId: collection.collectionId,
        collectionName: collection.collectionName,
        description: collection.description,
        label: startCase(collection.collectionName),
        options: uniqBy(roles, 'levelId').map(role => ({
          levelId: role.levelId,
          levelName: role.levelName,
          value: collection.collectionId + '#' + role.levelId,
          label: startCase(collection.collectionName) + ' ' + role.levelName,
          description: replaceHandlebarsWithValues(role.description, {
            collection: collection.collectionName
          }),
          disabled: !isAdmin(collection.collectionName) && !isAdmin('super')
        }))
      })),
    [roles, isAdmin]
  );
  return { data: groupedOptions, isLoading };
};
// const levelIcons = [faEye, faEdit, faPlusCircle, faUserTie];
const GridItem = ({
  domain,
  namePrefix
}: {
  namePrefix: string;
  domain: {
    collectionId: number;
    collectionName: string;
    description: string;
    label: string;
    options: {
      value: string;
      label: string;
      description: any;
      disabled: boolean;
      levelId: number;
      levelName: string;
    }[];
  };
}) => {
  const value = useWatch({
    name: namePrefix + domain.collectionId
  });
  const selected = !!value;
  return (
    <CustomTooltip
      content={
        selected
          ? domain.options.find(o => o.levelId === value)?.description
          : 'Cannot access ' + domain.label + 's'
      }
    >
      {/* <div className="w-100 d-flex flex-column"> */}
      <Flex inline alignItems="center" className="mb-3">
        <DomainIcon
          size={'sm'}
          className="me-2"
          domain={domain.collectionName}
          bg={!selected ? '100' : undefined}
          color={!selected ? '500' : undefined}
        />
        <div className="me-2">{domain.label}</div>
        <WizardInput
          name={namePrefix + domain.collectionId}
          type="select"
          hideLabel
          registerProps={{ required: false }}
          formGroupProps={{ noMb: true }}
          options={domain.options.map(o => ({
            ...o,
            value: o.levelId,
            label: startCase(o.levelName),
            description: null
          }))}
        />
      </Flex>
      {/* <div className="form-text">{domain.description}</div> */}
      {/* </div> */}
    </CustomTooltip>
  );
  // return (
  //   <Accordion.Item eventKey={domain.label}>
  //     <Accordion.Header className={classNames('pe-2')}>
  //       <Row className="w-100 align-items-center">
  //         <Col xs={2}>
  //           <DomainIcon
  //             size={breakpoints.up('sm') ? 'md' : 'sm'}
  //             domain={domain.domain}
  //             bg={!selected.length ? '100' : undefined}
  //             color={!selected.length ? '500' : undefined}
  //           />
  //         </Col>
  //         <Col xs={8}>{startCase(domain.label)}</Col>
  //         <Col xs={2}>
  //           {!!selected.length && (
  //             <SoftBadge bg="success" className="p-2" pill>
  //               <FontAwesomeIcon size="lg" icon={levelIcons[highestLevelId]} />
  //             </SoftBadge>
  //           )}
  //         </Col>
  //       </Row>
  //     </Accordion.Header>
  //     <Accordion.Body>
  //       <Row>
  //         {domain.options.map(role => (
  //           <Col xs={12} lg={6} xl={4} key={role.value}>
  //             <WizardInput
  //               name={namePrefix + role.value}
  //               type="select"
  //               label={role.label}
  //               instruction={role.description}
  //               disabled={role.disabled}
  //             />
  //           </Col>
  //         ))}
  //       </Row>
  //     </Accordion.Body>
  //   </Accordion.Item>
  // );
};
export const PermissionsAdder = ({ name }: { name?: string }) => {
  const { fields, append, remove } = useFieldArray<
    Record<typeof name, AccessGroupRole[]>,
    typeof name
  >({
    name
  });
  const { data: roles, isLoading } = useAppRoleOptions();
  const methods = useForm({
    // values: useWatch({ name }),
    defaultValues: { collectionId: null, levelId: null }
  });
  const handleAdd = methods.handleSubmit(v => {
    const collection = roles?.find(r => r.collectionId === v.collectionId);
    const level = collection?.options?.find(l => l.levelId === v.levelId);
    append({
      ...v,
      collectionName: collection?.collectionName,
      levelName: level?.levelName,
      id: undefined,
      groupId: undefined
    });
    methods.reset();
  });
  return (
    <div>
      <h6>Selected Permissions</h6>
      <Search data={fields} keys={['collectionName', 'levelName']}>
        {results => (
          <div className="d-flex gap-3 flex-wrap mb-4 mt-3">
            {results?.map((field, i) => (
              <PermissionBadge
                key={field.id}
                role={field}
                onClose={() => remove(i)}
                className="fs-0 px-2 py-1 rounded-4"
              />
            ))}
          </div>
        )}
      </Search>
      <h6>Add Permissions</h6>
      <div className="d-flex align-items-start gap-2">
        <FormProvider {...methods}>
          <WizardInput
            loading={isLoading}
            type="select"
            name="collectionId"
            placeholder="Collection"
            hideLabel
            options={roles?.map(r => ({
              label: domainToSentence(r.collectionName as EventPrefix),
              value: r.collectionId,
              isDisabled:
                !fields.find(f => f.collectionId === r.collectionId) ||
                r.options.every(o => o.disabled)
            }))}
          />
          <WithFormValues fields={['collectionId']}>
            {({ collectionId }) => (
              <WizardInput
                loading={isLoading}
                type="select"
                name="levelId"
                hideLabel
                placeholder="Level"
                options={roles
                  ?.find(r => r.collectionId === collectionId)
                  ?.options.map(r => ({
                    label: startCase(r.levelName),
                    value: r.levelId,
                    isDisabled: r.disabled
                  }))}
              />
            )}
          </WithFormValues>
          <Button
            type="button"
            variant="falcon-primary"
            className="mb-3"
            onClick={handleAdd}
          >
            Add
          </Button>
        </FormProvider>
      </div>
    </div>
  );
};
export const PermissionsGrid = ({
  namePrefix = ''
}: {
  namePrefix?: string;
}) => {
  const { data: options, isLoading } = useAppRoleOptions();
  const values = useWatch({ name: namePrefix });
  const sortedBySelected = useMemo(() => {
    if (!values || !options) return [];
    const selectedOptions = options.filter(o => values?.[o.collectionId]);
    const notSelected = options.filter(o => !values?.[o.collectionId]);
    return [...selectedOptions, ...notSelected];
  }, [values, options]);
  return isLoading ? (
    <Accordion>
      {[0, 0, 0, 0].map((_, i) => (
        <Accordion.Item key={i} eventKey={i.toString()}>
          <Skeleton />
        </Accordion.Item>
      ))}
    </Accordion>
  ) : (
    <>
      <Search data={sortedBySelected} keys={['label']} className={'mb-2'}>
        {display => (
          // <Accordion>
          <Row>
            {display.map(domain => (
              <Col xs={12} md={6} xl={4} key={domain.label}>
                <GridItem domain={domain} namePrefix={namePrefix} />
              </Col>
            ))}
          </Row>
          // </Accordion>
        )}
      </Search>
    </>
  );
};
export const PermissionsSelect = ({ ...rest }) => {
  const { data: options, isLoading } = useAppRoleOptions();
  return (
    <WizardInput
      type="select"
      name="rolesSelect"
      label="Custom Permissions"
      options={options}
      loading={isLoading}
      multiple
      registerProps={{
        required: false
      }}
      {...rest}
    />
  );
};

export const convertUserToPermissionsForm = (
  user: UserView,
  appRoles: AppRole[]
) => {
  const collectionLookup = new Map(appRoles?.map(r => [r.collectionName, r]));
  const levelLookup = new Map(appRoles?.map(r => [r.levelName, r]));
  if (!user) return {};
  const accessGroups =
    Object.keys(user.roles || {})?.length && !user.accessGroups?.length
      ? [0]
      : user.accessGroups?.map(g => g.groupId);
  return {
    accessGroups: accessGroups || [],
    rolesSelect: Object.keys(user.roles || {}).flatMap(domain => {
      return Object.keys(user.roles[domain]).map(
        l =>
          `${collectionLookup.get(domain)?.collectionId}#${
            levelLookup.get(l)?.levelId
          }`
      );
    })
  };
};
export const convertPermissionsFormToUser = (
  vals: {
    accessGroups: number[];
    rolesSelect: string[];
  },
  userId?: number
) => {
  return {
    accessGroups: vals.accessGroups?.map(a => ({
      id: undefined,
      userId,
      groupId: a
    })),
    rolesArray: vals.rolesSelect?.map(r => ({
      collectionId: Number(r.split('#')[0]),
      levelId: Number(r.split('#')[1])
    }))
  };
};
export const UserPermissionsForm = ({ isLoading = false }) => {
  return (
    <>
      <AccessGroupSelect loading={isLoading} />
      <WithFormValues fields={['accessGroups']}>
        {({ accessGroups }) =>
          !!accessGroups?.length &&
          !accessGroups?.[0] && <PermissionsSelect loading={isLoading} />
        }
      </WithFormValues>
    </>
  );
};
export const UserPermissions = ({
  isLoading,
  userId
}: {
  isLoading?: boolean;
  userId: number;
}) => {
  const { data: appRoles } = useAppRoles();
  const {
    update,
    isUpdating,
    data: user,
    isLoading: userLoading
  } = useUsers({ id: userId, select: d => d[0] });
  const methods = useForm({
    values: convertUserToPermissionsForm(user, appRoles)
  });
  const handleSubmit = methods.handleSubmit(v => {
    update({
      id: user?.id,
      data: convertPermissionsFormToUser(
        { accessGroups: v.accessGroups, rolesSelect: v.rolesSelect },
        userId
      )
    });
  }, console.error);
  const { canEdit } = useGuard({ roles: ['user'], itemIds: [userId] });
  return (
    <FormProvider {...methods}>
      <UserPermissionsForm isLoading={isLoading || userLoading} />
      {canEdit && (
        <div className="text-end">
          <LoadingButton loading={isUpdating} onClick={handleSubmit}>
            Update
          </LoadingButton>
        </div>
      )}
    </FormProvider>
  );
};
