import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';
import { Section } from './helpers';

export default forwardRef<HTMLDivElement, { id?: string }>((props, ref) => {
  return (
    <Card className="mb-3" id={props.id} ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Third Parties</h5>
      </Card.Header>
      <Card.Body>
        <Section title="Data sharing">
          We will never share your data with third parties unless where required
          by law, or where it is required in order to carry out an action you
          request within the Site. In such cases, we require third parties to
          respect the security of your data and to treat it in accordance with
          the law.
        </Section>
        <Section title="Which third-party service providers process my personal information?">
          "Third parties" includes third-party service providers (including
          contractors and designated agents). The following categories of
          third-party service providers process personal information about you
          for the following purposes:
          <ul>
            <li>
              Service providers acting as processors based in the UK who provide
              IT, development and system administration services.
            </li>
            <li>
              Professional advisers acting as processors or joint controllers
              including lawyers, bankers, auditors and insurers based in the UK
              who provide consultancy, banking, legal, insurance and accounting
              services.
            </li>
            <li>
              HM Revenue &amp; Customs, regulators and other authorities acting
              as processors or joint controllers based in the United Kingdom who
              require reporting of processing activities in certain
              circumstances.
            </li>
          </ul>
        </Section>
        <Section title="How secure is my information with third-party service providers?">
          All our third-party service providers are required to take appropriate
          security measures to protect your personal information in line with
          our policies. We do not allow our third-party service providers to use
          your personal data for their own purposes. We only permit them to
          process your personal data for specified purposes and in accordance
          with our instructions.
        </Section>
        <Section title="Data security">
          We have put in place measures to protect the security of your
          information. Details of these measures are available upon request.
          Third parties will only process your personal information on our
          instructions and where they have agreed to treat the information
          confidentially and to keep it secure.
          <br />
          We have put in place appropriate security measures to prevent your
          personal information from being accidentally lost, used or accessed in
          an unauthorised way, altered or disclosed. In addition, we limit
          access to your personal information to those employees, agents,
          contractors and other third parties who have a business need to know.
          They will only process your personal information on our instructions
          and they are subject to a duty of confidentiality. Details of these
          measures may be obtained from our Data Protection Officer. <br />
          We have put in place procedures to deal with any suspected data
          security breach and will notify you and any applicable regulator of a
          suspected breach where we are legally required to do so.
        </Section>
        <Section title="Subprocessors">
          We use certain subprocessors to assist in providing its Services. It
          works with service providers that have or may have access to or
          process Flex's customer data. Before engaging any sub-processors, we
          perform strict due diligence to ensure our customer data remain secure
          and protected, including ensuring our subprocessors satisfy equivalent
          contractual obligations as those required from us as a Processor. The
          list of our current subprocessors is below:
          <ul>
            <li>OneID - ID verification</li>
            <li>SignWell - Document signing</li>
            <li>Microsoft Azure - Cloud services provider</li>
          </ul>
        </Section>
      </Card.Body>
    </Card>
  );
});
