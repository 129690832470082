import React, { useEffect } from 'react';
import TrainingCoursePicker from '../widgets/TrainingCoursePicker';
import UserSelector from 'components/app/users/widgets/selector/UserSelector';
import WizardInput from 'components/wizard/WizardInput';
import { FormProvider, useForm } from 'react-hook-form';
import { ResponsiveModal } from 'components/common/Modals';
import { Button, Modal } from 'react-bootstrap';
import useUserTraining from '../hooks/useUserTraining';
import LoadingButton from 'components/common/LoadingButton';
export const CourseEnrollFormGeneric = ({
  namePrefix = '',
  includeDates = true
}: {
  includeDates?: boolean;
  namePrefix?: string;
}) => {
  return (
    <>
      {includeDates && (
        <WizardInput
          type="date"
          name={namePrefix + 'deadlineDate'}
          label="Training Deadline"
          registerProps={{ required: false }}
          instruction={
            'Once date has passed, the employee will be forced to complete before using Flex. Set a date in the past to force immediate training completion'
          }
        />
      )}
    </>
  );
};
export const CourseEnrollForm = () => {
  return (
    <>
      <TrainingCoursePicker multiple label="Course(s)" name="courseIds" />
      <UserSelector multiple label="User(s)" name="userIds" />
      <CourseEnrollFormGeneric />
    </>
  );
};
export type UserEnrollment = {
  courseIds: number[];
  userIds: number[];
  deadlineDate: Date;
};
export const CourseEnrollModal = ({
  show,
  setShow,
  courseIds,
  userIds
}: {
  show: boolean;
  setShow: (show: any) => void;
  courseIds?: number[];
  userIds?: number[];
}) => {
  const methods = useForm<UserEnrollment, any, UserEnrollment>();
  const { bulkAdd, isBulkAdding } = useUserTraining();
  const handleSubmit = methods.handleSubmit(data => {
    const dataArr = data.userIds.flatMap(userId =>
      data.courseIds.map(courseId => ({
        courseId,
        userId,
        deadlineDate: data.deadlineDate
      }))
    );
    bulkAdd(dataArr, { onSuccess: () => setShow(false) });
  }, console.error);
  useEffect(() => {
    if (courseIds) {
      methods.resetField('courseIds', { defaultValue: courseIds });
    }
    if (userIds) {
      methods.resetField('userIds', { defaultValue: userIds });
    }
  }, [userIds, courseIds]);
  const handleHide = () => {
    setShow(false);
    methods.reset({});
  };
  return (
    <ResponsiveModal onHide={handleHide} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>Enroll</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <CourseEnrollForm />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={handleHide}>
          Cancel
        </Button>
        <LoadingButton
          loading={isBulkAdding}
          variant="primary"
          onClick={handleSubmit}
        >
          Submit
        </LoadingButton>
      </Modal.Footer>
    </ResponsiveModal>
  );
};
