import React from 'react';
import CalendarsTable from './CalendarsTable';

export default () => {
  return (
    <>
      <CalendarsTable />
    </>
  );
};
