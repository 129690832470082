import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Form, Dropdown, CloseButton, InputGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fuse from 'fuse.js';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import { isIterableArray } from 'helpers/utils';
import Flex from 'components/common/Flex';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { DefaultCrudData } from 'hooks/useDefaultCrud';
import { domainToSentence } from 'components/common/DomainTimeline';
import Skeleton from 'react-loading-skeleton';
import { DebouncedSearchInput } from 'components/common/Search';
import { getRecentPages, useRemoteDomainSearch } from './useSiteSearch';
import DomainItemIcon from 'components/common/DomainItemIcon';
import { domains } from 'apis/flex/notifications';
import SoftBadge from 'components/common/SoftBadge';
import { nextColor } from 'components/app/reporting/helpers';
import { domainConfigs } from 'components/notification/config';
import { QuickLink, useRouteSearch } from 'components/common/QuickLinks';

const MediaSearchContent = ({ item }) => {
  return (
    <Dropdown.Item className="px-x1 py-2" as={Link} to={item.url}>
      <Flex alignItems="center">
        {item.file && (
          <div className="file-thumbnail">
            <img src={item.img} alt="" className={item.imgAttrs.class} />
          </div>
        )}
        {item.icon && (
          <Avatar src={item.icon.img} size="l" className={item.icon.status} />
        )}

        <div className="ms-2">
          <h6 className="mb-0">{item.title}</h6>
          <p
            className="fs--2 mb-0"
            dangerouslySetInnerHTML={{ __html: item.text || item.time }}
          />
        </div>
      </Flex>
    </Dropdown.Item>
  );
};
export const SearchInput = ({
  value,
  setValue,
  onFocus = () => {},
  onBlur = () => {},
  className = null,
  isLoading = false,
  showIcon = true,
  after = null,
  before = null,
  wrapperClassName = '',
  ...rest
}) => {
  return (
    <div className={classNames('input-group', wrapperClassName)}>
      {before}
      <div
        className={classNames(
          className,
          'position-relative form-control position-relative search-input px-4',
          { 'rounded-pill': !before && !after, 'ps-2': !showIcon }
        )}
      >
        <input
          // type="search"
          placeholder="Search..."
          aria-label="Search"
          className={classNames(
            { 'ms-2': showIcon },
            'border-0 form-control p-0 shadow-none'
          )}
          value={value}
          onChange={({ target }) => setValue(target.value)}
          // onFocus={() => setDropdownOpen(true)}
          onFocus={onFocus}
          onBlur={onBlur}
          {...rest}
          // onBlur={e => {
          //   console.log(e);
          //   setDropdownOpen(false);
          // }}
        />
        {showIcon && (
          <FontAwesomeIcon
            icon="search"
            className="position-absolute text-400 search-box-icon top-50 translate-middle-y start-0 ms-3"
          />
        )}
        {isLoading ? (
          <div className="position-absolute text-400 top-50 translate-middle-y end-0 d-flex me-1">
            <div
              className="spinner-border"
              style={{
                scale: '0.6'
              }}
            />
          </div>
        ) : (
          !!value && (
            <div
              className={classNames(
                'search-box-close-btn-container position-absolute top-50 translate-middle-y end-0 me-2'
              )}
            >
              <FalconCloseButton
                size="sm"
                noOutline
                className="fs--2 d-flex"
                onClick={() => {
                  setValue('');
                  onBlur();
                }}
              />
            </div>
          )
        )}
      </div>
      {after}
    </div>
  );
};
SearchInput.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  isLoading: PropTypes.bool
};
const ResultGroup = <T extends { url: string } = any>({
  title,
  formatter,
  results,
  onClick,
  isLast,
  isLoading,
  ...rest
}: {
  title: string;
  formatter: (item: T, i: number) => JSX.Element;
  results: T[];
  onClick: (item: T) => void;
  isLast?: boolean;
  isLoading?: boolean;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'results'>) => {
  return (
    (isLoading || results?.length > 0) && (
      <div {...rest}>
        <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
          {title}
        </Dropdown.Header>
        {(isLoading ? [null, null] : results)
          .filter((a, i) => i < 5)
          .map((item, i) => (
            <Dropdown.Item className="fs--1 px-x1 py-0 hover-primary " key={i}>
              {isLoading ? (
                <Skeleton />
              ) : (
                <Flex alignItems="center" onMouseDown={() => onClick(item)}>
                  {formatter(item, i)}
                </Flex>
              )}
            </Dropdown.Item>
          ))}
        {!isLast && <hr className="text-200 dark__text-900" />}
      </div>
    )
  );
};
const DomainResults = <TData extends DefaultCrudData = any>({
  domain,
  search,
  isLast = false
}) => {
  const { data, isLoading } = useRemoteDomainSearch<TData>(search, domain);
  const navigate = useNavigate();
  return (
    <ResultGroup
      onClick={item => navigate(item.url)}
      title={domainToSentence(domain) + 's'}
      isLoading={isLoading}
      formatter={item =>
        !!item && (
          // <>
          //   <div className="fw-normal">
          <DomainItemIcon
            size="xs"
            domain={domain}
            isLoading={isLoading}
            data={[item]}
          />
          //   </div>
          // </>
        )
      }
      results={data}
      isLast={isLast}
      className="w-50 border-end"
    />
  );
};
type SearchFilters = { domain?: string };
const SearchBox = ({ className }: { className?: string }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const defaultItems = getRecentPages();
  const [resultItem, setResultItem] = useState([]);
  const [navTarget, setNavTarget] = useState<string>();
  useEffect(() => {
    setResultItem(defaultItems);
  }, [defaultItems]);
  const fuseJsOptions = {
    includeScore: true,
    keys: ['title', 'text', 'breadCrumbTexts'],
    includeMatches: true,
    minMatchCharLength: 4,
    ignoreLocation: true
  };

  const [searchFilter, setSearchFilter] = useState<SearchFilters>({});

  const searchResult = new Fuse(defaultItems, fuseJsOptions)
    .search(
      searchInputValue + (searchFilter.domain ? ' ' + searchFilter.domain : ''),
      { limit: 3 }
    )
    .map(item => item.item);

  useEffect(() => {
    if (searchInputValue) {
      setResultItem(searchResult);
      isIterableArray(searchResult) && setDropdownOpen(true);
    } else {
      setResultItem(defaultItems);
    }

    // eslint-disable-next-line
  }, [searchInputValue]);
  const nav = useNavigate();
  useEffect(() => {
    if (navTarget) {
      nav(navTarget);
      setDropdownOpen(false);
      setNavTarget(null);
      setSearchInputValue('');
      setSearchFilter({});
    }
  }, [navTarget]);

  const domainswithCrud = domains.filter(d => domainConfigs[d]?.crudHook);
  // console.log('searchFilter', searchFilter);

  const pages = useRouteSearch({
    search: searchInputValue,
    scoped: false,
    showDefault: false
  });

  return (
    <Dropdown
      show={dropdownOpen}
      className={classNames(className, 'search-box')}
    >
      <InputGroup className="input-group rounded-pill">
        <DebouncedSearchInput
          onChange={v => setSearchInputValue(v || '')}
          onBlur={() => setDropdownOpen(false)}
          onFocus={() => setDropdownOpen(true)}
          className={'w-50'}
          after={
            <select
              defaultValue={0}
              className={classNames('form-select', {
                'text-muted': !searchFilter.domain
              })}
              style={{
                borderBottomRightRadius: 50,
                borderTopRightRadius: 50
              }}
              onChange={e =>
                setSearchFilter(s => ({
                  ...s,
                  domain: e.target.value || undefined
                }))
              }
            >
              <option value={''}>Any</option>
              {domainswithCrud.map(domain => (
                <option key={domain} value={domain}>
                  {domainToSentence(domain)}
                </option>
              ))}
            </select>
          }
        />
        {/* <div className="d-flex gap-2 flex-wrap w-100 px-2">
          {Object.keys(searchFilter).map((k, i) => (
            <SoftBadge key={k} bg={nextColor(i)}>
              {k + ': ' + searchFilter[k]}
              <CloseButton
                onClick={handleFilterRemove({ [k]: searchFilter[k] })}
              />
            </SoftBadge>
          ))}
        </div> */}
      </InputGroup>
      <Dropdown.Menu style={{ width: '80vw' }}>
        <div className="scrollbar py-3 w-100" style={{ maxHeight: '24rem' }}>
          {/* <>
            <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
              Suggested Filters
            </Dropdown.Header>
            {domains.map((domain, i) => (
              <Dropdown.Item
                className="fs-0 px-x1 py-1"
                key={domain}
                onClick={handleFilterAppend({ domain })}
              >
                <Flex alignItems="center">
                  <SoftBadge
                    bg={nextColor(i)}
                    className="fw-medium text-decoration-none me-2"
                  >
                    {domain}:{' '}
                  </SoftBadge>
                  <div className="flex-1 fs--1">{searchInputValue}</div>
                </Flex>
              </Dropdown.Item>
            ))}
            <hr className="text-200 dark__text-900" />
          </> */}
          <ResultGroup
            onClick={item => setNavTarget(item.url)}
            title="Recently Browsed"
            results={resultItem}
            className="w-100"
            formatter={item => (
              <div className="d-flex py-1">
                <FontAwesomeIcon
                  icon="circle"
                  className="me-2 text-300 fs--2"
                />
                <div className="fw-normal">
                  {(item.breadCrumbTexts as string[])
                    .slice(-3)
                    .map((breadCrumbText, index) => {
                      return (
                        <Fragment key={breadCrumbText}>
                          {breadCrumbText}
                          {item.breadCrumbTexts.length - 1 > index && (
                            <FontAwesomeIcon
                              icon="chevron-right"
                              className="mx-1 text-500 fs--2"
                              transform="shrink 2"
                            />
                          )}
                        </Fragment>
                      );
                    })}
                </div>
              </div>
            )}
          />
          {!!pages?.length && (
            <>
              <Dropdown.Header as="h6" className="px-x1 pt-0 pb-2 fw-medium">
                Pages
              </Dropdown.Header>
              <Row>
                {pages.map((p, i) => (
                  // <div onClick={() => setNavTarget(p.path)}>
                  <QuickLink size="sm" index={i} key={i} item={p} />
                  // </div>
                ))}
              </Row>
              <hr className="text-200 dark__text-900" />
            </>
          )}
          {/* <ResultGroup
            onClick={item => setNavTarget(item.url)}
            title="Pages"
            results={pages.map(p => ({ ...p, url: p.path }))}
            className="w-100"
            formatter={(item, i) => (
              // <Row className="gx-3 justify-content-around">
              // </Row>
            )}
          /> */}
          <div className="d-flex flex-wrap">
            {searchFilter.domain ? (
              <DomainResults
                domain={searchFilter.domain}
                search={searchInputValue}
              />
            ) : (
              <>
                <DomainResults domain="project" search={searchInputValue} />
                <DomainResults domain="form" search={searchInputValue} />
                <DomainResults domain="client" search={searchInputValue} />
                <DomainResults domain="employee" search={searchInputValue} />
                <DomainResults
                  domain="recruitment-campaign"
                  search={searchInputValue}
                />
                <DomainResults domain="applicant" search={searchInputValue} />
              </>
            )}
          </div>
        </div>
        {/* <div className="text-center mt-n3">
          <p
            className={classNames('fs-1 fw-bold text-center', {
              'd-none': resultItem.length > 0
            })}
          >
            No Result Found.
          </p>
        </div> */}
      </Dropdown.Menu>
    </Dropdown>
  );
};

MediaSearchContent.propTypes = {
  item: PropTypes.shape({
    catagories: PropTypes.string,
    url: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.shape({
        img: PropTypes.string.isRequired,
        size: PropTypes.string,
        status: PropTypes.string
      })
    ]),
    title: PropTypes.string,
    text: PropTypes.string,
    img: PropTypes.string,
    time: PropTypes.string,
    file: PropTypes.bool,
    imgAttrs: PropTypes.shape({
      class: PropTypes.string
    }),
    color: PropTypes.string
  }).isRequired
};

SearchBox.propTypes = {
  autoCompleteItem: PropTypes.arrayOf(MediaSearchContent.propTypes.item)
};

export default SearchBox;
