import React, { useEffect } from 'react';
import { useFormState, useWatch } from 'react-hook-form';

export default ({ name, mutator = x => x }) => {
  const value = useWatch({
    name,
    disabled: !name
  });
  return mutator(value);
};
export const FormDirtyListener = ({ onDirty = (x, y) => {} }) => {
  const { dirtyFields, isDirty } = useFormState();
  useEffect(() => {
    onDirty(dirtyFields, isDirty);
  }, [dirtyFields, isDirty]);
  return <></>;
};
