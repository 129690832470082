import useInterval from 'hooks/useInterval';
import React, { useEffect, useState } from 'react';
import PageLoader from './PageLoader';

export default ({ seconds: _seconds, message, url }) => {
  const [seconds, setSeconds] = useState(_seconds);
  useInterval({
    onInterval: () => {
      setSeconds(s => s - 1);
    },
    onClear: () => (window.location.href = url),
    intervalMs: 1000,
    clearAfterMs: 5000
  });
  return (
    <PageLoader
      message={seconds > 0 ? seconds : 'Redirecting...'}
      info={message}
      height={'20vh'}
    />
  );
};
