import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Button,
  ButtonProps,
  Card,
  CardProps,
  Col,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
// import events from 'data/calendar/events';
import AddScheduleModal from './AddScheduleModal';
import CalendarEventModal from './CalendarEventModal';
import DropdownFilter from 'components/common/DropdownFilter';
import AppContext from 'context/Context';
import { FormProvider, useForm } from 'react-hook-form';
import { addHours } from 'date-fns';
import {
  CalendarApi,
  CalendarOptions,
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  FormatterInput
} from '@fullcalendar/core';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { CalendarItem } from 'apis/flex/helpers';
import classNames from 'classnames';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { WizardInputProps } from 'components/wizard/WizardInput';
import SettingsDropdown, { Setting } from 'components/common/SettingsDropdown';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import LoadingButton from 'components/common/LoadingButton';
const views = {
  'Month View': 'dayGridMonth',
  'Week View v2': 'dayGridWeek',
  'Week View': 'timeGridWeek',
  'Day View': 'timeGridDay',
  'List View': 'listDay',
  'Year View': 'listYear'
};
export type Event = CalendarItem;
export type CalendarProps = {
  isLoading?: boolean;
  header?: boolean;
  events?: any[];
  showTitle?: boolean;
  height?: number | string;
  config?: {
    eventModal?: any;
    onEventClick?: (info: EventClickArg) => void;
    onChange?: (event: Event) => void;
    onAdd?: (event: Event) => void;
    initialView?: any;
    addButton?: any;
    customFields?: any;
    customForm?: any;
    onDelete?: (event: Event) => void;
    datesSet?: (dates: DatesSetArg) => void;
    defaultValues?: Partial<Event>;
    settings?: Setting[];
    buttons?: Omit<ButtonProps, 'as'>[];
  };
  calendarProps?: Partial<CalendarOptions>;
} & CardProps;
const Calendar = ({
  header = true,
  showTitle = true,
  events = [],
  // onChange = () => {},
  config = {},
  isLoading = false,
  height,
  calendarProps,
  ...rest
}: CalendarProps) => {
  const setHours = (input: Date, add = 0) => {
    const now = new Date();
    const dte = new Date(input);
    if (dte.getHours() < 1 && dte.getMinutes() < 1) {
      dte.setHours(now.getHours() + add, now.getMinutes());
    }
    return dte;
  };
  const initialiseEvent = (props?: Partial<DateSelectArg>) => {
    const now = new Date();
    const start = props?.start || now;
    //get the next 15 minute increment from now
    if (start.getHours() < 1) {
      start.setHours(now.getHours());
      start.setMinutes(Math.ceil(now.getMinutes() / 15) * 15);
    }
    const end = props?.end || addHours(start, 1);
    return {
      start: setHours(start),
      end: setHours(end, 1),
      ...rest,
      ...config.defaultValues
    };
  };
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const calendarRef = useRef<FullCalendar>();
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState<CalendarApi>();
  const { breakpoints } = useBreakpoints();
  const [currentFilter, setCurrentFilter] = useState(
    config.initialView || (breakpoints.up('md') ? 'Week View' : 'List View')
  );
  const [scheduleModal, setScheduleModal] = useState<string>();
  const [isOpenEventModal, setIsOpenEventModal] = useState(false);

  const eventList = events
    .reduce(
      (acc, event) =>
        event.schedules
          ? acc.concat(event.schedules.concat(event))
          : acc.concat(event),
      []
    )
    .map(event => ({ ...event, url: event.url || '' }));

  const eventTimeFormat: FormatterInput = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: true
  };
  const methods = useForm<any>({
    defaultValues: initialiseEvent()
  });
  const getEventData = info => {
    const e = info.event;
    const defaultValues = {
      start: e.start,
      end: e.end,
      title: e.title,
      description: e.description,
      allDay: e.allDay,
      url: e.url,
      id: e.id
    };
    return { ...defaultValues, ...e.extendedProps };
  };
  const handleEventClick = (info: EventClickArg) => {
    // console.log('clicked', info);
    info.jsEvent.preventDefault();
    // if (info.event.url) {
    //   window.open(info.event.url);
    //   return info.jsEvent.preventDefault();
    // }
    if (config.onEventClick) {
      return config.onEventClick(info);
    }
    methods.reset(getEventData(info));
    setIsOpenEventModal(true);
  };

  const handleEventAdded = data => {
    if (config.onAdd) {
      config.onAdd(data);
    }
    // onChange(data);
    methods.reset(initialiseEvent());
  };
  const handleEventChange = data => {
    if (config.onChange) {
      config.onChange(data);
    }
    return data;
  };
  const handleFilter = filter => {
    setCurrentFilter(filter);
    calendarApi.changeView(views[filter]);
    setTitle(calendarApi.view.title);
  };
  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());
  }, []);
  const AddButton = props => config.addButton(props);
  const EventModal = props => config.eventModal(props);
  return (
    <>
      <Card {...rest}>
        <div className="py-3 px-2">
          <Row className="align-items-center">
            <Col xs="auto" className="d-flex justify-content-end">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                    Previous
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1"
                  onClick={() => {
                    calendarApi.prev();
                    setTitle(calendarApi.view.title);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="previousTooltip">
                    Next
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    setTitle(calendarApi.view.title);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            {showTitle && (
              <Col xs="auto" className="d-flex justify-content-end">
                <h4 className="mb-0 fs-0 fs-sm-1 fs-lg-2">
                  {title || `${calendarApi?.view.title || ''}`}
                </h4>
              </Col>
            )}
            {header && (
              <>
                <Col xs md="auto" className="d-flex justify-content-end">
                  {calendarApi?.getDate().toDateString() !==
                    new Date().toDateString() && (
                    <Button
                      size="sm"
                      variant="falcon-primary"
                      onClick={() => {
                        calendarApi.today();
                        setTitle(calendarApi.view.title);
                      }}
                    >
                      Today
                    </Button>
                  )}
                </Col>
                <Col md="auto" className="d-md-none">
                  <hr />
                </Col>

                <Col className="d-flex justify-content-end me-2">
                  <DropdownFilter
                    filters={Object.keys(views)}
                    currentFilter={currentFilter}
                    handleFilter={handleFilter}
                    icon="sort"
                    // drop={"right"}
                  />
                </Col>
              </>
            )}
            {config.addButton ? (
              <AddButton />
            ) : (
              <Col
                xs="auto"
                className={classNames('d-flex justify-content-end', {
                  'flex-1': !header
                })}
              >
                <Button
                  variant="primary"
                  // transform="shrink-3"
                  size="sm"
                  onClick={() => {
                    setScheduleModal('true');
                    methods.reset(
                      initialiseEvent(
                        calendarApi.view.type.toLowerCase().includes('day') && {
                          start: calendarApi.view.activeStart
                        }
                      )
                    );
                  }}
                >
                  <FontAwesomeIcon icon="plus" />
                  <div className="d-none d-lg-inline ms-2">Add Event</div>
                </Button>
              </Col>
            )}
            {config?.settings && (
              <Col xs="auto" className="d-flex justify-content-end">
                <SettingsDropdown settings={config.settings} />
              </Col>
            )}
            {config?.buttons && (
              <Col xs="auto" className="d-flex justify-content-end">
                <InputGroup>
                  {config.buttons.map((props, i) => (
                    <Button key={i} {...props} />
                  ))}
                </InputGroup>
              </Col>
            )}
          </Row>
        </div>
        <Card.Body className="p-0">
          <FullCalendar
            datesSet={config.datesSet}
            firstDay={1}
            ref={calendarRef}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            eventChange={info => {
              handleEventChange(getEventData(info));
              return info.event;
            }}
            initialView={views[currentFilter]}
            themeSystem="bootstrap"
            // dayMaxEvents={2}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={height}
            aspectRatio={1.5}
            stickyHeaderDates={false}
            editable
            selectable
            selectMirror
            locale={'en-gb'}
            businessHours={{
              startTime: '09:00',
              endTime: '21:00',
              daysOfWeek: [1, 2, 3, 4, 5, 6]
            }}
            select={info => {
              setScheduleModal('true');
              methods.reset(
                initialiseEvent({
                  start: info.start,
                  end: info.end
                })
              );
            }}
            eventTimeFormat={eventTimeFormat}
            eventClick={handleEventClick}
            events={eventList}
            {...calendarProps}
          />
        </Card.Body>
        {isLoading && <LoadingOverlay />}
      </Card>
      <FormProvider {...methods}>
        <AddScheduleModal
          isOpenScheduleModal={!!scheduleModal}
          setIsOpenScheduleModal={setScheduleModal}
          customFields={config.customForm}
          onSubmit={
            scheduleModal === 'edit' ? handleEventChange : handleEventAdded
          }
        />
        {config.eventModal ? (
          <EventModal
            show={isOpenEventModal}
            setShow={setIsOpenEventModal}
            setEdit={() => setScheduleModal('edit')}
            onDelete={config.onDelete}
          />
        ) : (
          <CalendarEventModal
            isOpenEventModal={isOpenEventModal}
            setIsOpenEventModal={setIsOpenEventModal}
            customFields={config.customFields}
            setEdit={() => setScheduleModal('edit')}
            onDelete={config.onDelete}
          />
        )}
      </FormProvider>
    </>
  );
};
// Calendar.propTypes = {
//   header: PropTypes.bool,
//   events: PropTypes.arrayOf(
//     PropTypes.shape({
//       title: PropTypes.string,
//       description: PropTypes.string,
//       start: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
//         .isRequired,
//       end: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
//       allDay: PropTypes.bool,
//       id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
//     })
//   ),
//   // onChange: PropTypes.func.isRequired,
//   config: PropTypes.shape({
//     eventModal: PropTypes.func,
//     onEventAdded: PropTypes.func,
//     onEventClick: PropTypes.func,
//     onChange: PropTypes.func,
//     onAdd: PropTypes.func,
//     initialView: PropTypes.oneOf(Object.keys(views)),
//     addButton: PropTypes.func,
//     customFields: PropTypes.array,
//     customForm: PropTypes.func,
//     onDelete: PropTypes.func
//   })
// };
export default Calendar;
