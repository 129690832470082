import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { motion } from 'framer-motion';
import { FileId } from 'apis/types';
import { useQuery } from '@tanstack/react-query';
import { getMediaStream } from 'apis/flex/helpers';
import Skeleton from 'react-loading-skeleton';
import Flex from './Flex';
import { transitions } from 'helpers/animations';
import IconButton from './IconButton';
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { AudioVisualizer as StaticVisualizer } from 'react-audio-visualize';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { getColor } from 'helpers/utils';
import { fetchWithCredentials } from 'apis/flex';
import { useBreakpoints } from 'hooks/useBreakpoints';

export type PlayEvent = {
  type: string;
  startTime: number;
  position: number;
  source: AudioBufferSourceNode | null;
  destination: MediaStreamAudioDestinationNode | null;
};
export type PlayEventHandler = (e: PlayEvent) => void;
const connectAudio = (
  audioContext: AudioContext,
  audioBuffer: AudioBuffer,
  onEnded?: (e: Event) => void,
  onProgress: (progress: { played: number }) => void = () => {},
  onPlay?: PlayEventHandler
) => {
  if (!audioBuffer) {
    // onEnded();
    return { stream: null, start: () => null };
  }

  // Start the audio playback
  let startTime;
  let startOffset = 0;
  let progressInterval;
  function startProgressTracking() {
    progressInterval = setInterval(() => {
      if (!audioContext) {
        clearInterval(progressInterval);
        return;
      }
      if (audioBuffer) {
        const currentTime = audioContext.currentTime - startTime + startOffset;
        const duration = audioBuffer.duration;
        const progress = currentTime / duration;
        onProgress({ played: progress });
      }
    }, 100); // Update every 100ms
  }
  let source: AudioBufferSourceNode;
  let destination: MediaStreamAudioDestinationNode;
  function start(position = 0) {
    if (!audioContext) return;
    startProgressTracking();
    // Create an audio buffer source node
    source = audioContext.createBufferSource();
    source.buffer = audioBuffer;

    //create a stream destination that can be returned
    destination = audioContext.createMediaStreamDestination();
    source.connect(destination);

    //connect to the user's audio output
    const audioSource = audioContext.createMediaStreamSource(
      destination.stream
    );
    audioSource.connect(audioContext.destination);
    source.onended = e => {
      stop();
      onEnded && onEnded(e);
    };
    startOffset = audioBuffer.duration * position;
    source.start(0, startOffset);
    startTime = audioContext.currentTime;
    if (onPlay) {
      const playEvent = {
        type: 'play',
        startTime,
        position,
        source,
        destination
      };
      onPlay(playEvent);
    }
    return destination.stream;
  }

  function stopProgressTracking() {
    clearInterval(progressInterval);
  }
  function stop(position = null) {
    source?.stop(position);
    destination?.stream.getTracks().forEach(track => {
      track.stop();
    });
    stopProgressTracking();
  }
  return { stream: destination?.stream, start, stop };
};
const useAudioSrc = (
  audioContext: AudioContext,
  src: string,
  onEnded?: (e: Event) => void,
  onProgress?: (progress: { played: number }) => void,
  onPlay?: PlayEventHandler
) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['audioSrcStream', src],
    enabled: !!src && !!audioContext,
    staleTime: Infinity,
    queryFn: async () => {
      const resp = await fetchWithCredentials(src);
      const arrayBuffer = await resp.arrayBuffer();
      // Decode the audio dataconst uint8Array = new Uint8Array(buffer);

      // Create a Blob from the Uint8Array
      const uint8Array = new Uint8Array(arrayBuffer);
      const audioBlob = new Blob([uint8Array], { type: 'audio/mp3' });
      const a = await audioContext?.decodeAudioData(arrayBuffer);
      return { audioBuffer: a, blob: audioBlob };
    }
  });
  const { stream, start, stop } = useMemo(
    () =>
      connectAudio(
        audioContext,
        data?.audioBuffer,
        onEnded,
        onProgress,
        onPlay
      ),
    [data, audioContext, onPlay, onEnded]
  );

  // Return the MediaStream from the destination node
  return useMemo(
    () => ({ stream, blob: data?.blob, isLoading, error, start, stop }),
    [data, audioContext, stop]
  );
};
const useAudioBuffer = (
  audioContext: AudioContext,
  buffer: ArrayBuffer,
  onEnded?: (e: Event) => void,
  onProgress?: (progress: { played: number }) => void,
  onPlay?: PlayEventHandler
) => {
  const { data, isLoading, error } = useQuery({
    enabled: !!buffer && !!audioContext,
    queryKey: ['audioBufferStream', buffer],
    staleTime: Infinity,
    queryFn: async () => {
      const a = await audioContext?.decodeAudioData(buffer);
      // Convert the ArrayBuffer to a Uint8Array
      const uint8Array = new Uint8Array(buffer);

      // Create a Blob from the Uint8Array
      const audioBlob = new Blob([uint8Array], { type: 'audio/mp3' });
      return { audioBuffer: a, blob: audioBlob };
    }
  });

  const { stream, start, stop } = useMemo(
    () =>
      connectAudio(
        audioContext,
        data?.audioBuffer,
        onEnded,
        onProgress,
        onPlay
      ),
    [data, audioContext, onPlay, onEnded]
  );

  // Return the MediaStream from the destination node
  return useMemo(
    () => ({ stream, blob: data?.blob, isLoading, error, start, stop }),
    [stream, audioContext]
  );
};
const VisualSeeker = ({
  width,
  blob,
  setPosition,
  position
}: {
  width: number;
  blob: Blob;
  setPosition: (position: number) => void;
  position: number;
}) => {
  return (
    <motion.div
      className="overflow-hidden position-relative w-100 border border-start-0 rounded-4"
      initial={{ maxWidth: 0 }}
      animate={{ maxWidth: width }}
      layout
      onClick={e => {
        setPosition(e.nativeEvent.offsetX / width);
      }}
    >
      <StaticVisualizer
        height={60}
        width={width}
        blob={blob}
        barWidth={3}
        gap={2}
        barColor={'lightblue'}
      />
      <div
        className="bg-progress-gradient shadow h-100"
        style={{
          position: 'absolute',
          top: 0,
          left: position * 100 + '%',
          width: 2
        }}
      />
    </motion.div>
  );
};
export type AudioPlayerProps = {
  url?: string;
  autoplay?: boolean;
  buffer?: ArrayBuffer;
  className?: string;
  onEnded?: (e: Event) => void;
  onProgress?: (progress: { played: number }) => void;
  controls?: boolean;
  width: number;
  visualize?: boolean;
  countdown?: number;
  allowStop?: boolean;
  allowSeek?: boolean;
  onPlay?: PlayEventHandler;
  isLoading?: boolean;
};
const AudioPlayer = React.forwardRef<any, AudioPlayerProps>(
  (
    {
      url,
      buffer,
      autoplay = false,
      className = null,
      onEnded = () => {},
      onProgress = () => {},
      controls = true,
      width,
      visualize = true,
      countdown,
      allowStop = true,
      allowSeek = true,
      onPlay = () => {},
      isLoading,
      ...rest
    }: AudioPlayerProps,
    ref
  ) => {
    const [audio, setAudio] = useState<MediaStream | null>(null);
    const [playing, setPlaying] = useState(false);
    const [position, setPosition] = useState(0);
    const handleEnd = useCallback(
      e => {
        setPlaying(false);
        onEnded(e);
        setPosition(0);
      },
      [onEnded]
    );
    const handleProgress = useCallback(
      ({ played }: { played: number }) => {
        setPosition(played);
        onProgress({ played });
      },
      [onProgress]
    );
    const handleSeek = useCallback(
      (position: number) => {
        if (!allowSeek) return;
        if (playing) {
          handleStop(position);
          setTimeout(() => {
            handleStart(position);
          }, 10);
        } else {
          setPosition(position);
        }
      },
      [playing]
    );
    const [audioContext, setAudioContext] = useState<AudioContext>(null);
    useEffect(() => {
      const audioCtx = new AudioContext();
      setAudioContext(audioCtx);
      return () => {
        if (audioCtx?.state !== 'closed') {
          audioCtx.close();
        }
        setAudioContext(null);
      };
    }, []);
    const {
      start: playSrc,
      stop: stopSrc,
      blob: srcBlob,
      isLoading: srcIsLoading
    } = useAudioSrc(audioContext, url, handleEnd, handleProgress);
    const {
      start: playBuffer,
      stop: stopBuffer,
      blob: bufferBlob,
      isLoading: bufferIsLoading
    } = useAudioBuffer(audioContext, buffer, handleEnd, handleProgress);
    const isAudioLoading =
      (srcIsLoading && !!url) || (bufferIsLoading && !!buffer) || isLoading;
    useEffect(() => {
      if ((url || buffer) && !playing && autoplay) {
        handleStart(position);
      }
    }, [audio, autoplay]);
    const handleStop = useCallback(
      (position = null) => {
        stopSrc && stopSrc(position);
        stopBuffer && stopBuffer(position);
        setAudio(null);
        setPlaying(false);
      },
      [stopBuffer, stopSrc]
    );
    const handleStart = useCallback(
      (position = 0) => {
        if (url) {
          setAudio(playSrc(position));
        } else if (buffer) {
          setAudio(playBuffer(position));
        }
        onPlay?.({
          type: 'play',
          position,
          startTime: audioContext?.currentTime,
          source: null,
          destination: null
        });
        setPlaying(true);
      },
      [playSrc, playBuffer, url, buffer, onPlay]
    );
    const [startCountdown, setStartCountdown] = useState(false);
    const { breakpoints } = useBreakpoints();
    const maxWidth = breakpoints.up('md') ? 700 : 400;
    return (
      <Flex ref={ref} width="100%" className={className} {...rest}>
        <Flex className="d-flex gap-2">
          {(!!url || !!buffer || isLoading) && controls && (
            <motion.div
              layout
              transition={transitions.lightBounce}
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
            >
              {startCountdown ? (
                <CountdownCircleTimer
                  onComplete={() => {
                    setStartCountdown(false);
                    handleStart();
                  }}
                  isPlaying
                  size={60}
                  duration={countdown}
                  strokeWidth={3}
                  colors={['#ffffff', getColor('danger') as `#${string}`]}
                  colorsTime={[countdown, 0]}
                >
                  {({ remainingTime }) => (
                    <p className="fs-1 text-1000 m-0 text-primary fw-bold">
                      {remainingTime}
                    </p>
                  )}
                </CountdownCircleTimer>
              ) : (
                <>
                  <IconButton
                    loading={isLoading}
                    iconSize={'1x'}
                    disabled={(!allowStop && playing) || isLoading}
                    icon={playing ? faStop : faPlay}
                    variant="falcon-primary"
                    style={{ width: 60, height: 60 }}
                    onClick={() => {
                      if (playing) {
                        handleStop(position);
                      } else {
                        if (countdown) {
                          setStartCountdown(true);
                        } else {
                          handleStart(position);
                        }
                      }
                    }}
                    className={classNames('rounded-circle', {
                      'text-primary': playing
                    })}
                  />
                </>
              )}
            </motion.div>
          )}
        </Flex>
        {(srcBlob || bufferBlob) && visualize && (
          <VisualSeeker
            position={position}
            setPosition={handleSeek}
            width={Math.min(maxWidth, width)}
            blob={srcBlob || bufferBlob}
          />
        )}
      </Flex>
    );
  }
);
const getMediaStreamPreview = async fileId => {
  const r = await getMediaStream(fileId);
  if (!r) return null;
  return URL.createObjectURL(r);
};
export const useAudioStream = (fileId: FileId) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['audioUrl', fileId],
    staleTime: Infinity,
    queryFn: async () => {
      if (Array.isArray(fileId)) {
        return Promise.all(fileId.map(id => getMediaStreamPreview(id)));
      }
      const src = await getMediaStreamPreview(fileId);
      return [src];
    }
  });
  return {
    src: data,
    isLoading,
    error
  };
};
export const AudioFilePlayer = ({
  fileId,
  ...props
}: Omit<AudioPlayerProps, 'url'> & { fileId: FileId }) => {
  const { src, isLoading } = useAudioStream(fileId);
  return isLoading ? (
    <Skeleton />
  ) : (
    !!src && src.map(s => <AudioPlayer key={s} url={s} {...props} />)
  );
};
export default AudioPlayer;
