/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Flex from '../Flex';
import { RemoteData, useAdvanceTable } from './AdvanceTableProvider';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
import LoadingButton from '../LoadingButton';
const PageInput = ({ lastPage, setPage, pagination }) => {
  const methods = useForm();
  useEffect(() => {
    methods.setValue('pageIndex', pagination.pageIndex + 1);
  }, [pagination.pageIndex]);
  return (
    <FormProvider {...methods}>
      <WizardInput
        type="number"
        name="pageIndex"
        hideLabel
        formGroupProps={{ noMb: true }}
        formControlProps={{
          className: 'form-control-sm input-spin-none px-2',
          style: {
            width: '40px'
          },
          onFocus: e => e.target.select(),
          max: lastPage,
          min: 1
        }}
        registerProps={{
          onChange: debounce(e => setPage(Number(e.target.value) - 1), 200)
        }}
      />
    </FormProvider>
  );
};
export const AdvanceTableFooter = ({
  viewAllBtn,
  navButtons = true,
  rowsPerPageOptions = [10, 25, 50, 100, 1000],
  className,
  remoteData = null
}: {
  viewAllBtn?: boolean;
  navButtons?: boolean;
  rowsPerPageOptions?: number[];
  className?: string;
  remoteData?: RemoteData<any>;
}) => {
  const {
    getPrePaginationRowModel,
    getCanPreviousPage,
    getCanNextPage,
    pagination,
    setPagination,
    getRowModel
  } = useAdvanceTable();
  const rowsLength =
    remoteData?.totalCount || getPrePaginationRowModel().rows.length;
  const handlePageSizeChange = (pageSize: number) => {
    setPagination(p => ({
      ...p,
      pageSize
    }));
  };
  const handlePageChange = (pageIndex: number) => {
    setPagination(p => ({
      ...p,
      pageIndex
    }));
  };
  const lastPage = Math.ceil(rowsLength / pagination.pageSize) - 1;
  const isEndButtonLoading = pageIndex =>
    remoteData?.paging.pageIndex === pageIndex && remoteData?.paging.isLoading;
  const isNextButtonLoading = () =>
    remoteData?.paging.pageIndex > pagination.pageIndex &&
    remoteData?.paging.isLoading;
  const isPrevButtonLoading = () =>
    remoteData?.paging.pageIndex < pagination.pageIndex &&
    remoteData?.paging.isLoading;
  return (
    <div className="mt-3 p-2">
      <Flex
        className={classNames(
          className,
          'align-items-center justify-content-between'
        )}
      >
        <Flex alignItems="center" className="fs--1 d-none d-md-flex">
          <p className="mb-0">
            <span className="d-none d-sm-inline-block me-2">
              {Math.min(
                rowsLength,
                pagination.pageSize * pagination.pageIndex + 1
              )}{' '}
              to{' '}
              {pagination.pageSize * pagination.pageIndex +
                getRowModel().rows.length}{' '}
              of {rowsLength}
            </span>
          </p>
          <p className="mb-0 mx-2">Show:</p>
          <Form.Select
            size="sm"
            className="w-auto pe-5 tour-per-page"
            onChange={e => handlePageSizeChange(Number(e.target.value))}
            defaultValue={pagination.pageSize}
          >
            {rowsPerPageOptions.map(value => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Flex>
        <Flex className="flex-1">
          {navButtons && (
            <Flex alignItems={'center'} justifyContent="end" className="flex-1">
              <LoadingButton
                loading={isEndButtonLoading(0)}
                size="sm"
                variant={getCanPreviousPage() ? 'primary' : 'light'}
                onClick={() => handlePageChange(0)}
                className={classNames({ disabled: !getCanPreviousPage() })}
              >
                <span className="d-none d-sm-inline">First</span>
                <span className="d-sm-none">
                  <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </span>
              </LoadingButton>
              <LoadingButton
                loading={isPrevButtonLoading()}
                size="sm"
                variant={getCanPreviousPage() ? 'primary' : 'light'}
                onClick={() => handlePageChange(pagination.pageIndex - 1)}
                className={classNames('ms-1', {
                  disabled: !getCanPreviousPage()
                })}
              >
                <span className="d-none d-sm-inline">Previous</span>
                <span className="d-sm-none">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </span>
              </LoadingButton>
              <span className="fs--1 px-2">
                <PageInput
                  pagination={pagination}
                  lastPage={Math.ceil(rowsLength / pagination.pageSize)}
                  setPage={handlePageChange}
                />
              </span>
              <LoadingButton
                loading={isNextButtonLoading()}
                size="sm"
                variant={getCanNextPage() ? 'primary' : 'light'}
                className={classNames('px-sm-4', {
                  disabled: !getCanNextPage()
                })}
                onClick={() => handlePageChange(pagination.pageIndex + 1)}
              >
                <span className="d-none d-sm-inline">Next</span>
                <span className="d-sm-none">
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
              </LoadingButton>
              <LoadingButton
                loading={isEndButtonLoading(lastPage)}
                size="sm"
                variant={getCanNextPage() ? 'primary' : 'light'}
                className={classNames('ms-1', {
                  disabled: !getCanNextPage()
                })}
                onClick={() => handlePageChange(lastPage)}
              >
                <span className="d-none d-sm-inline">Last</span>
                <span className="d-sm-none">
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </span>
              </LoadingButton>
            </Flex>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

export default AdvanceTableFooter;
