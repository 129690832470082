import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal } from 'react-bootstrap';
import AppContext from 'context/Context';
import { useFormContext, useWatch } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
import { getHours, setHours } from 'date-fns';
import { ResponsiveModal } from 'components/common/Modals';
import CalendarPicker from './CalendarPicker';

const AddScheduleModal = ({
  setIsOpenScheduleModal,
  isOpenScheduleModal,
  customFields,
  onSubmit
}) => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const handleClose = () => {
    setIsOpenScheduleModal(!isOpenScheduleModal);
  };

  const handleSubmitForm = data => {
    if (onSubmit) {
      onSubmit(data);
    }
    // setInitialEvents([...initialEvents, { id: uuid(), ...data }]);
    setIsOpenScheduleModal(false);
  };
  const { handleSubmit, setValue, getValues } = useFormContext();
  const allDay = useWatch({ name: 'allDay' });
  return (
    <ResponsiveModal
      show={isOpenScheduleModal}
      onHide={handleClose}
      contentClassName="border"
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit(handleSubmitForm)(e);
        }}
      >
        <Modal.Header
          closeButton
          closeVariant={isDark ? 'white' : undefined}
          className="bg-light px-x1 border-bottom-0"
        >
          <Modal.Title as="h5"> Create Event</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <CalendarPicker name="calendarId" label="Calendar" />
          <WizardInput name="title" />
          <WizardInput
            type="switch"
            name="allDay"
            registerProps={{ required: false }}
          />
          {!allDay && (
            <>
              <WizardInput
                name="start"
                type="datetime"
                registerProps={{
                  onChange: e => {
                    const v = e.target.value;
                    if (v > getValues('end')) {
                      setValue('end', setHours(v, getHours(v) + 1));
                    }
                  }
                }}
              />
              <WizardInput
                name="end"
                type="datetime"
                registerProps={{
                  validate: (v, d) => {
                    const start = getValues('start');
                    if (v < start) {
                      return 'End time must be greater than start time';
                    }
                  }
                }}
              />
            </>
          )}
          <WizardInput
            type="textarea"
            name="description"
            registerProps={{ required: false }}
          />
          {customFields && customFields()}
        </Modal.Body>
        <Modal.Footer className="bg-light px-x1 border-top-0">
          <Button variant="primary" type="submit" className="px-4 mx-0">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </ResponsiveModal>
  );
};

AddScheduleModal.propTypes = {
  setIsOpenScheduleModal: PropTypes.func.isRequired,
  isOpenScheduleModal: PropTypes.bool.isRequired,
  customFields: PropTypes.func,
  onSubmit: PropTypes.func
};

export default AddScheduleModal;
