import { useMutation } from '@tanstack/react-query';
import PageLoader from 'components/common/PageLoader';
import { domainConfigs } from 'components/notification/config';
import { getDomainItemUrl } from 'hooks/useDomainRouter';
import useUrlSearchParams from 'hooks/useUrlSearchParams';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default () => {
  const u = useUrlSearchParams();
  useEffect(() => {
    if (!u?.code) return;
    console.log(u);
    window.parent.postMessage({
      data: { accessCode: u.code, state: u.state },
      source: 'oneid-redirect'
    });
  }, [u?.code]);
  useEffect(() => {
    if (!u?.error) return;
    window.parent.postMessage({
      source: 'oneid-redirect',
      error: new Error(
        u.error ? u.error + ': ' + u.error_oneid : 'Unknown error'
      )
    });
  }, [u?.error]);

  //if the request is not being dealt with in an iframe, redirect to the screening page after a second
  const nav = useNavigate();
  const { mutate } = useMutation({
    mutationFn: async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
      nav(
        '/my/screening' +
          ('?code=' +
            (u.code || '') +
            '&error=' +
            (u.error || u.error_oneid || ''))
      );
    }
  });
  useEffect(() => {
    mutate();
  }, [u?.state]);
  return <PageLoader />;
};
