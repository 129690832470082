import { useMutation, useQuery } from '@tanstack/react-query';
import {
  DomainUserAccess,
  getDomainUserAccess,
  ItemAccessDelegation
} from 'apis/flex/users';
import { useItemAccessDelegation } from 'components/app/users/hooks/useAccessDelegation';
import React from 'react';
import AdvanceTableProvider from './advance-table-v2/AdvanceTableProvider';
import AdvanceTable from './advance-table-v2/AdvanceTable';
import { SelectedDomainItems } from './DomainItemSelector';
import { domainConfigs } from 'components/notification/config';
import { EventPrefix } from 'apis/flex/notifications';
import { domainToSentence } from './DomainTimeline';
import Search from './Search';
import UserSelector, {
  SelectedUsers
} from 'components/app/users/widgets/selector/UserSelector';
import { format } from 'date-fns';
import LoadingButton from './LoadingButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faShare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
import ActionFooter from './ActionFooter';
import { Guard } from 'hooks/useGuard';
import { ModalConfirm } from './Modals';
import classNames from 'classnames';
const useDomainAccess = ({ domain, itemId, ...props }: any) => {
  return useQuery<(DomainUserAccess & { id: number })[], Error>({
    queryKey: ['DomainItemAccess', domain, itemId],
    queryFn: () => getDomainUserAccess(domain, itemId),
    enabled: !!domain && !!itemId,
    select: d => d.map((dd, i) => ({ ...dd, id: i })),
    ...props
  });
};
export default ({ domain, itemId }: { domain: string; itemId: number }) => {
  const { data: delegations, isLoading: isLoadingDelegation } =
    useItemAccessDelegation({
      filters: { domain, itemId },
      useFilter: !!domain && !!itemId
    });
  const { data, isLoading } = useDomainAccess({ domain, itemId });
  return (
    <div>
      <AdvanceTableProvider
        flat
        title="Users with access"
        columns={[
          {
            id: 'userId',
            domain: 'user'
          },
          {
            id: 'Reason',
            accessorFn: d =>
              d.isDomainAdmin
                ? 'Domain admin'
                : d.isSuper
                ? 'Super user'
                : delegations?.find(dd => dd.userId === d.userId)
                ? 'Shared'
                : d.inheritedFrom
                ? 'Inherited'
                : d.accessGroupId
                ? 'Group'
                : d.userRoleId
                ? 'Role'
                : 'Item owner'
          },
          {
            id: 'inheritedFrom',
            formatter: v => {
              const val = v();
              return (
                !!val &&
                (domainConfigs[val.domain as EventPrefix]?.crudHook ? (
                  <SelectedDomainItems
                    size={'xs'}
                    bold={false}
                    compact
                    maxShown={1}
                    domain={val.domain}
                    ids={[val.itemId]}
                  />
                ) : (
                  domainToSentence(v().domain) + ' id: ' + v().itemId
                ))
              );
            }
          },
          {
            id: 'accessGroupId',
            domain: 'access-group'
          }
        ]}
        data={data}
        isLoading={isLoading || isLoadingDelegation}
      >
        <AdvanceTable />
      </AdvanceTableProvider>
      <div>
        <h6 className="mt-4">Shared with</h6>
        <Search data={delegations} keys={['userFirstName', 'userSurname']}>
          {d => (
            <div
              style={{ maxHeight: '200px', overflow: 'auto' }}
              className="mx-4 my-2"
            >
              {d?.length ? (
                d.map(delegation => (
                  <Delegation key={delegation.id} delegation={delegation} />
                ))
              ) : (
                <div className="text-muted fs--1 m-auto w-100 text-center py-3">
                  No users found
                </div>
              )}
            </div>
          )}
        </Search>
        <Guard roles={[domain + '.admin']}>
          <AddDelegation
            domain={domain}
            itemId={itemId}
            alreadyWithAccess={data
              ?.map(d => d.userId)
              .concat(
                delegations
                  ?.filter(d => !d.endDate || d.endDate > new Date())
                  .map(d => d.userId)
              )}
          />
        </Guard>
      </div>
    </div>
  );
};
const AddDelegation = ({
  domain,
  itemId,
  alreadyWithAccess
}: {
  domain: string;
  itemId: number;
  alreadyWithAccess?: number[];
}) => {
  const [open, setOpen] = React.useState(false);
  const { add, isAdding } = useItemAccessDelegation({
    afterSave: () => setOpen(false)
  });
  const methods = useForm<{
    userId: number;
    startDate: Date;
    endDate?: Date;
  }>();
  const handleSubmit = methods.handleSubmit(v => add({ ...v, domain, itemId }));
  return (
    <>
      {open ? (
        <div className="my-3 w-100">
          <FormProvider {...methods}>
            <UserSelector
              name="userId"
              label="User"
              filter={{ first: 'id', second: alreadyWithAccess, negate: true }}
            />
            <WizardInput name="startDate" label="From" type="date" />
            <WizardInput
              name="endDate"
              type="date"
              label="Until"
              registerProps={{ required: false }}
            />
            <ActionFooter
              onSubmit={handleSubmit}
              onCancel={() => setOpen(false)}
              isLoading={isAdding}
            />
          </FormProvider>
        </div>
      ) : (
        <Button
          className="w-100 my-3"
          variant="falcon-primary"
          size="sm"
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon className="me-2" icon={faShare} />
          Share
        </Button>
      )}
    </>
  );
};
const Delegation = ({ delegation }: { delegation: ItemAccessDelegation }) => {
  const { remove, isRemoving } = useItemAccessDelegation();
  return (
    <div
      className={classNames(
        'mb-2 d-flex align-items-center w-100 justify-content-between',
        { 'opacity-50': new Date(delegation.startDate) > new Date() }
      )}
    >
      <div>
        <SelectedUsers size="sm" ids={[delegation.userId]} />
      </div>
      {new Date(delegation.startDate) > new Date() && (
        <div className="text-muted ms-2 fs--1">
          not active until{' '}
          {format(new Date(delegation.startDate), 'dd/MM/yyyy')}
        </div>
      )}
      {delegation.endDate && (
        <div className="text-muted ms-2 fs--1">
          expires {format(delegation.endDate, 'dd/MM/yyyy')}
        </div>
      )}
      <div>
        <ModalConfirm
          body="This user might not be able to access this resource"
          title="Unshare resource"
          confirmText="Remove"
          onConfirm={done => remove(delegation.id, { onSuccess: done })}
          isLoading={isRemoving}
        >
          <Button variant="link" size="sm">
            <FontAwesomeIcon className="text-danger" icon={faTrashAlt} />
          </Button>
        </ModalConfirm>
      </div>
    </div>
  );
};
