import classNames from 'classnames';
import React from 'react';
import { Spinner, SpinnerProps } from 'react-bootstrap';

export default ({ className, style, ...props }: SpinnerProps) => {
  return (
    <div
      className={classNames('d-inline-block', className)}
      style={{ maxHeight: '1em', scale: '0.6', verticalAlign: 'sub', ...style }}
    >
      <Spinner size="sm" {...props} />
    </div>
  );
};
