import React, { useEffect, useRef } from 'react';
import ReactECharts from 'echarts-for-react'; // or var ReactECharts = require('echarts-for-react');
import { getColor, grays, themeColors } from 'helpers/utils';
import { FiClock } from 'react-icons/fi';

const SimpleClock = ({
  startTime,
  endTime,
  height,
  segmentColor = themeColors.primary,
  bgColor = grays[100]
}: {
  startTime: string;
  endTime: string;
  height?: string | number;
  segmentColor?: string;
  bgColor?: string;
}) => {
  const startHour = Number(startTime?.split(':')[0]);
  const startMinute = Number(startTime?.split(':')[1]);
  const endHour = Number(endTime?.split(':')[0]);
  const endMinute = Number(endTime?.split(':')[1]);
  // Calculate the angles for the start and end hours
  const calcAngle = (hour, minute) => ((hour % 12) + minute / 60) * 30;
  const startAngle = calcAngle(startHour, startMinute);
  let endAngle = calcAngle(endHour, endMinute);

  // Handle cases where endHour < startHour (crossing midnight)
  if (startHour < 12 && endHour >= 12) {
    endAngle += 360;
  }
  if (startHour < 12 && endHour >= 12) {
    // endAngle += 360;
  }

  const coverage = endAngle - startAngle;
  // console.log(startHour%12,startAngle,endHour%12, endAngle, 90-startAdjust)
  // Define the chart options
  const option = {
    series: [
      {
        type: 'pie',
        // radius: ['70%', '100%'], // Donut shape
        startAngle: 90 - startAngle, // Start at 12 o'clock
        data: [
          // {
          //   value: startAngle, // Gap before the blue segment
          //   name: "Gap",
          //   itemStyle: { color: "black" },
          // },
          {
            value: coverage, // Blue segment
            name: startTime + ' - ' + startTime,
            itemStyle: { color: segmentColor }
          },
          {
            value: 360 - coverage, // Remaining circle
            name: 'Remaining',
            itemStyle: { color: bgColor }
          }
        ],
        label: { show: false } // Hide labels
      }
    ]
  };
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  useEffect(() => {
    if (chartRef.current) {
      const resizeObserver = new ResizeObserver(() =>
        chartRef.current?.resize()
      );
      resizeObserver.observe(containerRef.current);

      // Cleanup on unmount
      return () => {
        chartRef.current?.dispose();
        resizeObserver.disconnect();
      };
    }
  }, [chartRef.current]);
  return (
    <div
      ref={containerRef}
      style={{ position: 'relative', width: '100%', height: '100%' }}
    >
      {/* Chart */}
      <ReactECharts
        ref={chartRef}
        option={option}
        style={{ width: height, height: height, opacity: 0.8 }}
      />

      {/* Overlay */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pointerEvents: 'none' // Allow interaction with the chart
        }}
      >
        <FiClock className="w-100 h-100" />
      </div>
    </div>
  );
};

export default SimpleClock;
