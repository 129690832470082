import { useMsal } from "@azure/msal-react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { flexLogin } from "apis/flex/auth";
import { getItemFromStore, setItemToStore } from "helpers/utils";
import { socket } from "index";
import { useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { publicLoginRequest } from "authConfig";
import { LoginContext } from "context/Context";
const attemptLogin = async () => {
  const { data: user } = await flexLogin();
  if (!user) throw new Error("No data");
  setItemToStore("loginErrorCount", 0);
  return user;
};
export default () => {
  const { settings, setSettings } = useContext(LoginContext);
  const t = useMsal();
  const queryClient = useQueryClient();
  const [errorCount, setErrorCount] = useState(0);
  const query = useQuery({
    staleTime: 1e3 * 60 * 10,
    retry(failureCount, error) {
      setErrorCount(failureCount);
      if (failureCount > 2) {
        return false;
      }
      return true;
    },
    // refetchOnWindowFocus: false,
    queryFn: attemptLogin,
    queryKey: ["flexLogin"]
  });
  const currentToken = getItemFromStore("client-access-token", null);
  useEffect(() => {
    if (!currentToken && !query.isFetching && !query.isError) {
      query.refetch();
    }
  }, [currentToken]);
  useEffect(() => {
    if (errorCount > 2) {
      t.instance.acquireTokenSilent(publicLoginRequest).then((e) => {
        setItemToStore("client-access-token", e.accessToken);
        query.refetch();
      }).catch(async (e) => {
        await t.instance.acquireTokenRedirect(publicLoginRequest);
      });
    }
  }, [errorCount]);
  useEffect(() => {
    if (query.isSuccess) {
      setErrorCount(0);
    }
  }, [query.isSuccess]);
  useEffect(() => {
    const sendLogin = () => {
      socket.emit("login", query.data);
    };
    if (query.data && query.data.firstName) {
      queryClient.invalidateQueries(["Notifications"]);
      Sentry.setUser({
        email: query.data.email,
        name: query.data.firstName + " " + query.data.surname
      });
      sendLogin();
      socket.io.on("reconnect", sendLogin);
      if (!settings.welcomed) {
        setSettings((s) => ({ ...s, welcomed: true }));
        toast.success("Welcome back " + query.data.firstName + "!", {
          containerId: "default"
        });
      }
    }
    return () => {
      socket.io.off("reconnect", sendLogin);
    };
  }, [query.data]);
  return { ...query, errorCount };
};
