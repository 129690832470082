import DomainTable from 'components/common/DomainTable';
import React from 'react';
import useAccessGroups from '../team/permissions/useAccessGroups';
import { useAppRoleOptions } from '../team/permissions/UserPermissions';
import SoftBadge, { SoftBadgeProps } from 'components/common/SoftBadge';
import { AccessGroupRole, AppRole } from 'apis/flex/auth';
import DomainIcon from 'components/common/DomainIcon';
import { startCase } from 'lodash';
import { domainToSentence } from 'components/common/DomainTimeline';
import { EventPrefix } from 'apis/flex/notifications';
import { CloseButton } from 'react-bootstrap';
import classNames from 'classnames';
import { RoleLevel } from 'apis/flex/users';
import { Variant } from 'react-bootstrap/esm/types';

export default () => {
  const { data: appRoles } = useAppRoleOptions();
  return (
    <DomainTable
      domain="access-group"
      crudHook={useAccessGroups}
      // editFields={['name', 'description', 'users', 'roles']}
      columns={[
        'name',
        'description',
        {
          id: 'users',
          domain: 'user',
          accessorFn: d => d?.users?.map(u => u.userId)
        },
        {
          id: 'roles',
          accessorFn: d =>
            d?.roles?.map(
              r =>
                domainToSentence(r.collectionName as EventPrefix) +
                ' ' +
                r.levelName
            ),
          size: 400,
          formatter: (v, d) => (
            <div className="d-flex flex-wrap gap-2" style={{ width: '400px' }}>
              {v()
                ?.slice(0, 4)
                .map((r, i) => (
                  <PermissionBadge key={r.id} role={d.roles?.[i]} />
                ))}
              {v()?.length > 4 && (
                <SoftBadge
                  bg="secondary"
                  className="p-0 d-flex align-items-center d-inline-flex pe-2"
                >
                  +{v()?.length - 4} more
                </SoftBadge>
              )}
            </div>
          )
        }
      ]}
    />
  );
};
export const PermissionBadge = ({
  role,
  onClose,
  className,
  ...rest
}: {
  role: { collectionName?: string; levelName?: string };
  onClose?: () => void;
} & Partial<Omit<SoftBadgeProps, 'role' | 'onClose'>>) => {
  const levelColors: Record<RoleLevel, Variant> = {
    admin: 'danger',
    creator: 'warning',
    editor: 'success',
    viewer: 'info'
  };
  if (!role) {
    return null;
  }
  return (
    <SoftBadge
      bg={levelColors[role?.levelName]}
      className={classNames(
        className,
        'p-0 d-flex align-items-center d-inline-flex pe-2'
      )}
      {...rest}
    >
      <DomainIcon
        size="xs"
        className={`bg-${levelColors[role?.levelName]}-subtle text-${
          levelColors[role?.levelName]
        } shadow-none border-0 p-0`}
        domain={role?.collectionName}
      />
      {domainToSentence(role.collectionName as EventPrefix) +
        ' ' +
        role.levelName}
      {onClose && (
        <CloseButton
          className="ms-1 fs--2"
          // style={{ opacity: 0.5 }}
          onClick={onClose}
        />
      )}
    </SoftBadge>
  );
};
