import useVisibilityObserver from 'hooks/useVisibilityObserver';
import React, { useEffect, useRef } from 'react';
export default ({
  placeholder,
  height = '10vh',
  children,
  onRendered,
  onVisible
}: {
  placeholder?: React.ReactNode;
  height?: string;
  children: React.ReactNode;
  onRendered?: () => void;
  onVisible?: () => void;
}) => {
  const ref = useRef(null);
  const [hasRendered, setHasRendered] = React.useState(false);
  const { isVisible } = useVisibilityObserver(ref, '0px');
  const place = placeholder || (
    <div className="d-block" style={{ height }}></div>
  );
  useEffect(() => {
    if (isVisible && !hasRendered) {
      setHasRendered(true);
      onRendered?.();
    }
    if (isVisible) {
      onVisible?.();
    }
  }, [isVisible]);
  return <div ref={ref}>{isVisible || hasRendered ? children : place}</div>;
};
