import React from 'react';
import WizardInput from 'components/wizard/WizardInput';
import { useWatch } from 'react-hook-form';
import ContractPicker from './ContractPicker';

const EmployeeContractForm = ({
  name = '',
  includeDates
}: {
  includeDates?: boolean;
  name?: '' | `${string}.`;
}) => {
  const id = useWatch({ name: name + 'id' });
  return (
    <>
      <ContractPicker
        name={name + 'id'}
        label="Contract"
        registerProps={{ required: false }}
        filter={{ isActive: true }}
      />
      {id && includeDates && (
        <>
          <WizardInput
            type="date"
            label="Contract start"
            name={name + 'startDate'}
          />
          <WizardInput
            type="date"
            label="Contract end"
            name={name + 'endDate'}
            registerProps={{
              required: false,
              validate: (v, d) =>
                !v ||
                new Date(v) > new Date(d[name + 'startDate']) ||
                'Must be after start date'
            }}
          />
        </>
      )}
    </>
  );
};
export default EmployeeContractForm;
