import { Shift } from 'apis/flex/hr';
import {
  DomainItemSelectorProps,
  getDomainItemSelector
} from 'components/common/DomainItemSelector';
import React, { ReactNode } from 'react';
import { AvailableShiftConstraints, useAvailableShifts } from './useShifts';
import { mergeFilters } from 'hooks/useDefaultCrud';

const ShiftSelector = getDomainItemSelector('shift');

export const AvailableShiftsSelector = ({
  name,
  availableTo,
  filter: defaultFilter,
  render,
  ...defaultProps
}: DomainItemSelectorProps<Shift> & {
  render: (
    item: (props: DomainItemSelectorProps<Shift>) => JSX.Element,
    date: `${string}-${string}-${string}`
  ) => ReactNode;
  availableTo: AvailableShiftConstraints;
}) => {
  const { data, isLoading } = useAvailableShifts(availableTo);
  return (
    data &&
    Object.keys(data).map((date: `${string}-${string}-${string}`) =>
      render(
        ({ filter, ...props }) => (
          <ShiftSelector
            onNewClick={false}
            name={`${name}.${date}`}
            loading={isLoading}
            filter={mergeFilters(defaultFilter, filter, [
              {
                first: 'id',
                second: data[date]?.map(d => d.id).filter(Boolean)
              }
            ])}
            {...defaultProps}
            {...props}
          />
        ),
        date
      )
    )
  );
};
export default ShiftSelector;
