import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default ({ count = 1 }) => {
  return new Array(count).map((_, i) => (
    <div className="mb-3 w-100" key={i}>
      <Skeleton className="mb-2 w-25" height={'1rem'} />
      <Skeleton className="mb-3 w-75" height={50} />
    </div>
  ));
};
