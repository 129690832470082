import React from 'react';
import classNames from 'classnames';
import { domainIconWidths, settings } from 'config';
import { FiBox } from 'react-icons/fi';

export default ({
  domain = null,
  size = 'sm',
  className = null,
  icon,
  color,
  width = null,
  bg = '100',
  text = null,
  defaultColor
}: {
  domain?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  icon?: React.ReactNode;
  color?: string;
  width?: number;
  bg?: string;
  text?: string;
  defaultColor?: string;
}) => {
  const iconWidth = width || domainIconWidths[size];
  const domainColor =
    color || settings.domains[domain]?.color || defaultColor || 'primary';
  const domainIcon = icon || (domain && settings.domains[domain]?.icon);
  const height = iconWidth;
  return (
    <div
      style={{ width: height, height }}
      className={classNames(
        className,
        `d-inline-flex align-items-center justify-content-center rounded-circle text-center`,
        `border border-1 border-300`,
        // `shadow-none`,
        `shadow-sm`,
        {
          'p-1 fs-0': size === 'xs',
          'p-2 fs-2': size === 'sm',
          'p-2 fs-4': size === 'md',
          'p-3 fs-5': size === 'lg',
          'p-5 fs-8': size === 'xl',
          [`bg-${domainColor}-subtle`]: !bg,
          [`bg-${bg}`]: !!bg,
          [`text-${text}`]: !!text,
          [`text-${domainColor}`]: !text
        }
      )}
    >
      {domainIcon || <FiBox />}
    </div>
  );
};
