import React, { useEffect, useMemo, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import RenderWhenVisible from './RenderWhenVisible';
import { useDebounce } from './Debouncer';

export default <TData,>({
  data,
  getNext,
  children,
  totalPages,
  isLoadingNext,
  placeholder,
  ...rest
}: {
  data: (TData[] | 'loading')[];
  getNext: () => void;
  isLoadingNext?: boolean;
  children: (props: { data: TData[]; isLoading: boolean }) => React.ReactNode;
  totalPages: number;
  placeholder?: React.ReactNode;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'onChange'>) => {
  // const listRef = useRef(null);
  // const handleScroll = () => {
  //   const container = listRef.current;
  //   if (
  //     container.scrollTop + container.clientHeight >
  //       container.scrollHeight * 0.9 &&
  //     !isLoadingNext
  //   ) {
  //     getNext();
  //   }
  // };
  // useEffect(() => {
  //   listRef.current?.addEventListener('scroll', handleScroll);
  //   return () => listRef.current?.removeEventListener('scroll', handleScroll);
  // }, [listRef.current]);
  const childrenMemo = useMemo(() => {
    return children({
      isLoading: !data?.[0] || data[0] === 'loading',
      data: data?.filter(d => d !== 'loading')?.flat() as TData[]
    });
  }, [children, data]);
  return (
    <div {...rest}>
      {childrenMemo}
      <ScrollListener
        fetchNextPage={getNext}
        canFetch={data?.filter(d => d !== 'loading').length < totalPages}
        isFetching={isLoadingNext}
      >
        {placeholder || <Skeleton height={50} />}
      </ScrollListener>
    </div>
  );
};
export const ScrollListener = ({
  children,
  fetchNextPage,
  canFetch,
  isFetching
}) => {
  const [isVisible, setIsVisible] = React.useState(false);
  useEffect(() => {
    if (!isFetching && !!isVisible) {
      fetchNextPage();
      setIsVisible(false);
    }
  }, [isVisible, isFetching, fetchNextPage]);
  return (
    <RenderWhenVisible onVisible={() => setIsVisible(true)}>
      <div className="d-block" style={{ minHeight: '50px' }}>
        {!!canFetch && children}
      </div>
    </RenderWhenVisible>
  );
};
