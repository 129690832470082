import React from 'react';
import DetailPage from 'components/common/detail/DetailPage';
import useJobRoles from './useJobRoles';
import { useNavigate, useParams } from 'react-router-dom';
import DetailCard from 'components/common/detail/DetailCard';
import WizardInput from 'components/wizard/WizardInput';
import { EmployeeSelector } from '../staff/widgets/EmployeeSelector';
import ContractPicker from 'components/app/documents/contracts/ContractPicker';
import { DepartmentSelector } from '../departments/SelectedDepartment';
import { AccessGroupSelect } from 'components/app/settings/team/permissions/UserPermissions';
import { ResourceGroupSelector } from 'components/app/pm/projects/resourceGroups/ResourceGroupSelector';
import NewEmployeeForm from 'components/app/users/detail/NewEmployeeForm';

export const JobRoleForm = () => {
  return (
    <>
      <DepartmentSelector
        name="departmentId"
        label="Department"
        registerProps={{ required: false }}
      />
      <WizardInput name="jobTitle" label="Job Title" />
      <WizardInput
        name="jobDescription"
        type="texteditor"
        pluginProps={{ height: 200 }}
        label="Job Description"
      />
    </>
  );
};
export const defaultJobRoleValues = {
  jobTitle: '',
  jobDescription: '',
  defaultManagerId: 0,
  defaultContractId: 0,
  departmentId: 0,
  defaultResourceGroupIds: [],
  defaultAccessGroupIds: []
};
export default () => {
  const { id } = useParams();
  const { upsert, isUpserting, data, isLoading } = useJobRoles({ id });
  const nav = useNavigate();
  return (
    <DetailPage
      isSaving={isUpserting}
      onSave={upsert}
      data={data?.[0]}
      isLoading={isLoading}
      domain="job-title"
      title={data?.[0]?.jobTitle || 'New Job Role'}
      afterSave={d => nav('/hr/company/roles/' + d.id)}
      defaultValues={defaultJobRoleValues}
    >
      <DetailCard id="details" title="Details">
        <JobRoleForm />
      </DetailCard>
      <DetailCard
        id="defaults"
        title="Defaults"
        subtitle="Will only apply to new employees. Can be changed on a per-employee basis."
      >
        <NewEmployeeForm includeDates={false} name="defaults." />
      </DetailCard>
    </DetailPage>
  );
};
