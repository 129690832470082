import React from 'react';
import useUserPrescreening from './hooks/useUserPrescreening';
import PrescreeningDetail from './PrescreeningDetail';

export default () => {
  const { data: screening } = useUserPrescreening({
    select: d => d[0],
    staleTime: Infinity
  });
  return <PrescreeningDetail id={screening?.id} />;
};
