/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { debounce } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { DebouncedSearchInput } from '../Search';

const AdvanceTableSearchBox = ({
  placeholder = 'Search...',
  className,
  formControlProps,
  onChange,
  autoFocus = false,
  value = ''
}: {
  placeholder?: string;
  className?: string;
  formControlProps?: any;
  onChange?: (value: string) => void;
  autoFocus?: boolean;
  value?: string;
}) => {
  return (
    // <InputGroup className={classNames(className, 'position-relative d-flex')}>
    //   <div className="flex-1">
    <DebouncedSearchInput
      value={value}
      onChange={val => {
        // console.log('Table search box heard onChange', val);
        onChange(val);
      }}
      autoFocus={autoFocus}
      size="sm"
      showIcon={false}
      placeholder={placeholder}
      className="shadow-none"
      wrapperClassName="input-group-sm"
      after={
        <Button
          size="sm"
          variant="outline-secondary"
          className="border-300 hover-border-secondary"
        >
          <FontAwesomeIcon icon="search" className="fs--1" />
        </Button>
      }
      {...formControlProps}
    />
    //   </div>
    // </InputGroup>
  );
};

export default AdvanceTableSearchBox;
