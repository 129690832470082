import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';
import { Section } from './helpers';

export default forwardRef<HTMLDivElement, { id?: string }>((props, ref) => {
  return (
    <Card className="mb-3" id={props.id} ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Your rights</h5>
      </Card.Header>
      <Card.Body>
        <Section title="Your duty to inform us of changes">
          It is important that the personal information we hold about you is
          accurate and current. Please keep us informed if your personal
          information changes.
        </Section>
        <Section title="Your rights in connection with personal information">
          Under certain circumstances, by law you have the right to:
          <ul>
            <li>
              Request access to your personal information (commonly known as a
              "data subject access request"). This enables you to receive a copy
              of the personal information we hold about you and to check that we
              are lawfully processing it.
            </li>
            <li>
              Request correction of the personal information that we hold about
              you. This enables you to have any incomplete or inaccurate
              information we hold about you corrected.
            </li>
            <li>
              Request erasure of your personal information. This enables you to
              ask us to delete or remove personal information where there is no
              good reason for us continuing to process it. You also have the
              right to ask us to delete or remove your personal information
              where you have exercised your right to object to processing (see
              below).
            </li>
            <li>
              Object to processing of your personal information where we are
              relying on a legitimate interest (or those of a third party) and
              there is something about your particular situation which makes you
              want to object to processing on this ground. You also have the
              right to object where we are processing your personal information
              for direct marketing purposes.
            </li>
            <li>
              Request the restriction of processing of your personal
              information. This enables you to ask us to suspend the processing
              of personal information about you, for example if you want us to
              establish its accuracy or the reason for processing it.
            </li>
            <li>
              Request the transfer of your personal information to another
              party.
            </li>
            <li>
              Withdraw consent at any time where we are relying on consent to
              process your personal data. However, this will not affect the
              lawfulness of any processing carried out before you withdraw your
              consent. If you withdraw your consent, we may not be able to
              provide certain products or services to you. We will advise you if
              this is the case at the time you withdraw your consent.
            </li>
          </ul>
          If you want to review, verify, correct or request erasure of your
          personal information, object to the processing of your personal data,
          request that we transfer a copy of your personal information to
          another party or withdraw consent, please{' '}
          <a href="mailto:info@falcon-ui.com">
            contact us in writing via email
          </a>
          .
        </Section>
        <Section title="No fee usually required">
          You will not have to pay a fee to access your personal information (or
          to exercise any of the other rights). However, we may charge a
          reasonable fee if your request for access is clearly unfounded or
          excessive. Alternatively, we may refuse to comply with the request in
          such circumstances.
        </Section>
        <Section title="What we may need from you">
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access the information
          (or to exercise any of your other rights). This is another appropriate
          security measure to ensure that personal information is not disclosed
          to any person who has no right to receive it.
        </Section>
        <Section
          title="Time limit to respond
"
        >
          We try to respond to all legitimate requests within one month.
          Occasionally it may take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.
        </Section>
      </Card.Body>
    </Card>
  );
});
