import { userPreferenceApi } from "apis/flex/users";
import { defaultCrudHookBuilder } from "hooks/useDefaultCrud";
import { useUser } from "hooks/useUser";
import { useEffect, useState } from "react";
const useUserPreferencesHook = defaultCrudHookBuilder(
  "UserPreferences",
  userPreferenceApi,
  {
    staleTime: Infinity
  }
);
export const useUserPreferences = ({ domain, key }) => {
  const { id: userId, preferences } = useUser();
  return useUserPreferencesHook({
    useFilter: !!userId && !!domain && !!key,
    filters: { userId, domain, key },
    silent: true,
    staleTime: Infinity,
    select: (d) => d[0],
    initialData: preferences?.find((p) => p.domain === domain && p.key === key)
  });
};
export const useDbState = ({
  domain,
  key,
  defaultValue
}) => {
  const { id: userId } = useUser();
  const {
    data: preference,
    update,
    add
  } = useUserPreferences({
    domain,
    key
  });
  const [record, setRecord] = useState();
  useEffect(() => {
    if (!tempVal && defaultValue) {
      setTempVal(defaultValue);
    }
  }, [defaultValue]);
  useEffect(() => {
    if (preference && !tempVal) {
      setRecord(preference);
    }
  }, [preference]);
  useEffect(() => {
    if (record) {
      setTempVal(record.value);
    }
  }, [record]);
  const [tempVal, setTempVal] = useState();
  console.log("tempVal", tempVal, "record", record);
  return [
    tempVal,
    (v) => {
      if (record?.id) {
        update({ data: { domain, key, userId, value: v }, id: record?.id });
      } else {
        add({ domain, key, userId, value: v });
      }
      setTempVal(v);
    }
  ];
};
