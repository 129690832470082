import React from 'react';
import { ProcessedColumn } from './AdvanceTableProvider';
import BasicChart from 'components/app/reporting/charts/BasicChart';
import _, { uniq } from 'lodash';
import ChartLayout from 'components/app/reporting/charts/ChartLayout';

export default <T extends Record<any, any>>({
  column,
  data: _data,
  onClick
}: {
  column: ProcessedColumn<T>;
  data: T[];
  onClick?: (value: any, index: number) => void;
}) => {
  const type = 'pie';
  const series = uniq(
    _data
      .map((d, i) => column.accessorFn(d, i)?.toString())
      .filter(d => d !== null && d !== undefined && d !== '')
  );
  const data = series.map(
    s => _data.filter((d, i) => column.accessorFn(d, i) === s).length
  );
  return (
    <ChartLayout showHeadline={false} height={'100px'} simple>
      <BasicChart
        simple
        height={'100px'}
        type={type}
        onClick={params => {
          onClick?.(params.name, params.dataIndex);
        }}
        series={series.map((s, i) => ({
          name: s,
          data: [data[i]]
        }))}
        xAxes={
          [
            // {
            //   id: 'x',
            //   data: series
            // }
          ]
        }
        yAxes={[]}
      />
    </ChartLayout>
  );
};
