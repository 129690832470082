import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';
import { Section } from './helpers';

export default forwardRef<HTMLDivElement, { id?: string }>((props, ref) => {
  return (
    <Card className="mb-3" id={props.id} ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">General</h5>
      </Card.Header>
      <Card.Body>
        <Section title="Third parties">
          We contract with a number of third parties to assist us in providing
          our Site and services. For example, we may use third parties to
          develop our Site and to provide the servers on which our Site is
          hosted. Wherever third parties are involved in processing personal
          information on our behalf, we shall ensure they do so in accordance
          with UK Data Protection Law. Third parties are not allowed to use your
          personal information for their own purposes.
        </Section>

        <Section title="Business reorganisation">
          In addition to the above, we may transfer your personal information to
          a third party as part of a sale of some or all of our business and
          assets to any third party or as part of any business restructuring or
          reorganisation, in which event we will take steps to ensure that your
          privacy rights under this Privacy Policy continue to be protected.
        </Section>
        <Section title="Financial transactions">
          Under no circumstances will we hold payment details such as your card
          number, expiry date and security code on our servers. Where payments
          are handled through an accredited payment bureau, you should visit
          their website to view their privacy policy.
        </Section>

        <Section title="Links to other websites">
          This Privacy Policy only covers our Site. Any other websites which may
          be linked to by this Site will be subject to their own privacy policy,
          which may differ from ours and we are not responsible for the content
          provided on any third party websites.
        </Section>

        <Section title="Changes to this Privacy Policy">
          We keep our Privacy Policy under regular review. Updates to it shall
          become effective when published on our Site. This Privacy Policy was
          last updated on 11th December 2024
        </Section>

        <Section title="Complaints or queries">
          We will endeavour to provide additional information or explanation of
          this Privacy Policy if needed and any requests for this should be sent
          to the address set out in the “WHO WE ARE” section above.
        </Section>
      </Card.Body>
    </Card>
  );
});
