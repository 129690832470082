import useFormResponses from 'components/common/customForms/hooks.js/useFormResponses';
import React from 'react';
import useCampaign from '../campaigns/hooks/useCampaign';
import { addHours } from 'date-fns';
import RerunDomainEventError from 'components/common/RerunDomainEventError';
import useApplicants from './hooks/useApplicants';

export default ({ campaignId }) => {
  const { data } = useCampaign({
    id: campaignId,
    columns: [
      'formId',
      'formEmailQuestionId',
      'formNameQuestionId',
      'formTelQuestionId',
      'allowDuplicates',
      'allowRepeats',
      'allowRepeatsAfter'
    ]
  });
  const { data: responses } = useFormResponses({
    formId: data?.formId,
    enabled: !!data?.formId
  });
  const { data: applicants } = useApplicants({
    //get all applicants, because if they've somehow been reassigned to a different campaign, he database will not accept them as new applicants
    // filters: { campaignId },
    // useFilter: !!campaignId,
    columns: ['responseId', 'email', 'telephone']
  });
  const missingResponses =
    applicants &&
    responses
      ?.filter(
        //filter on this first because if there's an applicant somewhere from this form response, it's not missing
        r =>
          !applicants.some(a => Number(a.responseId) === Number(r.id)) &&
          //and there are not already applicants with that email or tel
          (!applicants.find(
            a =>
              a.email === r.data.form[data.formEmailQuestionId] ||
              a.telephone === r.data.form[data.formTelQuestionId]
          ) ||
            //or the campaign allows repeats
            (data.allowRepeats &&
              //amd the form response was submitted after the last time they applied + the allowance period
              (!data.allowRepeatsAfter ||
                new Date(r.submittedDate) >
                  new Date(
                    new Date(
                      applicants.find(
                        a =>
                          a.email === r.data.form[data.formEmailQuestionId] ||
                          a.telephone === r.data.form[data.formTelQuestionId]
                      ).appliedDate
                    ).valueOf() +
                      data.allowRepeatsAfter * 24 * 60 * 60 * 1000
                  ))))
      )
      .filter(
        r =>
          r.meta &&
          //if the form response was submitted for this campaign
          r.meta.campaignType === 'recruitment' &&
          Number(r.meta.campaignId) === Number(campaignId) &&
          //and they've had time to go through processing
          new Date(r.submittedDate) < addHours(new Date(), -1) &&
          //and they've given name, email and tel
          r.data.form[data.formTelQuestionId] &&
          r.data.form[data.formEmailQuestionId] &&
          r.data.form[data.formNameQuestionId] &&
          //and not screened out
          !r.screenoutQuestionIds?.length &&
          //and there are not already applicants with that email or tel
          (!applicants.find(
            a =>
              a.email === r.data.form[data.formEmailQuestionId] ||
              a.telephone === r.data.form[data.formTelQuestionId]
          ) ||
            //or the campaign allows duplicates
            data.allowDuplicates)
      );
  return (
    !!missingResponses?.length && (
      <RerunDomainEventError
        filters={{ id: missingResponses.map(r => r.id) }}
        domain="form-response"
        action="added"
      >
        It looks like {missingResponses.length} applicants might not be showing
        here.
      </RerunDomainEventError>
    )
  );
};
