import React, { ReactNode } from 'react';
import TooltipBadge from './TooltipBadge';
import classNames from 'classnames';
import { FiInfo } from 'react-icons/fi';
export default ({
  children,
  className,
  ...rest
}: { children: ReactNode; className?: string } & any) => {
  return (
    <TooltipBadge
      className={classNames('ms-1', className)}
      tooltip={children}
      {...rest}
    >
      <FiInfo />
    </TooltipBadge>
  );
};
