import DetailPage, {
  BottomBarAction,
  DetailPageProps
} from 'components/common/detail/DetailPage';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DefaultCrudData,
  defaultCrudHookBuilder,
  UseCrudProps
} from 'hooks/useDefaultCrud';
import { EventPrefix } from 'apis/flex/notifications';
import { domainToSentence } from './DomainTimeline';
import { domainConfigs } from 'components/notification/config';
import ActionListener from 'components/notification/ActionListener';
import DomainCalendar from './DomainCalendar';

export default <
  TData extends DefaultCrudData & {
    id?: number;
    name?: string;
    createdBy?: number;
    createdDate?: Date;
    modifiedDate?: Date;
    modifiedBy?: number;
  } = any,
  TMutated = any
>({
  domain,
  crudHook: passedCrudHook,
  children,
  crudProps,
  actions,
  avatar,
  background,
  ...rest
}: {
  children: React.ReactNode;
  domain: EventPrefix;
  crudHook?: ReturnType<typeof defaultCrudHookBuilder<TData>>;
  crudProps?: UseCrudProps<TData, TData>;
  actions?: BottomBarAction[] | ((data: TData) => BottomBarAction[]);
} & Partial<
  Omit<
    DetailPageProps<TData, TMutated>,
    'allData' | 'data' | 'baseUrl' | 'actions'
  >
>) => {
  const d = useParams();
  const config = domainConfigs[domain];
  const crudHook = passedCrudHook || config.crudHook<TData>;
  const rawId = d.id || d[config.foreignKey];
  const id = rawId && !isNaN(Number(rawId)) ? Number(rawId) : undefined;
  const { data, isLoading, upsert, isUpserting } = crudHook({
    id,
    select: d => d?.[0],
    noReturnOnChange: false,
    ...crudProps
  });
  const nav = useNavigate();
  const itemName = data
    ? config.format?.(data)?.label ||
      data?.name ||
      `${domainToSentence(domain)} #${id}`
    : null;
  return (
    <DetailPage
      title={
        data
          ? config.format?.(data)?.label ||
            data?.name ||
            'Editing ' + domainToSentence(domain)
          : 'New ' + domainToSentence(domain)
      }
      data={data}
      isLoading={isLoading}
      onSave={upsert}
      isSaving={isUpserting}
      afterSave={() => {
        const targetSplit = location.pathname.split('/');
        targetSplit.pop();
        nav(targetSplit.join('/'));
      }}
      domain={domain}
      itemId={id}
      createdBy={data?.modifiedBy || data?.createdBy}
      createdDate={data?.modifiedDate || data?.createdDate}
      actions={typeof actions === 'function' ? actions(data) : actions}
      authItemCollection={domain}
      authItemId={id}
      background={
        background && {
          authItemCollection: domain,
          authItemId: id,
          ...background
        }
      }
      avatar={
        avatar && {
          authItemCollection: domain,
          authItemId: id,
          ...avatar
        }
      }
      {...rest}
    >
      <ActionListener eventName={`${domain}-added`} actioned itemId={id} />
      {children}
      <DomainCalendar title={itemName} domain={domain} itemId={id} />
    </DetailPage>
  );
};
