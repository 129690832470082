import { debounce, isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

export default <T,>({
  data: inputData,
  ms = 1000,
  children
}: {
  data: T;
  ms?: number;
  children: (d: T) => React.ReactNode;
}) => {
  const { data } = useDebounce({ ms, data: inputData });
  return useMemo(() => children(data), [data]);
};
export const useDebounce = <T,>({ ms, data }: { ms?: number; data: T }) => {
  const [newData, setNewData] = useState<string>();
  const [isPending, setIsPending] = useState(false);
  const updateData = useCallback(
    debounce(data => {
      // console.log('running debounced fn');
      setIsPending(false);
      setNewData(data);
    }, ms),
    [ms]
  );
  const handleDataChange = d => {
    const stringifiedData =
      d === null || d === undefined ? undefined : JSON.stringify(d);
    // console.log('data changed', stringifiedData, newData);
    if (!isEqual(stringifiedData, newData)) {
      setIsPending(true);
      // console.log(
      //   'cancelling debounce in favour of ',
      //   stringifiedData,
      //   updateData
      // );
      updateData.cancel();
      // console.log('starting new debounce with ', stringifiedData);
      updateData(stringifiedData);
    }
  };
  useEffect(() => {
    handleDataChange(data);
  }, [data]);
  return {
    isPending,
    setData: handleDataChange,
    data: useMemo<T>(
      () => (newData === undefined ? undefined : JSON.parse(newData)),
      [newData]
    )
  };
};
