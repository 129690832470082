import DetailCard from 'components/common/detail/DetailCard';
import DomainDetail from 'components/common/DomainDetail';
import WizardInput from 'components/wizard/WizardInput';
import React from 'react';
import { weekdays } from 'helpers/dates';
import { FixedSchedule } from 'apis/flex/hr';
import { Col, Row } from 'react-bootstrap';
import { DayShiftsPicker } from '../ShiftsPicker';

export default () => {
  return (
    <DomainDetail<
      FixedSchedule,
      Omit<FixedSchedule, 'shifts'> & { shifts: number[][] }
    >
      domain="fixed-schedule"
      loadMutator={d => ({
        ...d,
        shifts: weekdays.map((w, i) =>
          d.shifts.filter(s => s.weekday == i).map(s => s.shiftId)
        )
      })}
      saveMutator={d => {
        return {
          ...d,
          shifts: d.shifts.flatMap((wdShifts, weekday) =>
            (wdShifts || []).map(shiftId => ({
              shiftId,
              weekday,
              fixedScheduleId: d.id,
              id: undefined
            }))
          )
        };
      }}
    >
      <DetailCard title="Info" id="info">
        <WizardInput name="name" />
        <Row>
          {weekdays.map((w, i) => (
            <Col key={i} xs={12} md={4} xl={3}>
              <DayShiftsPicker
                name={`shifts.${i}`}
                label={w}
                registerProps={{ required: false }}
              />
            </Col>
          ))}
        </Row>
      </DetailCard>
    </DomainDetail>
  );
};
