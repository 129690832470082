import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { Card, Col, Modal, Row } from 'react-bootstrap';
import Background from './Background';
import corner4 from 'assets/img/illustrations/corner-4.png';
import createMarkup from 'helpers/createMarkup';
import Skeleton from 'react-loading-skeleton';
import { Tag } from './detail/DetailPage';
import SoftBadge from './SoftBadge';
import AuthorStamp from './AuthorStamp';
import FalconLink from './FalconLink';
import IconButton from './IconButton';
import {
  faHistory,
  faLock,
  faShareSquare
} from '@fortawesome/free-solid-svg-icons';
import { ResponsiveModal } from './Modals';
import DomainTimeline, { domainToSentence } from './DomainTimeline';
import { EventPrefix } from 'apis/flex/notifications';
import { domainConfigs } from 'components/notification/config';
import DomainItemAccess from './DomainItemAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const TagItem = ({ tag }) => {
  const text = typeof tag === 'string' ? tag : tag?.text;
  const bg = typeof tag === 'string' ? 'info' : tag?.bg;
  return (
    <SoftBadge bg={bg} pill>
      {text}
    </SoftBadge>
  );
};
const AccessButton = ({ domain, itemId, className, ...rest }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <IconButton
        size="sm"
        variant="link"
        className={classNames(className, 'p-0')}
        iconSize={'1x'}
        icon={faLock}
        {...rest}
        onClick={() => setShow(true)}
      >
        Access
      </IconButton>
      <ResponsiveModal
        wide
        closeButton
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Body>
          {show && <DomainItemAccess domain={domain} itemId={itemId} />}
        </Modal.Body>
      </ResponsiveModal>
    </>
  );
};
const HistoryButton = ({ domain, itemId, className, ...rest }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <IconButton
        size="sm"
        variant="link"
        className={classNames(className, 'p-0')}
        iconSize={'1x'}
        icon={faHistory}
        {...rest}
        onClick={() => setShow(true)}
      >
        History
      </IconButton>
      <ResponsiveModal
        wide
        closeButton
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Body>
          {show && <DomainTimeline domain={domain} itemId={itemId} />}
        </Modal.Body>
      </ResponsiveModal>
    </>
  );
};
const PageHeader = ({
  title,
  preTitle,
  titleTag: TitleTag,
  description,
  image,
  col,
  children,
  isLoading,
  tags,
  createdBy,
  createdDate,
  domain,
  link,
  itemId,
  ...rest
}: {
  title: string;
  preTitle?: string;
  titleTag?: (...args: any) => ReactNode;
  description?: React.ReactNode;
  image?: string;
  col?: any;
  children?: ReactNode;
  isLoading?: boolean;
  tags?: Tag[];
  createdBy?: number;
  createdDate?: Date;
  domain?: EventPrefix;
  link?: {
    text: ReactNode;
    to: string;
  };
  itemId?: number;
  [key: string]: any;
}) => {
  return (
    <Card {...rest}>
      <Background
        image={image}
        className="bg-card d-none d-sm-block"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <Card.Body className="position-relative">
        <Row className="justify-content-between g-0 gy-3">
          <Col {...col} xs={12}>
            {preTitle && <h6 className="text-600">{preTitle}</h6>}

            {domain && (
              <div className="fw-normal h6 mb-3 text-black-50 w-100">
                {/* <DomainIcon size="sm" className={'me-2 border-0 p-0'} domain={domain} /> */}
                <div className="d-inline-block fs-0 me-1">
                  {domainConfigs[domain]?.icon}
                </div>
                <div
                  className="d-inline-block fs-0"
                  style={{ verticalAlign: 'text-bottom' }}
                >
                  {domainToSentence(domain)}
                </div>

                {itemId && (
                  <div className="float-end">
                    <HistoryButton
                      className="ms-2"
                      domain={domain}
                      itemId={itemId}
                    />
                    <AccessButton
                      className="ms-2"
                      domain={domain}
                      itemId={itemId}
                    />
                  </div>
                )}
              </div>
            )}
            <TitleTag className="mb-0 d-flex align-items-center justify-content-start">
              {isLoading ? <Skeleton width={200} /> : title}
            </TitleTag>
            {tags && (
              <div className="mt-2 d-flex gap-2">
                {tags.map((tag, index) => (
                  <TagItem key={index} tag={tag} />
                ))}
              </div>
            )}
            {isLoading ? (
              <p>
                <Skeleton className="mt-2" count={3} />
              </p>
            ) : (
              description && (
                <div className={classNames('mt-2', { 'mb-0': !children })}>
                  {typeof description === 'string' ? (
                    <div dangerouslySetInnerHTML={createMarkup(description)} />
                  ) : (
                    description
                  )}
                </div>
              )
            )}
            {!!(createdDate && createdBy) && (
              <>
                <hr />
                <div>
                  <AuthorStamp
                    showIcon={false}
                    date={createdDate}
                    userId={createdBy}
                  />
                </div>
              </>
            )}
          </Col>
          {(children || link) && (
            <Col
              xs={12}
              className="mt-0 justify-content-end flex-grow-1 d-flex align-items-center"
            >
              {children}
              {link && <FalconLink title={link.text} to={link.to} />}
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

PageHeader.defaultProps = { col: {}, image: corner4, titleTag: 'h3' };

export default PageHeader;
