import { screeningApi } from "apis/flex/hr";
import useDefaultCrud from "hooks/useDefaultCrud";
import { useUser } from "hooks/useUser";
export default (props) => {
  const user = useUser();
  return useDefaultCrud("Prescreenings", screeningApi, {
    ...props,
    useFilter: !!user?.employeeId,
    filters: { employeeId: user?.employeeId }
  });
};
