import { ShiftAvailabilityRule } from 'apis/flex/hr';
import ContractPicker from 'components/app/documents/contracts/ContractPicker';
import { FieldArrayList } from 'components/common/customForms/Editor/CustomFormQuestionEditor';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { DepartmentSelector } from '../departments/SelectedDepartment';
import { EmployeeSelector } from '../staff/widgets/EmployeeSelector';
import TrainingCoursePicker from '../training/widgets/TrainingCoursePicker';
import { weekdays } from 'helpers/dates';
import { uniq, uniqBy } from 'lodash';
import SettingsBox from 'components/common/SettingsBox';
import Expandable from 'components/common/Expandable';
export type FormAvailabilityRule = {
  weekday: string[];
  contractId: number[];
  departmentId: number[];
  employeeId: number[];
  trainingId: number[];
  dateRange?: [Date, Date];
};
export const convertShiftRulesToForm = (
  rules: ShiftAvailabilityRule[]
): FormAvailabilityRule[] => {
  const indices = uniq(rules?.map(r => r.ruleIndex));
  return indices?.map(ruleIndex => {
    const ruleGroup = rules.filter(r => r.ruleIndex === ruleIndex);
    const getValues = (name: string & keyof FormAvailabilityRule) =>
      uniq(
        ruleGroup?.map(r => r[name]).filter(d => d !== null && d !== undefined)
      );

    return {
      weekday: getValues('weekday')?.map(w => weekdays[w]),
      contractId: getValues('contractId'),
      departmentId: getValues('departmentId'),
      employeeId: getValues('employeeId'),
      trainingId: getValues('trainingId'),
      dateRange: ruleGroup?.[0]?.startDate
        ? [
            new Date(ruleGroup?.[0]?.startDate),
            new Date(ruleGroup?.[0]?.endDate)
          ]
        : undefined
    };
  });
};
function generateCombinations(
  shiftId: number,
  rule: FormAvailabilityRule,
  ruleIndex: number
): ShiftAvailabilityRule[] {
  const combinations: ShiftAvailabilityRule[] = [];
  (rule.employeeId || [null]).forEach(employeeId => {
    (rule.departmentId || [null]).forEach(departmentId => {
      (rule.weekday || [null]).forEach(weekday => {
        (rule.contractId || [null]).forEach(contractId => {
          (rule.trainingId || [null]).forEach(trainingId => {
            combinations.push({
              id: undefined,
              ruleIndex,
              employeeId,
              shiftId,
              departmentId,
              weekday: weekday && weekdays.indexOf(weekday),
              contractId,
              trainingId,
              startDate: rule.dateRange?.[0],
              endDate: rule.dateRange?.[1]
            });
          });
        });
      });
    });
  });

  return uniqBy(combinations, JSON.stringify);
}
export const convertShiftRulesFromForm = (
  shiftId: number,
  rules: FormAvailabilityRule[]
): ShiftAvailabilityRule[] => {
  return rules.flatMap((rule, i) => generateCombinations(shiftId, rule, i));
};
export const AvailabilityRulesForm = ({
  name = 'availabilityRules'
}: WizardInputProps) => {
  const { fields, append, remove } = useFieldArray<
    Record<string, ShiftAvailabilityRule[]>,
    string
  >({ name });
  const { getValues } = useFormContext();
  console.log('all vals', useWatch(), useWatch({ name }));
  return (
    <FieldArrayList
      fields={fields}
      append={val => append(val)}
      remove={remove}
      defaultValues={{}}
      divider="OR"
      item={(field, i) => {
        const vals = getValues(`${name}.${i}`);
        const activeFields = Object.keys(vals).filter(
          d =>
            d !== 'weekday' &&
            !!vals[d] &&
            (!Array.isArray(vals[d]) || !!vals[d]?.length)
        );
        return (
          <SettingsBox
            title={`Rule ${i + 1}`}
            description="Will permit shift if any of these conditions are met"
          >
            <WizardInput
              type="select"
              name={`${name}.${i}.weekday`}
              multiple
              label="Weekdays"
              options={weekdays}
            />
            <Expandable activeCount={activeFields.length}>
              <WizardInput
                type="daterange"
                label="Date Range"
                registerProps={{ required: false }}
                name={`${name}.${i}.dateRange`}
              />
              <ContractPicker
                label="Contract Types"
                multiple
                registerProps={{ required: false }}
                name={`${name}.${i}.contractId`}
              />
              <DepartmentSelector
                label="Departments"
                multiple
                name={`${name}.${i}.departmentId`}
                registerProps={{ required: false }}
              />
              <EmployeeSelector
                label="Employees"
                multiple
                name={`${name}.${i}.employeeId`}
                registerProps={{ required: false }}
              />
              <TrainingCoursePicker
                label="Passed Training"
                multiple
                registerProps={{ required: false }}
                name={`${name}.${i}.trainingId`}
              />
            </Expandable>
          </SettingsBox>
        );
      }}
    />
  );
};
