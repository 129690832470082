import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Bottombar from './Bottombar';
import useVisibilityObserver from 'hooks/useVisibilityObserver';
import WizardInput from 'components/wizard/WizardInput';
import LoadingButton from 'components/common/LoadingButton';
import PropTypes from 'prop-types';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useFormContext, useWatch } from 'react-hook-form';
import is from 'is_js';
import CustomTooltip from 'components/common/Tooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const PublishStatInput = ({ setIsEditing, input, name, ...rest }) => {
  const inputs = Array.isArray(input) ? input : [input];
  const watchVals = useWatch();
  const { trigger } = useFormContext();
  const handleDone = () => {
    trigger([name, ...inputs.map(i => i.name)]).then(allow => {
      if (allow) {
        setIsEditing(false);
      }
    });
  };
  return (
    <Row className="mb-3 mt-2 px-2 ms-2">
      {inputs.map((input, i) => (
        <WizardInput
          key={i}
          name={name}
          hideLabel
          {...rest}
          {...input}
          disabled={
            is.function(input.disabled)
              ? input.disabled(watchVals)
              : input.disabled
          }
          formGroupProps={{
            as: Col,
            lg: i === 0 ? 'auto' : Math.round(10 / inputs.length),
            ...input.formGroupProps,
            className: 'px-1',
            noMb: true
          }}
        />
      ))}
      <Button
        title="Done editing"
        size="sm"
        // as={Col}
        // lg={2}
        onClick={handleDone}
      >
        <FontAwesomeIcon icon={faCheck} />
      </Button>
    </Row>
  );
};
export const publishStatsSettings = [
  {
    title: 'Condition',
    name: 'isActive',
    formatter: (v, d) => (d?.isActive ? 'Published' : 'Draft'),
    icon: 'file',
    input: {
      type: 'select',
      pluginProps: { size: 'sm' },
      formControlProps: { className: 'form-control-sm' },
      options: [
        { value: false, label: 'Draft' },
        { value: true, label: 'Published' }
      ],
      registerProps: {
        validate: v => v || v === false || 'Required',
        required: false
      }
    }
  },
  {
    title: 'Duplicate applicants',
    name: 'allowDuplicates',
    formatter: (v, d) => (d?.allowDuplicates ? 'Allow' : 'Reject'),
    info: (
      <>
        Allow or reject applicants automatically if they've already applied to{' '}
        <b>this campaign</b> previously, based on the email address they enter.
      </>
    ),
    icon: 'copy',
    input: {
      type: 'select',
      pluginProps: { size: 'sm' },
      options: [
        { value: false, label: 'Reject' },
        { value: true, label: 'Allow' }
      ],
      registerProps: {
        validate: v => v || v === false || 'Required',
        required: false
      }
    }
  },
  {
    title: 'Repeat applicants',
    name: 'allowRepeats',
    formatter: (v, d) =>
      d?.allowRepeats
        ? 'Allow after ' + d?.allowRepeatsAfter + ' days'
        : 'Reject',
    icon: faCopy,
    info: (
      <>
        Allow or reject applicants automatically if they've applied to{' '}
        <b>any campaign</b> within the last X days, based on the email address
        they enter.
      </>
    ),
    input: [
      {
        type: 'select',
        options: [
          { value: false, label: 'Reject' },
          { value: true, label: 'Allow after X days' }
        ],
        formGroupProps: { as: Col, lg: 6 },
        pluginProps: { size: 'sm' },
        registerProps: {
          validate: v => v || v === false || 'Required',
          required: false
        }
      },
      {
        type: 'number',
        name: 'allowRepeatsAfter',
        placeholder: 'X (days)',
        formControlProps: { className: 'form-control-sm' },
        formGroupProps: { as: Col, lg: 4 },
        disabled: d => !d?.allowRepeats
      }
    ]
  }
];
export const CampaignSidebar = () => {
  const { getValues } = useFormContext();
  const vals = getValues();
  return (
    <ul className="list-unstyled fs--1 mb-0">
      {publishStatsSettings.map((setting, index) => (
        <CampaignSidebarItem
          setting={setting}
          index={index}
          key={setting.name}
          vals={vals}
        />
      ))}
    </ul>
  );
};
const CampaignSidebarItem = ({ setting, index, vals }) => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <CustomTooltip key={setting.name} content={setting.info}>
      <li
        key={setting.name}
        className={`hover-actions-trigger ${
          publishStatsSettings.length - 1 !== index && 'mb-2'
        }`}
      >
        <FontAwesomeIcon icon={setting.icon} className="me-2" />
        <span className="ms-1">{setting.title}:</span>
        <span className="text-800 fw-medium me-2">
          {' '}
          {setting.formatter(vals[setting.name], vals)}
        </span>
        {isEditing ? (
          <PublishStatInput
            setIsEditing={setIsEditing}
            inputs={setting.input}
            {...setting}
          />
        ) : (
          <Button
            variant="link"
            size="sm"
            className="p-0 lh-sm fs--2"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        )}
      </li>
    </CustomTooltip>
  );
};
CampaignSidebarItem.propTypes = {
  setting: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  index: PropTypes.number.isRequired,
  vals: PropTypes.object.isRequired
};
const PublishCampaign = ({ isLoading, isEdit, onSave, getValues }) => {
  const targetElRef = useRef();
  const { isVisible: inViewport, observer } = useVisibilityObserver(
    targetElRef,
    '0px'
  );

  useEffect(() => {
    return () => {
      observer &&
        targetElRef.current &&
        observer.unobserve(targetElRef.current);
    };
  }, [observer]);
  const vals = getValues();
  return (
    <>
      <Card className="mb-lg-3 order-lg-0 order-1">
        <Card.Header className="py-2 d-flex flex-between-center">
          <h5 className="mb-0">
            {isEdit ? 'Update' : 'Publish'} your Campaign
          </h5>
          <Button
            variant="link"
            size="sm"
            className="px-0 fw-medium text-secondary "
          >
            <span className="d-lg-none d-xxl-inline-block">Preview</span>
            <FontAwesomeIcon icon="external-link-alt" className="ms-2 fs--2" />
          </Button>
        </Card.Header>
        <Card.Body className="bg-light">
          <ul className="list-unstyled fs--1 mb-0">
            {publishStatsSettings.map((setting, index) => {
              const [isEditing, setIsEditing] = useState(false);
              return (
                <CustomTooltip key={setting.name} content={setting.info}>
                  <li
                    key={setting.name}
                    className={`hover-actions-trigger ${
                      publishStatsSettings.length - 1 !== index && 'mb-2'
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={setting.icon as IconProp}
                      className="me-2"
                    />
                    <span className="ms-1">{setting.title}:</span>
                    <span className="text-800 fw-medium me-2">
                      {' '}
                      {setting.formatter(vals[setting.name], vals)}
                    </span>
                    {isEditing ? (
                      <PublishStatInput
                        setIsEditing={setIsEditing}
                        inputs={setting.input}
                        {...setting}
                      />
                    ) : (
                      <Button
                        variant="link"
                        size="sm"
                        className="p-0 lh-sm"
                        onClick={() => setIsEditing(true)}
                      >
                        Edit
                      </Button>
                    )}
                  </li>
                </CustomTooltip>
              );
            })}
          </ul>
        </Card.Body>
        <Card.Footer ref={targetElRef} className="py-2">
          <Row className="flex-between-center g-0">
            {!isEdit && (
              <Col xs="auto">
                <LoadingButton
                  variant="link"
                  size="sm"
                  className="fw-medium text-secondary p-0"
                  onClick={() => onSave(true)}
                  loading={isLoading}
                >
                  Save as Draft
                </LoadingButton>
              </Col>
            )}
            <Col xs="auto">
              <LoadingButton
                // size="md"
                variant="primary"
                className="px-xxl-5 px-4"
                data-tour="submit-campaign"
                onClick={onSave}
                loading={isLoading}
              >
                {isEdit ? 'Update' : 'Publish'}
              </LoadingButton>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
      <Bottombar inViewport={inViewport} />
    </>
  );
};
PublishCampaign.propTypes = {
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
  getValues: PropTypes.func
};
export default PublishCampaign;
