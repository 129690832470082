import React, { ReactNode } from 'react';

// Lazy-loaded imports
const MainLayout = React.lazy(() => import('../layouts/MainLayout'));
// const Recruitment = React.lazy(() => import('components/app/recruitment'));
const Error404 = React.lazy(() => import('components/errors/Error404'));
const Quotes = React.lazy(() => import('components/app/pm/quotes'));
const Error500 = React.lazy(() => import('components/errors/Error500'));

const ReturnToProvider = React.lazy(
  () => import('components/return-to/ReturnToProvider')
);
const ReturnToList = React.lazy(
  () => import('components/return-to/ReturnToProvider')
);
const QuickLinks = React.lazy(() => import('components/common/QuickLinks'));
const Stages = React.lazy(
  () => import('components/app/recruitment/stages/Stages')
);
const PreviewPopout = React.lazy(
  () => import('components/common/customForms/Editor/CustomFormPreview')
);
const Tasks = React.lazy(
  () => import('components/app/recruitment/stages/Tasks')
);
const Landing = React.lazy(() => import('components/app/landing/Landing'));
const CustomFormResponses = React.lazy(
  () => import('components/common/customForms/Reporting/CustomFormResponses')
);
const Logout = React.lazy(
  () => import('components/authentication/card/Logout')
);
const ErrorLayout = React.lazy(() => import('layouts/ErrorLayout'));
const Error401 = React.lazy(() => import('components/errors/Error401'));
const Error403 = React.lazy(() => import('components/errors/Error403'));
const ViewUser = React.lazy(() => import('components/app/users/detail'));
const CustomFormPage = React.lazy(
  () => import('components/common/customForms/CustomFormPage')
);
const CreateCampaign = React.lazy(
  () => import('components/app/recruitment/campaigns/create')
);
const EditCampaign = React.lazy(
  () => import('components/app/recruitment/campaigns/edit')
);
// const Applicants = React.lazy(
//   () => import('components/app/recruitment/applicants')
// );
const NotificationRedirect = React.lazy(
  () => import('components/notification/NotificationRedirect')
);
const MyProfile = React.lazy(() => import('components/app/users/MyProfile'));
const GuidedTourProvider = React.lazy(
  () => import('components/tours/TourProvider')
);
const Contracts = React.lazy(
  () => import('components/app/documents/contracts/Contracts')
);
// const Courses = React.lazy(() => import('components/app/hr/training/courses'));
// const EditCourse = React.lazy(
//   () => import('components/app/hr/training/courses/detail/EditCourse')
// );
// const TrainingClientPage = React.lazy(
//   () => import('components/app/hr/training/client/TrainingClientPage')
// );
const TrainingAttempts = React.lazy(
  () => import('components/app/hr/training/courses/TrainingAttempts')
);
// const MyTraining = React.lazy(
//   () => import('components/app/hr/training/courses/MyTraining')
// );
const Editor = React.lazy(() => import('components/common/customForms/Editor'));
const TaskEditor = React.lazy(
  () => import('components/app/recruitment/tasks/TaskEditor')
);
const ReviewAttempt = React.lazy(
  () => import('components/app/hr/training/courses/ReviewAttempt')
);
const PrescreeningTable = React.lazy(
  () => import('components/app/hr/prescreening/PrescreeningTable')
);
const PrescreeningDetail = React.lazy(
  () => import('components/app/hr/prescreening/PrescreeningDetail')
);
const CustomForms = React.lazy(() => import('components/common/customForms'));
const Departments = React.lazy(() => import('components/app/hr/departments'));
const DepartmentDetail = React.lazy(
  () => import('components/app/hr/departments/DepartmentDetail')
);
const JobRoles = React.lazy(() => import('components/app/hr/jobRoles'));
const JobRoleDetail = React.lazy(
  () => import('components/app/hr/jobRoles/JobRoleDetail')
);
const DomainRedirect = React.lazy(
  () => import('components/common/DomainRedirect')
);
const Support = React.lazy(() => import('components/app/support'));
const TicketDetail = React.lazy(
  () => import('components/app/support/TicketDetail')
);
const Mailer = React.lazy(() => import('components/mailer'));
const TemplateDetail = React.lazy(
  () => import('components/mailer/templates/TemplateDetail')
);
const ShortLinkRedirect = React.lazy(
  () => import('components/extra/ShortLinkRedirect')
);

// Non-lazy imports
import * as Sentry from '@sentry/react';
import { RolesAuthRoute } from './RolesAuthRoute';
import PrivateProvider from './PrivateProvider';
import { ModalProvider } from 'hooks/useModal';
import { settings } from '../config';
import companyConfig from 'companyConfig';
import {
  FiBell,
  FiBookOpen,
  FiCalendar,
  FiCheckSquare,
  FiClock,
  FiEdit,
  FiFilePlus,
  FiFileText,
  FiFolder,
  FiHelpCircle,
  FiHome,
  FiKey,
  FiList,
  FiLock,
  FiPlusCircle,
  FiSettings,
  FiSpeaker,
  FiUser,
  FiUserCheck,
  FiUserPlus,
  FiUsers
} from 'react-icons/fi';
import PipelineFormTester from 'components/app/recruitment/campaigns/create/PipelineFormTester';
import Recruitment from 'components/app/recruitment';
import Applicants from 'components/app/recruitment/applicants';
import Faq from 'components/documentation/Faq';
import ComingSoon from 'components/extra/ComingSoon';
import OneIDRedirect from 'components/app/hr/prescreening/OneIDRedirect';
import { RoleDomain } from 'apis/flex/users';
import Storage from 'components/app/settings/storage';
import MyTraining from 'components/app/hr/training/courses/MyTraining';
import TrainingClientPage from 'components/app/hr/training/client/TrainingClientPage';
import Courses from 'components/app/hr/training/courses';
import EditCourse from 'components/app/hr/training/courses/detail/EditCourse';
import Unsubscribe from 'components/notification/Unsubscribe';
import Clients from 'components/app/pm/clients';
import ClientDetail from 'components/app/pm/clients/ClientDetail';
import ResourceGroups from 'components/app/pm/projects/resourceGroups';
import ResourceGroupDetail from 'components/app/pm/projects/resourceGroups/ResourceGroupDetail';
import Shifts from 'components/app/hr/shifts';
import ShiftDetail from 'components/app/hr/shifts/ShiftDetail';
import AccessGroups from 'components/app/settings/accessGroups';
import AccessGroupDetail from 'components/app/settings/accessGroups/AccessGroupDetail';
import Projects from 'components/app/pm/projects';
import ProjectDetail from 'components/app/pm/projects/ProjectDetail';
import TargetGroupsTable from 'components/app/pm/projects/targetGroups/TargetGroupsTable';
import TargetGroupDetail from 'components/app/pm/projects/targetGroups/TargetGroupDetail';
import QuestionnairesTable from 'components/app/pm/projects/questionnaires/QuestionnairesTable';
import QuestionnaireDetail from 'components/app/pm/projects/questionnaires/QuestionnaireDetail';
import ApplicantDetail from 'components/app/recruitment/applicants/detail/ApplicantDetail';
import Employees from 'components/app/hr/staff';
import TargetGroups from 'components/app/pm/projects/targetGroups';
import ContractDetail from 'components/app/documents/contracts/detail/ContractDetail';
import Calendars from 'components/app/calendars';
import CalendarDetail from 'components/app/calendars/CalendarDetail';
import AllNotifications from 'components/app/landing/AllNotifications';
import FixedSchedules from 'components/app/hr/shifts/fixedSchedules';
import FixedSchedulesDetail from 'components/app/hr/shifts/fixedSchedules/FixedSchedulesDetail';
import MyCalendar from 'components/app/calendars/MyCalendar';
import MyScreening from 'components/app/hr/prescreening/MyScreening';
import PrivacyPolicy from 'components/pages/privacy-policy/PrivacyPolicy';
import TandC from 'components/pages/tandc/TandC';
import PublicLayout from 'layouts/PublicLayout';

export const coreRoutes = [
  // {
  //   path: 'apply',
  //   element: <ApplicationForm />
  // },
  {
    path: 's/:hash',
    element: <ShortLinkRedirect />
  },
  {
    path: 'oneid-rtw-redirect',
    element: <OneIDRedirect />
  },
  {
    path: 'unsubscribe',
    element: <Unsubscribe />
  },
  {
    path: 'privacy',
    element: (
      <PublicLayout>
        <PrivacyPolicy />
      </PublicLayout>
    )
  },
  {
    path: 'terms',
    element: (
      <PublicLayout>
        <TandC />
      </PublicLayout>
    )
  },
  {
    path: 'previewer',
    children: [
      {
        path: 'form',
        element: <PreviewPopout />
      },
      {
        path: 'form-pipeline/:formId',
        element: <PipelineFormTester />
      }
    ]
  },
  {
    path: 'respond',
    children: [
      {
        index: true,
        element: <CustomFormPage />
      },
      {
        path: ':id',
        element: <CustomFormPage />
      }
    ]
  },
  {
    path: 'logout',
    element: <Logout />
  },
  {
    path: '/',
    fullpath: '/',
    name: 'Home',
    element: (
      <PrivateProvider>
        <ReturnToProvider>
          <GuidedTourProvider>
            <ModalProvider>
              <MainLayout />
            </ModalProvider>
            <ReturnToList />
          </GuidedTourProvider>
        </ReturnToProvider>
      </PrivateProvider>
    ),
    children: [
      {
        index: true,
        element: <Landing />
      },
      {
        path: 'notifications/:id/:ids',
        element: <NotificationRedirect />
      },
      {
        path: 'domain/:domain/:id',
        element: <DomainRedirect />
      },
      {
        path: 'my',
        children: [
          {
            name: 'Profile',
            path: 'profile',
            description: 'View your user profile',
            icon: <FiUser />,
            element: <MyProfile />
          },
          {
            name: 'Notifications',
            path: 'notifications',
            description: 'View all your notifications',
            icon: <FiBell />,
            element: <AllNotifications />
          },
          {
            name: 'Schedule',
            path: 'schedule',
            description: 'View and manage your calendar events and schedule',
            icon: <FiCalendar />,
            element: <MyCalendar />
          },
          {
            name: 'Screening',
            path: 'screening',
            description: 'Ypou current screening requests',
            icon: <FiUserCheck />,
            element: <MyScreening />
          }
        ]
      },
      {
        path: 'forms',
        name: 'Forms',
        roles: ['form'],
        description: 'Create and manage all forms used across the company',
        icon: <FiCheckSquare />,
        menu: false,
        children: [
          {
            index: true,
            element: <CustomForms />
          },
          {
            path: 'new',
            name: 'Create a form',
            icon: <FiPlusCircle />,
            roles: ['form.create'],
            element: <Editor />
          },
          {
            path: ':id/responses',
            name: 'Responses',
            // roles: ['Forms'],
            element: <CustomFormResponses />
          },
          {
            path: ':id',
            name: 'Edit',
            roles: ['form.edit'],
            element: <Editor />
          }
        ]
      },
      {
        name: 'HR',
        icon: <FiUsers />,
        path: 'hr',
        description:
          'Recruitment, training, documentation, employee shift patterns and more..',
        children: [
          {
            index: true,
            element: <></>
          },
          {
            name: 'Contracts',
            path: 'contracts',
            roles: ['contract'],
            icon: <FiFileText />,
            description:
              'Manage new and current staff contracts, including pay rates, working hours, signatures and more..',
            children: [
              {
                index: true,
                element: <Contracts />
              },
              {
                name: 'New',
                path: 'new',
                icon: <FiFilePlus />,
                description: 'Create a new contract',
                roles: ['contract.create'],
                element: <ContractDetail />
              },
              {
                name: 'View',
                path: ':contractId',
                // description: 'View',
                roles: ['contract'],
                element: <ContractDetail />
              }
            ]
          },
          {
            name: 'Training',
            path: 'training',
            description:
              'Train new skills, review HR and policy documents, administrate training courses and more..',
            children: [
              {
                index: true,
                element: <></>
              },
              {
                name: 'My training',
                path: 'me',
                description:
                  'View and complete training courses you are enrolled in, and enroll in new training courses',
                children: [
                  {
                    index: true,
                    element: <MyTraining />
                  },
                  {
                    name: 'Take course',
                    path: ':id',
                    element: <TrainingClientPage />
                  }
                ]
              },
              {
                name: 'Courses',
                description: 'Manage new and current training courses',
                path: 'courses',
                roles: ['training-course'],
                icon: <FiBookOpen />,
                children: [
                  {
                    index: true,
                    element: <Courses />
                  },
                  {
                    name: 'New',
                    path: 'new',
                    icon: <FiBookOpen />,
                    roles: ['training-course.create'],
                    description: 'Create a new training course',
                    element: <EditCourse />
                  },
                  {
                    name: 'Edit',
                    path: ':id',
                    roles: ['training-course.edit'],
                    element: <EditCourse />
                  },
                  {
                    name: 'Sessions',
                    path: 'sessions',
                    description:
                      "Review trainees' training sessions across all courses",
                    icon: <FiClock />,
                    children: [
                      {
                        index: true,
                        element: <TrainingAttempts />
                      },
                      {
                        name: 'Review',
                        path: ':id',
                        description:
                          "Review a user's training session, approve or fail their submissions",
                        element: <ReviewAttempt />
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            name: 'Recruitment',
            icon: <FiUserPlus />,
            path: 'recruitment',
            roles: ['recruitment-campaign'],
            fullpath: '/recruitment',
            description:
              'Review new applications, set new campaigns running, administrate academies and more..',
            // element:<div></div>,
            children: [
              {
                index: true,
                name: 'Campaigns',
                exact: true,
                element: <Recruitment />
              },
              {
                path: ':campaignId',
                name: 'View',
                children: [
                  { index: true, element: <Applicants /> },
                  {
                    path: 'applicants/:applicantId',
                    name: 'View applicant',
                    element: <ApplicantDetail />
                  }
                ]
              }
              // {
              //   // name: 'Campaigns',
              //   path: 'campaigns',
              //   icon: <FiSpeaker />,
              //   children: [
              //     {
              //       name: 'Create campaign',
              //       icon: <FiPlusCircle />,
              //       roles: ['recruitment-campaign.create'],
              //       path: 'new',
              //       element: <CreateCampaign />
              //     },
              //     {
              //       name: 'View campaign',
              //       icon: <FiPlusCircle />,
              //       path: ':campaignId',
              //       children: [
              //         {
              //           name: 'Stages',
              //           exact: true,
              //           path: 'stages',
              //           icon: <FiList />,
              //           description:
              //             'Manage the stages applicants can go through during this campaign',
              //           children: [
              //             {
              //               index: true,
              //               element: <Stages />
              //             }
              //           ]
              //         },
              //         {
              //           name: 'Edit campaign',
              //           roles: ['recruitment-campaign.edit'],
              //           description:
              //             'Edit campaign details, including application forms, new starter forms, automation pipelines and forms for custom stages',
              //           icon: <FiEdit />,
              //           path: 'edit',
              //           element: <EditCampaign />
              //         }
              //       ]
              //     }
              //   ]
              // },
              // {
              //   name: 'Recruitment Forms',
              //   path: 'forms',
              //   icon: <FiCheckSquare />,
              //   description:
              //     'Create and manage forms filled in during recruitment, including application forms, new starter forms and forms for custom stages',
              //   roles: ['form'],
              //   children: [
              //     {
              //       index: true,
              //       element: <Tasks />
              //     },
              //     {
              //       name: 'New form',
              //       path: 'new',
              //       roles: ['form.create'],
              //       description:
              //         'Create a new form to be used with recruitment stages',
              //       element: <TaskEditor />
              //     },
              //     {
              //       path: ':id',
              //       name: 'Edit form',
              //       roles: ['form.edit'],
              //       element: <TaskEditor />
              //     },
              //     {
              //       path: 'responses/:id',
              //       name: 'Form responses',
              //       // roles: ['Forms'],
              //       element: <CustomFormResponses />
              //     }
              //   ]
              // }
            ]
          },
          {
            name: 'Time & Attendance',
            roles: ['shift'],
            path: 'ta',
            description:
              'Manage employee shifts, absence, lateness and attendance',
            icon: <FiClock />,
            children: [
              { index: true, element: <></> },
              {
                name: 'Shift Patterns',
                description:
                  'Manage the shifts and shift patterns employees can work',
                path: 'shifts',
                roles: ['shift'],
                children: [
                  { index: true, element: <Shifts /> },
                  { path: 'new', name: 'Create', element: <ShiftDetail /> },
                  { path: ':id', name: 'View', element: <ShiftDetail /> },
                  {
                    path: 'schedules',
                    name: 'Fixed schedules',
                    domain: 'fixed-schedule',
                    description:
                      'Manage schedules of fixed working hours available for employees on fixed or semi-flexible contracts',
                    children: [
                      { index: true, element: <FixedSchedules /> },
                      {
                        path: 'new',
                        name: 'Create',
                        element: <FixedSchedulesDetail />
                      },
                      {
                        path: ':id',
                        name: 'View',
                        element: <FixedSchedulesDetail />
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            name: 'Company',
            path: 'company',
            roles: ['department', 'job-title', 'employee'],
            icon: <FiHome />,
            exact: true,
            description:
              'Manage the company structure, including departments, job roles and employees',
            children: [
              {
                index: true,
                element: <></>
              },
              {
                name: 'Departments',
                roles: ['department'],
                path: 'departments',
                icon: <FiUsers />,
                children: [
                  {
                    index: true,
                    element: <Departments />
                  },
                  {
                    path: 'new',
                    name: 'Create',
                    roles: ['department.create'],
                    icon: <FiPlusCircle />,
                    element: <DepartmentDetail />
                  },
                  {
                    path: ':id',
                    name: 'Edit',
                    roles: ['department.edit'],
                    icon: <FiEdit />,
                    element: <DepartmentDetail />
                  }
                ]
              },
              {
                name: 'Job Roles',
                path: 'roles',
                roles: ['job-title'],
                icon: <FiSettings />,
                children: [
                  {
                    index: true,
                    element: <JobRoles />
                  },
                  {
                    path: 'new',
                    name: 'Create',
                    roles: ['job-title.create'],
                    icon: <FiPlusCircle />,
                    element: <JobRoleDetail />
                  },
                  {
                    path: ':id',
                    name: 'Edit',
                    roles: ['job-title.edit'],
                    icon: <FiEdit />,
                    element: <JobRoleDetail />
                  }
                ]
              }
            ]
          },
          {
            name: 'Staff',
            path: 'staff',
            roles: ['user'],
            icon: <FiUsers />,
            description: 'Manage current and past staff members',
            children: [
              {
                index: true,
                element: <Employees />
              },
              {
                path: 'screening',
                description:
                  'View and manage employee screening checks such as identity and right to work',
                name: 'Employee screening',
                roles: ['employee-screening'],
                children: [
                  {
                    index: true,
                    element: <PrescreeningTable />
                  },
                  {
                    path: ':id',
                    name: 'View screening',
                    element: <PrescreeningDetail />
                  }
                ]
              },
              {
                path: ':id',
                roles: ['user.view'],
                name: 'View',
                children: [{ index: true, element: <ViewUser /> }]
              }
            ]
          }
        ]
      },
      {
        name: 'Fieldwork',
        icon: <FiList />,
        path: 'fieldwork',
        roles: ['project', 'client', 'quote', 'resource-group'],
        description:
          'Call centre, project management, suppliers, clients and more..',
        children: [
          {
            index: true,
            element: <></>
          },
          {
            name: 'Quotes',
            description: 'Manage new and current project quotations',
            path: 'quotes',
            roles: ['quote'],
            children: [
              { index: true, element: <Quotes /> },
              { path: 'new', name: 'Create', element: <ComingSoon /> },
              { path: ':id', name: 'View', element: <ComingSoon /> }
            ]
          },

          {
            name: 'Project Management',
            path: 'pm',
            roles: ['project'],
            description:
              'Manage new and current projects, create or commission projects, assign project teams and more...',

            children: [
              { index: true, element: <Projects /> },
              { path: 'new', name: 'Create', element: <ProjectDetail /> },
              {
                path: ':projectId',
                name: 'View',
                children: [
                  { index: true, element: <ProjectDetail /> },
                  {
                    name: 'Target Groups',
                    path: 'target-groups',
                    children: [
                      { index: true, element: <TargetGroups /> },
                      {
                        path: 'new',
                        name: 'Create',
                        element: <TargetGroupDetail />
                      },
                      {
                        path: ':targetGroupId',
                        name: 'View',
                        element: <TargetGroupDetail />
                      }
                    ]
                  },
                  {
                    path: 'questionnaires',
                    name: 'Questionnaires',
                    children: [
                      { index: true, element: <QuestionnairesTable /> },
                      {
                        path: 'new',
                        name: 'Create',
                        element: <QuestionnaireDetail />
                      },
                      {
                        path: ':questionnaireId',
                        name: 'View',
                        element: <QuestionnaireDetail />
                      }
                    ]
                  }
                ]
              },
              {
                name: 'Resource Groups',
                description:
                  'Manage the types of resources that can be utilised by projects',
                path: 'resource-groups',
                roles: ['resource-group'],
                children: [
                  { index: true, element: <ResourceGroups /> },
                  {
                    path: 'new',
                    name: 'Create',
                    element: <ResourceGroupDetail />
                  },
                  {
                    path: ':resourceGroupId',
                    name: 'View',
                    element: <ResourceGroupDetail />
                  }
                ]
              },
              {
                roles: ['project.admin'],
                name: 'Team Roles',
                description:
                  'Manage the roles that users can play in project management',
                path: 'roles',
                children: [
                  { index: true, element: <ComingSoon /> },
                  { path: 'new', name: 'Create', element: <ComingSoon /> },
                  { path: ':id', name: 'View', element: <ComingSoon /> }
                ]
              }
            ]
          },
          {
            name: 'Clients',
            roles: ['client'],
            description:
              'Manage new and current clients, as well as client contacts',
            path: 'clients',
            children: [
              { index: true, element: <Clients /> },
              { path: 'new', name: 'Create', element: <ClientDetail /> },
              { path: ':id', name: 'View', element: <ClientDetail /> }
            ]
          }
        ]
      },
      {
        name: 'Settings',
        icon: <FiSettings />,
        path: 'settings',
        roles: ['company'],
        description: "Manage your company's app configuration and settings",
        children: [
          {
            index: true
          },
          {
            name: 'Storage',
            path: 'storage',
            icon: <FiFolder />,
            description:
              'View all files on the system, manage storage space, download and delete files',
            children: [{ index: true, element: <Storage /> }]
          },
          {
            name: 'Permissions',
            path: 'permissions',
            icon: <FiKey />,
            description:
              'Manage user permissions by configuring access roles, and custom permission settings',
            children: [
              {
                index: true,
                element: <></>
              },
              {
                name: 'Access Groups',
                path: 'groups',
                roles: ['access-group'],
                children: [
                  { index: true, element: <AccessGroups /> },
                  {
                    path: 'new',
                    name: 'Create',
                    element: <AccessGroupDetail />
                  },
                  {
                    path: ':id',
                    name: 'View',
                    element: <AccessGroupDetail />
                  }
                ]
              }
            ]
          },
          {
            name: 'Notifications',
            path: 'notifications',
            icon: <FiBell />,
            description:
              'Manage how notifications go out, including email templates and channels',
            children: [
              {
                index: true,
                element: <Mailer />
              },
              {
                path: 'templates',
                name: 'Templates',
                icon: <FiFileText />,
                children: [
                  {
                    name: 'Create template',
                    path: 'new',
                    element: <TemplateDetail />
                  },
                  {
                    name: 'Edit template',
                    path: ':id',
                    element: <TemplateDetail />
                  }
                ]
              }
            ]
          },
          {
            name: 'Calendars',
            path: 'calendars',
            icon: <FiCalendar />,
            description:
              'Manage the calendars used by projects, users, and more. Perform bulk changes on calendars and calendar events/tasks. Review calendar permissions and share calendars with users.',
            children: [
              {
                index: true,
                element: <Calendars />
              },
              {
                path: 'new',
                name: 'Create',
                element: <CalendarDetail />
              },
              {
                path: ':calendarId',
                name: 'View',
                element: <CalendarDetail />
              }
            ]
          }
        ]
      },
      {
        path: 'support',
        name: 'Support',
        description:
          'Get help from our support team, view your ongoing tickets, and use knowledgebase articles and company process documents',
        children: [
          {
            name: 'Tickets',
            path: 'tickets',
            children: [
              {
                index: true,
                element: <Support />
              },
              {
                path: ':status',
                children: [
                  {
                    path: ':id',
                    name: 'View ticket',
                    element: <TicketDetail />
                  }
                ]
              },
              {
                path: ':id',
                name: 'View ticket',
                element: <TicketDetail />
              }
            ]
          },
          {
            name: 'Documentation',
            path: 'documentation',
            children: [
              { index: true, element: <></> },
              {
                name: 'FAQ',
                description:
                  'Answers to the questions we get asked the most about with Flex',
                icon: <FiHelpCircle />,
                path: 'faq',
                element: <Faq />
              },
              {
                name: companyConfig.companyName + ' knowledgebase',
                description:
                  'Process documents, help files, training, wiki and how-to guides for ' +
                  companyConfig.companyName,
                icon: <FiHelpCircle />,
                path: 'knowledgebase',
                element: <ComingSoon />
              }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  },
  {
    path: '*',
    element: <ErrorLayout />,
    children: [
      { path: '404', element: <Error404 backBtn={false} /> },
      { path: '401', element: <Error401 /> },
      { path: '403', element: <Error403 /> }
    ]
  }
];
export type Route = {
  name: string;
  path: string;
  element: ReactNode;
  roles?: string[];
  icon?: ReactNode;
  description?: string;
  children?: Route[];
  color?: string;
  domain?: RoleDomain;
  index?: boolean;
};
const colors = new Set(['primary', 'danger', 'info', 'warning', 'success']);
let colorEnum = colors.values();
const nextColor = () => {
  let color = colorEnum.next();
  if (color.done) {
    colorEnum = colors.values();
    color = colorEnum.next();
  }
  return color.value;
};
const filterRoutesRecursively = (
  children,
  inheritedRoles = null,
  inheritedDomain = null
) => {
  return children.map(route => {
    const getDomain = (...str) => {
      return str
        .find(s =>
          Object.keys(settings.domains).find(
            d => d?.toLowerCase() === s?.toLowerCase()
          )
        )
        ?.toLowerCase();
    };
    const domain = getDomain(
      route.name,
      //remove the last letter
      route.name?.slice(0, -1),
      route.name + 's',
      route.domain,
      ...(route?.roles || []),
      inheritedDomain,
      ...(inheritedRoles || [])
    );
    const domainObj = settings.domains[domain];
    const color = domainObj?.variant || nextColor();
    const icon = route.icon || domainObj?.icon;
    // console.log(
    //   route,
    //   domainObj,
    //   'icon for ',
    //   route.name,
    //   domain,
    //   ' is ',
    //   icon
    // );
    const newRoute = {
      ...route,
      roles: route.roles || inheritedRoles,
      domain,
      icon
    };
    if (newRoute.index) {
      newRoute.element = (
        <>
          {newRoute.element}
          <QuickLinks />
        </>
      );
    }
    if (newRoute.children) {
      newRoute.children = filterRoutesRecursively(
        [...newRoute.children],
        newRoute.roles,
        newRoute.domain
      );
      return {
        ...newRoute,
        element: !!newRoute.element && (
          <RolesAuthRoute roles={newRoute.roles} route={newRoute.element} />
        ),
        handle: { ...newRoute, color, domain },
        color,
        icon,
        domain,
        errorElement: <Sentry.ErrorBoundary fallback={<Error500 />} />
      };
    }
    return {
      ...newRoute,
      handle: { ...newRoute, color, icon, domain },
      element: !!newRoute.element && (
        <RolesAuthRoute route={newRoute.element} roles={newRoute.roles} />
      ),
      errorElement: <Sentry.ErrorBoundary fallback={<Error500 />} />,
      color,
      domain,
      icon
    };
  });
};
export const getRoutes = () => {
  return filterRoutesRecursively([...coreRoutes]);
};
const recursivelyRemoveDisallowed = (children, isAllowed) => {
  return children
    .filter(r => isAllowed(r.roles))
    .map(route => {
      if (route.children) {
        route.children = recursivelyRemoveDisallowed(route.children, isAllowed);
      }
      return route;
    });
};
export const getRolesRoutes = isAllowed => {
  return recursivelyRemoveDisallowed(getRoutes(), isAllowed);
};

export default [coreRoutes];
