import React from 'react';
import Greetings from '../../common/Greetings';
import Campaigns from './campaigns';
import CustomReportTiles from '../reporting/CustomReportTiles';

const Recruitment = () => {
  return (
    <>
      {/* <Greetings title="Recruitment" /> */}
      {/* <CustomReportTiles defaultValues={{ db: 'Recruitment' }} simple /> */}
      <Campaigns />
    </>
  );
};

export default Recruitment;
