import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Campaign } from 'apis/flex/recruitment';
import { FieldArrayList } from 'components/common/customForms/Editor/CustomFormQuestionEditor';
import { Col, Row } from 'react-bootstrap';
import WizardInput from 'components/wizard/WizardInput';
import StageForm from '../../stages/StageForm';

export default () => {
  const { fields, append, remove } = useFieldArray<Campaign>({
    name: 'stages'
  });
  return (
    <FieldArrayList
      tabs
      defaultValues={{ name: '', isActive: true }}
      fields={fields}
      append={f => append(f)}
      remove={remove}
      item={(f, i) => (
        <Row className="align-items-center">
          <WizardInput
            name={`stages.${i}.name`}
            label="Name"
            formGroupProps={{ as: Col, xs: 12 }}
          />
          <WizardInput
            name={`stages.${i}.isActive`}
            type="switch"
            label="Active"
            formGroupProps={{ as: Col, xs: 12 }}
            registerProps={{ required: false }}
          />
          <StageForm
            withForm
            namePrefix={`stages.${i}.`}
            formGroupProps={[
              { as: Col, xs: 12 },
              { as: Col, xs: 12 }
            ]}
          />
        </Row>
      )}
    />
  );
};
