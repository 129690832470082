import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import WizardInput from 'components/wizard/WizardInput';
import { useFormContext, useWatch } from 'react-hook-form';
import CustomTooltip from 'components/common/Tooltip';
import FormPicker from 'components/common/customForms/widgets/FormPicker';
import useCustomForm from 'components/common/customForms/hooks.js/useCustomForm';
import { truncate } from 'lodash';
import { removeHtmlTags } from 'helpers/strings';
import { ResponsiveModal } from 'components/common/Modals';
// import { useHistory } from 'react-router-dom';

const CampaignInformationForm = () => {
  const applicants = useWatch({ name: 'applicants' });
  const formId = useWatch({ name: 'formId' });
  const { setValue, getValues } = useFormContext();
  const { form } = useCustomForm({ formId });
  const getQuestionsOfType = t =>
    form?.sections
      ?.map(s => s.questions.filter(q => q.inputType === t && q.isRequired))
      .flat(2) || [];
  useEffect(() => {
    const emailQs = getQuestionsOfType('email');
    if (emailQs?.length === 1) {
      setValue('formEmailQuestionId', emailQs[0].id);
    }
    const nameQs = getQuestionsOfType('name');
    if (nameQs?.length === 1) {
      setValue('formNameQuestionId', nameQs[0].id);
    }
    const telQs = getQuestionsOfType('mobile');
    if (telQs?.length === 1) {
      setValue('formTelQuestionId', telQs[0].id);
    }
  }, [form, getQuestionsOfType]);
  const getQuestionOptions = type =>
    getQuestionsOfType(type).map(q => ({
      label:
        q.label +
        '. ' +
        truncate(removeHtmlTags(q.questionText), { length: 50 }),
      value: q.id
    }));
  const [linkWarning, setLinkWarning] = React.useState(false);
  return (
    <Row className="gx-2 gy-3">
      <Col md="12">
        <WizardInput
          name="name"
          instruction={'Name of the campaign for internal reference'}
        />
      </Col>
      <Col xs="12">
        <CustomTooltip
          content={
            applicants?.length > 0 &&
            'This campaign has already had some responses so the form cannot be changed. To use a new form, create a new campaign.'
          }
        >
          <div>
            <FormPicker
              name="formId"
              formGroupProps={{ 'data-tour': 'form-select' }}
              readOnly={applicants?.length > 0}
              label="Application form"
              requiredQuestionTypes={['email', 'name', 'mobile']}
              instruction={
                'This is the form that applicants will complete when applying. It must have mandatory email, name and mobile phone fields'
              }
              registerProps={{
                onChange: e => {
                  console.log('form changed', getValues('inviteLink'));
                  if (getValues('inviteLink')) {
                    setLinkWarning(true);
                    setValue('inviteLink', null);
                    setValue('inviteLinkId', null);
                  }
                }
              }}
            />
            {linkWarning && (
              <ResponsiveModal show backdrop="static">
                <Modal.Header>
                  <Modal.Title className="text-danger">Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Once you save this campaign with a different application form,
                  any invite links created for this campaign will not work!
                  <br />
                  You will need to create a new invite link after saving your
                  changes.
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="falcon-danger"
                    onClick={() => setLinkWarning(false)}
                  >
                    Ok
                  </Button>
                </Modal.Footer>
              </ResponsiveModal>
            )}
            {formId && (
              <Row className="mt-3">
                <Col md={6} xl={4}>
                  <WizardInput
                    name="formEmailQuestionId"
                    type="select"
                    readOnly={applicants?.length > 0 || !form}
                    formGroupProps={{ 'data-tour': 'formEmailQuestionId' }}
                    label="Email question"
                    options={getQuestionOptions('email')}
                  />
                </Col>
                <Col md={6} xl={4}>
                  <WizardInput
                    name="formNameQuestionId"
                    formGroupProps={{ 'data-tour': 'formNameQuestionId' }}
                    label="Name question"
                    type="select"
                    readOnly={applicants?.length > 0 || !form}
                    options={getQuestionOptions('name')}
                  />
                </Col>
                <Col md={6} xl={4}>
                  <WizardInput
                    name="formTelQuestionId"
                    formGroupProps={{ 'data-tour': 'formTelQuestionId' }}
                    label="Mobile phone question"
                    type="select"
                    readOnly={applicants?.length > 0 || !form}
                    options={getQuestionOptions('mobile')}
                  />
                </Col>
              </Row>
            )}
          </div>
        </CustomTooltip>
      </Col>
      <Col md="12">
        <WizardInput
          name="campaignDates"
          instruction={'When should this campaign be open for applications?'}
          type="daterange"
          formGroupProps={{ 'data-tour': 'campaign-dates' }}
        />
        <WizardInput
          name="maxApplicants"
          type="number"
          label="Maximum number of applicants"
          registerProps={{ required: false }}
          instruction={
            'The application form will close once this number of applicants have applied. Leave blank to have no limit.'
          }
        />
        <WizardInput
          name="maxAccepted"
          type="number"
          label={
            <>
              Maximum number of <b>accepted</b> applicants
            </>
          }
          registerProps={{ required: false }}
          instruction={
            'The application form will close once this number of applicants have been accepted. Leave blank to have no limit.'
          }
        />
      </Col>
      {/* <Col xs="12"> */}
      {/* <WizardInput name="tags" type="select" registerProps={{required:false}} options={[]} /> */}
      {/* </Col> */}
      <Col xs="12">
        <WizardInput
          name="description"
          label="Description (optional)"
          instruction={'Description of the campaign for internal reference'}
          registerProps={{ required: false }}
          type="textarea"
        />
      </Col>
    </Row>
  );
};

CampaignInformationForm.propTypes = {
  getValues: PropTypes.func,
  setValue: PropTypes.func,
  applicants: PropTypes.array
};

export default CampaignInformationForm;
