import { EventAction, EventPrefix } from 'apis/flex/notifications';
import { DefaultCrudData } from 'hooks/useDefaultCrud';
import { useMutation } from '@tanstack/react-query';
import { devApi, SavedEvent } from 'apis/flex/helpers';
import classNames from 'classnames';
import React from 'react';
import { Alert, AlertLink } from 'react-bootstrap';
import InlineSpinner from './InlineSpinner';
import { ApiFilters } from 'apis/types';
import { domainConfigs } from 'components/notification/config';

export default <TData extends DefaultCrudData = any>({
  domain,
  action,
  children,
  filters
}: {
  action: EventAction;
  domain: EventPrefix;
  children: React.ReactNode;
  filters?: ApiFilters<TData>;
}) => {
  const crudHook = domainConfigs[domain]?.crudHook;
  const [done, setDone] = React.useState(false);
  const { refire, isRefiring } = crudHook({
    filters,
    afterSave: () => setDone(true)
  });
  return (
    <Alert variant={done ? 'success' : 'danger'} dismissible>
      <Alert.Heading>Something's not quite right</Alert.Heading>
      <p>
        {done
          ? 'Re-processing has begun. It may take a few minutes to take effect.'
          : children}
      </p>
      {!done && (
        <Alert.Link
          href="#"
          onClick={() => refire({ action })}
          className={classNames('me-2', {
            'opacity-50 pointer-events-none': isRefiring
          })}
        >
          {isRefiring && <InlineSpinner />}
          Click here to self-heal
        </Alert.Link>
      )}
    </Alert>
  );
};
