import React from 'react';
import RemoteCalendar from 'components/app/calendars/RemoteCalendar';
import useCalendars from 'components/app/calendars/useCalendars';
import { Row, Col, Card } from 'react-bootstrap';
import { domainToSentence } from './DomainTimeline';
import { EventPrefix } from 'apis/flex/notifications';
import LoadingButton from './LoadingButton';
import DetailCard from './detail/DetailCard';
import { CalendarProps } from 'components/app/calendars/Calendar';
import classNames from 'classnames';
import { useGuard } from 'hooks/useGuard';

export default ({
  domain,
  itemId,
  title,
  className,
  header = true,
  md,
  id,
  ...rest
}: {
  domain: EventPrefix;
  itemId: number;
  title?: string;
  md?: number;
  id?: string;
} & CalendarProps) => {
  const {
    data: calendarIds,
    add,
    isAdding,
    isLoading
  } = useCalendars({
    filters: { domain, itemId },
    useFilter: !!domain && !!itemId,
    columns: ['id'],
    asList: true,
    select: d => d.map(dd => dd.id),
    invalidate: [domain]
  });
  const calendarDomains: EventPrefix[] = ['user', 'project'];
  const detailCardProps = {
    id: id || domain + '-' + itemId + '-calendar',
    title: 'Calendar',
    headerProps: { className: classNames({ 'd-none': !header }) },
    colProps: {
      className: classNames({ [`col-md-${md}`]: !!md })
    }
  };
  const { canCreate } = useGuard({ roles: ['calendar'] });
  return calendarIds?.length ? (
    <DetailCard {...detailCardProps}>
      <RemoteCalendar
        header={header}
        calendarIds={calendarIds}
        className={classNames(className, 'shadow-none')}
        {...rest}
      />
    </DetailCard>
  ) : (
    !isLoading &&
      !!canCreate &&
      calendarDomains.includes(domain) &&
      !!itemId && (
        <DetailCard {...detailCardProps}>
          <div className="d-flex align-items-center justify-content-center">
            {/* <Col lg={6} className="text-center">
              <DomainIcon domain="calendar" size="xl" />
            </Col> */}
            <div className="text-center">
              {/* <h3>Missing</h3> */}
              <p className="lead">
                This {domainToSentence(domain).toLowerCase()} has no calendar
                yet
              </p>
              <LoadingButton
                onClick={() =>
                  add({
                    domain,
                    itemId,
                    name: title || `${domainToSentence(domain)} #${itemId}`
                  })
                }
                variant="falcon-primary"
                loading={isAdding}
              >
                Create one now
              </LoadingButton>
            </div>
          </div>
        </DetailCard>
      )
  );
};
