import { useMutation } from "@tanstack/react-query";
import { uploadMedia } from "apis/flex/helpers";
export default ({
  authItemCollection,
  authItemId,
  isPublic,
  isTemp,
  settings,
  ...rest
}) => {
  return useMutation({
    mutationFn: async ({
      files,
      options = {}
    }) => {
      return await uploadMedia(
        { "0": files },
        options.settings || settings,
        options.isPublic || isPublic,
        options.isTemp || isTemp,
        options.authItemCollection || authItemCollection,
        options.authItemId || authItemId
      );
    },
    ...rest
  });
};
