import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import useJobRoles from 'components/app/hr/jobRoles/useJobRoles';
import { JobRole } from 'apis/flex/hr';
import JobRoleDetailModal from 'components/app/hr/jobRoles/JobRoleDetailModal';
const JobTitleSelect = ({
  name,
  filter = () => true,
  ...rest
}: {
  name?: string;
  filter?: (jt: JobRole) => boolean;
} & Partial<WizardInputProps>) => {
  const { setValue } = useFormContext();
  const { data: jobTitles, isLoading } = useJobRoles<
    { value: number; label: string }[]
  >({
    useFilter: true,
    getPublic: true,
    select: d =>
      d.filter(filter).map(d => ({ value: d.id, label: d.jobTitle })),
    ...rest
  });
  const [show, setShow] = useState(false);
  const [newStr, setNewStr] = useState<string>();
  const handleCreate = (val: string) => {
    setShow(true);
    setNewStr(val);
  };
  return (
    <>
      <WizardInput
        name={name || 'jobTitleId'}
        label="Job Title"
        type="select"
        loading={isLoading}
        options={jobTitles}
        pluginProps={{
          onCreate: handleCreate
        }}
        {...rest}
      />
      {newStr && show && (
        <JobRoleDetailModal
          defaultValues={{ jobTitle: newStr }}
          show={show}
          setShow={setShow}
          onUpserted={d => setValue('jobTitleId', d.id)}
        />
      )}
    </>
  );
};
JobTitleSelect.propTypes = {
  name: PropTypes.string
};
export default JobTitleSelect;
