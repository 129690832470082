import { FixedSchedule } from 'apis/flex/hr';
import DomainTable from 'components/common/DomainTable';
import React from 'react';

export default () => {
  return (
    <DomainTable<FixedSchedule>
      domain="fixed-schedule"
      columns={['name', { id: 'shifts', formatter: v => v.length }]}
    />
  );
};
