import React, { useEffect } from 'react';
import AdvanceTableProvider from '../common/advance-table-v2/AdvanceTableProvider';
import AdvanceTable from '../common/advance-table-v2/AdvanceTable';
import useFileExplorer from 'hooks/useFileExplorer';
import FileDownloader, { useFileDownloader } from './FileDownloader';
import { CrudFilters } from 'hooks/useDefaultCrud';
import { FlexFile, uploadMedia } from 'apis/flex/helpers';
import { FormProvider, useForm } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
import { RoleDomain } from 'apis/flex/users';
import { FileId } from 'apis/types';
import PageLoader from '../common/PageLoader';
import { useMutation } from '@tanstack/react-query';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { fileSize } from 'components/app/reporting/helpers';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import { cleanFilePath, mimeIcon } from 'helpers/utils';
import classNames from 'classnames';
import useFileUpload, { FileWithPreview } from 'hooks/useFileUpload';

export const FileMimeIcon = ({
  file,
  height,
  width,
  isLoading,
  className,
  ...rest
}: {
  file?: FlexFile;
  isLoading?: boolean;
  height?: number | string;
  width?: number | string;
} & Partial<FontAwesomeIconProps>) => {
  const fileLogoStyle = {
    width: width || height || 30,
    height: height || width || 30
  };
  return (
    <FontAwesomeIcon
      icon={file?.mimeType ? mimeIcon(file?.mimeType) : 'file'}
      className={classNames('rounded', className, {
        'opacity-25': isLoading
      })}
      style={fileLogoStyle}
      {...rest}
    />
  );
};
export const FileAdder = ({
  authItemCollection,
  authItemId,
  onSubmitted,
  children
}: {
  authItemCollection?: RoleDomain;
  authItemId?: number;
  onSubmitted?: (files: FileId[]) => void;
  children?: React.ReactNode;
}) => {
  const methods = useForm();
  const { mutate, isLoading } = useFileUpload({
    authItemCollection,
    authItemId
  });
  const handleChange = (files: FileWithPreview[]) => {
    files.forEach(file => {});
    mutate(
      { files },
      {
        onSuccess: fileIdObj => {
          methods.reset();
          onSubmitted && onSubmitted(fileIdObj['0']);
        }
      }
    );
  };
  return (
    <FormProvider {...methods}>
      {isLoading ? (
        <PageLoader title="Uploading file..." height={'30vh'} />
      ) : (
        <WizardInput
          name="file"
          hideLabel
          label="File"
          type="file"
          pluginProps={{ children, maxFiles: 99 }}
          registerProps={{
            required: true,
            onChange: (e: any) => {
              //   console.log('changed', e);
              handleChange(e.target.value);
            }
          }}
        />
      )}
      {/* <div>
        <LoadingButton
          loading={methods.formState.isSubmitting}
          onClick={methods.handleSubmit(onSubmitted, onError)}
        >
          Upload
        </LoadingButton>
      </div> */}
    </FormProvider>
  );
};
export default ({
  filters,
  authItemCollection,
  authItemId
}: {
  filters?: CrudFilters<FlexFile>;
  authItemCollection?: RoleDomain;
  authItemId?: number;
}) => {
  const { data, isLoading, refetch, bulkRemoveAsync } = useFileExplorer({
    filters: { ...filters, authItemCollection, authItemId },
    useFilter: true
  });
  const { downloadAsync } = useFileDownloader();
  return (
    <AdvanceTableProvider
      sqlDb="System"
      sqlTables={['FileStorage']}
      columns={[
        {
          id: 'originalName',
          header: 'Name',
          formatter: (v, d) => {
            return (
              <FileDownloader files={[d.fileId]}>
                <FileMimeIcon height={'1rem'} file={d} className="me-2" /> {v()}
              </FileDownloader>
            );
          }
        },
        { id: 'authItemCollection', header: 'Collection' },
        {
          id: 'filePath',
          header: 'Location',
          accessorFn: d => cleanFilePath(d.filePath)
        },
        { id: 'addedBy', domain: 'user' },
        { id: 'size', formatter: v => fileSize(v()) },
        'added',
        'lastAccessed'
      ]}
      data={data}
      isLoading={isLoading}
      bulkActions={[
        {
          name: 'Delete',
          icon: 'trash',
          confirm: d => 'Delete ' + d.length + ' files?',
          actionFn: async rows => {
            bulkRemoveAsync(rows.map(row => row.original.id));
          }
        },
        {
          name: 'Download',
          icon: faDownload,
          actionFn: async rows => {
            downloadAsync(rows.map(row => row.original.fileId));
          }
        }
      ]}
      actions={[
        {
          name: 'Download',
          icon: faDownload,
          onClick: row => {
            downloadAsync(row.original.fileId);
          }
        },
        {
          name: 'Delete',
          icon: 'trash',
          confirm: true,
          variant: 'danger',
          onClick: async row => {
            bulkRemoveAsync([row.original.id]);
          }
        }
      ]}
    >
      {authItemCollection && authItemId ? (
        <FileAdder
          authItemCollection={authItemCollection}
          authItemId={authItemId}
          onSubmitted={() => refetch()}
        >
          <div onClick={e => e.stopPropagation()}>
            <AdvanceTable />
          </div>
        </FileAdder>
      ) : (
        <AdvanceTable />
      )}
    </AdvanceTableProvider>
  );
};
