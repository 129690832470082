import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  markNotificationAsActioned,
  markNotificationAsSeen
} from "apis/flex/notifications";
import { useCallback, useState } from "react";
export default ({
  domain: _domain,
  eventName: _eventName,
  id: _id,
  itemId: _itemId
} = {}) => {
  const queryClient = useQueryClient();
  const [alreadySent, setAlreadySent] = useState();
  const { mutate } = useMutation({
    mutationKey: ["notificationsActioned"],
    onError: (e) => {
      console.error(e);
    },
    mutationFn: ({
      domain = _domain,
      eventName = _eventName,
      id = _id,
      itemId = _itemId,
      fn,
      alreadySent: alreadySent2
    }) => {
      if (alreadySent2?.has(JSON.stringify({ domain, eventName, id, itemId }))) {
        return Promise.resolve();
      }
      setAlreadySent(
        (s) => (s || /* @__PURE__ */ new Set()).add(JSON.stringify({ domain, eventName, id, itemId }))
      );
      return fn({
        eventName,
        domain,
        id,
        itemId
      });
    },
    onSuccess: (d) => {
      if (d) {
        queryClient.invalidateQueries(["notifications"]);
        queryClient.invalidateQueries(["Notifications"]);
      }
    }
  });
  const setActioned = useCallback(
    ({
      domain,
      eventName,
      id,
      itemId
    } = {}) => {
      return mutate({
        domain,
        eventName,
        id,
        itemId,
        fn: markNotificationAsActioned,
        alreadySent
      });
    },
    [mutate, alreadySent]
  );
  const setSeen = useCallback(
    ({
      domain,
      eventName,
      id,
      itemId
    } = {}) => {
      return mutate({
        domain,
        eventName,
        id,
        itemId,
        fn: markNotificationAsSeen,
        alreadySent
      });
    },
    [mutate, alreadySent]
  );
  return {
    setActioned,
    setSeen
  };
};
