import { useQuery } from "@tanstack/react-query";
import { getMediaStream } from "apis/flex/helpers";
import useArray from "./useArray";
export default ({ fileId }) => {
  const array = useArray(fileId);
  return useQuery({
    enabled: !!array,
    queryKey: ["MediaBlob", array],
    queryFn: async () => {
      const files = await Promise.all(
        array?.map(async (id) => {
          if (typeof id === "string" && (id.startsWith("http") || id.startsWith("blob") || id.startsWith("data")))
            return { blob: id, preview: id };
          if (typeof id !== "string")
            return {
              blob: id?.blob,
              preview: id?.blob
            };
          const blob = await getMediaStream(id);
          return { blob, preview: URL.createObjectURL(blob) };
        })
      );
      return files;
    },
    staleTime: 1e3 * 60 * 60
  });
};
