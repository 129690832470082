import React, { HTMLProps, ReactNode, useState } from 'react';
import { Accept, DropzoneOptions, useDropzone } from 'react-dropzone';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';
import { blobIcon, getSize } from 'helpers/utils';
import { Dropdown, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import CustomTooltip from '../common/Tooltip';
import classNames from 'classnames';
import { apiCustomError } from 'apis/errors';
import is from 'is_js';
import Skeleton from 'react-loading-skeleton';
import { useMutation } from '@tanstack/react-query';
import { getMediaUrl } from 'apis/flex/helpers';
import { truncate } from 'lodash';
export const FileItem = ({ file, actions = [] }) => {
  const fileLogoStyle = { width: 30, height: 30 };
  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return getMediaUrl(file.uploadPath);
    },
    onSuccess: url => {
      window.open(url, 'blank');
    }
  });
  return (
    <Flex
      alignItems="center"
      className="border border-200 btn-reveal-trigger cursor-pointer position-relative px-2 py-2 rounded-2 shadow-sm"
      justifyContent={'between'}
    >
      {is.string(file) ? (
        <div className="w-100 d-flex">
          <Skeleton style={fileLogoStyle} />
          <Skeleton containerClassName="flex-1 px-2" />
        </div>
      ) : (
        <>
          <div className="position-relative">
            <div className="position-absolute position-absolute start-50 text-400 top-0 top-50 translate-middle">
              {(file.uploading || isLoading) && (
                <Spinner size="sm" variant="info" />
              )}
              {file.uploadError && (
                <CustomTooltip content="Failed to upload, please remove and re-try">
                  <span>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="text-danger"
                      style={fileLogoStyle}
                    />
                  </span>
                </CustomTooltip>
              )}
            </div>
            {file.type?.indexOf('image') === 0 ? (
              <img
                className={classNames('rounded', {
                  'opacity-25': file.uploading
                })}
                style={fileLogoStyle}
                src={file.preview}
                alt={file.path || file.name}
              />
            ) : (
              <FontAwesomeIcon
                icon={blobIcon(file) || 'file'}
                className={classNames('rounded', {
                  'opacity-25': file.uploading
                })}
                style={fileLogoStyle}
              />
            )}
          </div>
          {/* <Link to={file.uploadPath && getMediaStreamUrl(file.uploadPath)}> */}
          <Flex
            justifyContent="between"
            alignItems="center"
            className="ms-3 flex-1"
            onClick={() => mutate()}
          >
            <div>
              <h6 className="link-primary mb-0 stretched-link">
                {truncate(file.path || file.name)}
              </h6>
              <Flex className="position-relative" alignItems="center">
                <p className="mb-0 fs--1 text-400 line-height-1">
                  <strong>{getSize(file.size)}</strong>
                </p>
              </Flex>
            </div>
          </Flex>
          {/* </Link> */}
          {actions && (
            <CardDropdown>
              {actions.map((a, i) => (
                <div key={i} className="py-2">
                  <Dropdown.Item className="text-danger" onClick={a.fn}>
                    {a.label}
                  </Dropdown.Item>
                </div>
              ))}
            </CardDropdown>
          )}
        </>
      )}
    </Flex>
  );
};
const FileUploader = ({
  accept = {},
  onChange = v => console.log(v),
  files = [],
  label,
  formControlProps = { disabled: false, className: null },
  className = '',
  dropzoneProps = {},
  hidden,
  children
}: {
  accept?: Accept;
  onChange?: (files: any) => any;
  files?: any[];
  label?: string;
  formControlProps?: HTMLProps<HTMLInputElement>;
  className?: string;
  dropzoneProps?: DropzoneOptions;
  hidden?: boolean;
  children?: ReactNode;
}) => {
  const disabled = formControlProps?.disabled || dropzoneProps?.disabled;
  const mutateFile = file => {
    if ((file.path || file.name) && !file.preview && !file.uploadPath) {
      const url = URL.createObjectURL(file);
      return Object.assign(file, {
        preview: url,
        blob: url,
        icon: blobIcon(file)
      });
    }
    return file;
  };
  const [hovering, setHovering] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: acceptedFiles => {
      setHovering(false);
      const totalFiles = [...files, ...acceptedFiles.map(mutateFile)];
      if (dropzoneProps.maxFiles) {
        totalFiles.splice(0, totalFiles.length - dropzoneProps.maxFiles);
      }
      onChange(totalFiles);
    },
    onDropRejected: files => {
      console.log('REJECTED', files);
      apiCustomError(files[0].errors[0].message)(files[0].errors);
    },
    onDragEnter: () => setHovering(true),
    onDragLeave: () => setHovering(false),
    ...dropzoneProps
  });

  const handleRemove = index => {
    onChange(files.filter((f, i) => i !== index));
  };
  return (
    // {!disabled && (
    <div
      {...getRootProps({
        className:
          'w-100 h-100 cursor-pointer d-flex align-items-center justify-content-center'
      })}
    >
      {!disabled && <input {...getInputProps()} {...formControlProps} />}
      {children || (
        <span className={classNames('w-100', { 'd-none': hidden })}>
          <div
            className={classNames(
              'dropzone-area form-control rounded-3',
              className,
              {
                'py-6': !className,
                'bg-200 border-primary shadow-lg': hovering
              },
              formControlProps.className
            )}
            // {...formControlProps}
          >
            <Flex justifyContent="center">
              <img src={cloudUpload} alt="" width={25} className="me-2" />
              <p className="fs-0 mb-0 text-700 d-none d-lg-block">
                {label || 'Drop your files here'}
              </p>
            </Flex>
          </div>
          <div
            className={classNames('d-flex flex-column w-100', {
              'mt-3': files.length > 0
            })}
          >
            {files.map((file, i) => (
              <FileItem
                key={i}
                file={file}
                actions={
                  !disabled && [{ label: 'Remove', fn: () => handleRemove(i) }]
                }
              />
            ))}
          </div>
        </span>
      )}
    </div>
    // )}
  );
};
export default FileUploader;
