import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownProps } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
export const CardDropdownToggle = ({
  btnRevealClass = null,
  icon = 'ellipsis-h',
  buttonClass = null
}: {
  btnRevealClass?: string;
  icon?: IconProp;
  buttonClass?: string;
}) => {
  return (
    <Dropdown.Toggle
      variant="link"
      size="sm"
      data-boundary="viewport"
      className={classNames('text-600 dropdown-caret-none', buttonClass, {
        [btnRevealClass]: btnRevealClass,
        'btn-reveal': !btnRevealClass
      })}
    >
      <FontAwesomeIcon icon={icon} className="fs--2" />
    </Dropdown.Toggle>
  );
};
const CardDropdown = ({
  btnRevealClass,
  drop,
  children,
  icon = 'ellipsis-h',
  className = '',
  buttonClass = ''
}: {
  btnRevealClass?: string;
  icon?: IconProp;
  buttonClass?: string;
  drop?: DropdownProps['drop'];
  children?: ReactNode;
  className?: string;
}) => {
  return (
    <Dropdown
      className={classNames(className, 'font-sans-serif btn-reveal-trigger')}
      align={'end'}
      drop={drop}
      // style={{ zIndex: 1050 }}
    >
      <CardDropdownToggle
        btnRevealClass={btnRevealClass}
        icon={icon}
        buttonClass={buttonClass}
      />
      {createPortal(
        <Dropdown.Menu className="border py-0" style={{ zIndex: 1099 }}>
          {children}
          {!children && (
            <div className="py-2">
              <Dropdown.Item>View</Dropdown.Item>
              <Dropdown.Item>Export</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className="text-danger">Remove</Dropdown.Item>
            </div>
          )}
        </Dropdown.Menu>,
        document.body
      )}
    </Dropdown>
  );
};

CardDropdown.propTypes = {
  btnRevealClass: PropTypes.string,
  drop: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  className: PropTypes.string,
  buttonClass: PropTypes.string
};

export default CardDropdown;
