import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ShareCampaign from '../campaigns/InvitePeople';
import DomainReportTiles from 'components/app/reporting/DomainReportTiles';
import useCampaign from '../campaigns/hooks/useCampaign';
import ApplicantsTable from './ApplicantsTable';
import ApplicantsMissing from './ApplicantsMissing';
import DomainDetail from 'components/common/DomainDetail';
import { useWatch } from 'react-hook-form';
import { CampaignSidebar } from '../campaigns/create/PublishCampaign';
import DetailCard from 'components/common/detail/DetailCard';
import CampaignInformationForm from '../campaigns/create/CampaignInformationForm';
import JobInformation from '../campaigns/create/JobInformation';
import Stages from '../campaigns/create/Stages';
import Pipelines from '../campaigns/create/Pipelines';
import { Guard } from 'hooks/useGuard';
import WatchedValue, {
  FormDirtyListener
} from 'components/wizard/WatchedValue';
import { Campaign } from 'apis/flex/recruitment';

export default () => {
  const { campaignId } = useParams();
  return (
    <DomainDetail<Campaign>
      domain="recruitment-campaign"
      loadMutator={data => {
        return {
          ...data,
          campaignDates: [data.startDate, data.endDate],
          hourlyRate: [data.minHourlyRate, data.maxHourlyRate],
          annualSalary: [data.salaryMin, data.salaryMax],
          asap: !data.jobStartDate
        };
      }}
      beforeSave={(vals, done) => {
        const data = vals;
        if (data.campaignDates) {
          data.startDate = data.campaignDates[0];
          data.endDate = data.campaignDates[1];
        }
        if (data.annualSalary && data.annualSalary[1]) {
          data.salaryMin = data.annualSalary[0];
          data.salaryMax = data.annualSalary[1];
        }
        if (data.hourlyRate && data.hourlyRate[1]) {
          data.minHourlyRate = data.hourlyRate[0];
          data.maxHourlyRate = data.hourlyRate[1];
        }
        done();
      }}
      defaultValues={{
        name: '',
        description: '',
        jobTitleId: null,
        advertTitle: '',
        advertDescription: '',
        jobStartDate: null,
        salaryMin: null,
        salaryMax: null,
        minHourlyRate: null,
        maxHourlyRate: null,
        isRemote: false,
        formId: undefined,
        isActive: true,
        startDate: new Date(),
        endDate: new Date(new Date().setDate(new Date().getDate() + 30)),
        isPrivate: false,
        allowRepeats: false,
        allowRepeatsAfter: 30,
        allowDuplicates: false,
        asap: true
      }}
      description={
        <>
          Set up a recruitment campaign to fill a position. Create and share an
          application form, then sit back and watch the applicants come in!
        </>
      }
      sidebar={<CampaignSidebar />}
    >
      {campaignId && (
        <Col xs={12}>
          <Row className="g-3">
            <Col xs={12}>
              <ShareCampaign campaignId={campaignId} />
            </Col>
            <Col>
              <CampaignReportTiles campaignId={Number(campaignId)} />
            </Col>
            <Col xs={12}>
              <ApplicantsMissing campaignId={Number(campaignId)} />
            </Col>
            <Col xs={12}>
              <ApplicantsTable campaignId={Number(campaignId)} />
            </Col>
          </Row>
        </Col>
      )}
      <Guard roles={['recruitment-campaign.edit']} itemIds={[campaignId]}>
        <DetailCard
          id="campaign-info"
          title="Campaign information"
          data-tour="campaign-information"
        >
          <CampaignInformationForm />
        </DetailCard>
        <DetailCard id="job-info" data-tour="job-info" title="Job information">
          <JobInformation />
        </DetailCard>
        <DetailCard
          id="stages"
          title="Stages"
          subtitle={
            <>
              Stages are a way to group applicants, and/or progress them through
              the application process.
              <br />
              Each stage can have a task associated with it, which applicants
              will be asked to complete, or it can simply be used internally to
              group applicants.
              <br />
              Once a stage is created here, you will be able to place applicants
              into it, and follow their progress. You can use stages to ask
              applicants further questions, to place them into groups, or to
              manage multi-stage recruitment processes.
            </>
          }
        >
          <Stages />
        </DetailCard>
        <DetailCard
          id="pipelines"
          title="Pipelines"
          subtitle={
            <>
              <b>Automate recruitment with pipelines!</b> Here you can define
              some rules that will be run whenever someone applies of fills in a
              form for one of the campaign stages. You can automatically accept,
              reject, or move an applicant to a different stage depending on
              their responses!
            </>
          }
        >
          <Pipelines />
        </DetailCard>
      </Guard>
    </DomainDetail>
  );
};
const CampaignReportTiles = ({ campaignId }) => {
  return (
    <DomainReportTiles
      domain="recruitment-campaign"
      location="view-campaign"
      itemId={campaignId}
      simple
      colProps={{ md: 6, xl: 3 }}
      className="g-3"
      data={useWatch()}
    />
  );
};
