import React, { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { Button, Dropdown, Modal, Spinner, SplitButton } from 'react-bootstrap';
import { FormProvider } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';
import PropTypes from 'prop-types';
import { useUser } from 'hooks/useUser';
import { ResponsiveModal } from 'components/common/Modals';
import useMediaForm from 'hooks/useMediaForm';
import { Ticket } from 'apis/flex/support';
import useTickets from 'components/app/support/useTickets';
import {
  ticketPriorities,
  ticketTypes
} from 'components/app/support/TicketDetail';
import { truncate } from 'lodash';
const FeedbackModal = ({ type: defaultType = '', show, setShow }) => {
  //as a..
  //i want...
  //so that..
  const user = useUser();
  const defaults = {
    name: user.firstName + ' ' + user.surname,
    email: user.email,
    type: defaultType,
    currentPage: truncate(window.location.href, { length: 500 }),
    notifyCreator: true,
    comments: []
  };
  const methods = useMediaForm<
    Ticket,
    any,
    Ticket & { email: string; name: string; comments: string }
  >({
    defaultValues: defaults
  });
  useEffect(() => {
    methods.resetField('type', { defaultValue: defaultType });
  }, [defaultType]);
  const type = methods.watch('type');
  const instructions = {
    'Bug/error':
      'Please be as detailed as possible. Let us know what pages you were on, what you clicked or typed, what you expected to happen, and what actually happened...',
    'Feature request':
      'Please be as detailed as possible. What would you like the app to do? What end goal do you want to acheive?',
    Other: 'Please be as detailed as possible...'
  };
  const { add, isAdding } = useTickets();
  const handleHide = () => setShow(false);
  const handleSubmit = another => {
    return methods.handleSubmit(data => {
      const eventId = Sentry.captureMessage('User Feedback');
      const eventInfo =
        '<a href="https://teamsearch.sentry.io/issues/4931149729/events/' +
        eventId +
        '">Sentry event</a>';
      const sentryFeedback = {
        event_id: eventId,
        eventInfo:
          '<a href="https://teamsearch.sentry.io/issues/4931149729/events/' +
          eventId +
          '">Sentry event</a>',
        name: data.name,
        email: data.email,
        comments: data.comments
      };
      Sentry.captureUserFeedback(sentryFeedback);
      add(
        { ...data, eventInfo },
        {
          onSuccess: () => {
            !another && handleHide();
            methods.reset(defaults);
          }
        },
        true
      );
    }, console.error);
  };
  // useEffect(() => {
  //   if (type) {
  //     methods.setFocus('comments');
  //   }
  // }, [type, show]);
  return (
    <ResponsiveModal show={show} onHide={handleHide}>
      <FormProvider {...methods}>
        <Modal.Header closeButton>
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WizardInput
            type="radio"
            options={ticketTypes}
            name="type"
            hideLabel
          />
          <WizardInput
            type="select"
            name="priority"
            registerProps={{ required: false }}
            options={ticketPriorities}
          />
          {type && (
            <WizardInput
              type="textarea"
              name="description"
              label="Feedback"
              // formControlProps={{ autoFocus: true }}
              instruction={instructions[type]}
            />
          )}
          <WizardInput
            type="checkbox"
            name="notifyCreator"
            label="Keep me updated"
            instruction="You'll get updates on this issue as the development team works on it"
            registerProps={{ required: false }}
          />
        </Modal.Body>
      </FormProvider>
      <Modal.Footer>
        <Button variant="link" onClick={handleHide}>
          Cancel
        </Button>
        <SplitButton
          disabled={isAdding}
          onClick={handleSubmit(false)}
          title={
            <div className="d-flex align-items-center gap-2">
              {isAdding && <Spinner as="span" size="sm" />}Submit
            </div>
          }
        >
          <Dropdown.Item onClick={handleSubmit(true)}>
            Submit and add another
          </Dropdown.Item>
        </SplitButton>
      </Modal.Footer>
    </ResponsiveModal>
  );
};
FeedbackModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  type: PropTypes.string
};
export default FeedbackModal;
