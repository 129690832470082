import React, { useEffect } from 'react';
import Calendar, { CalendarProps } from './Calendar';
import useCalendarItems from './useCalendarItems';
import { EventInput } from '@fullcalendar/core';

export default ({
  calendarIds,
  config,
  ...rest
}: { calendarIds: number[] } & CalendarProps) => {
  const [dates, setDates] = React.useState<{ start: ''; end: '' }>();
  const handleDatesSet = ({ startStr, endStr }) => {
    setDates({ start: startStr, end: endStr });
  };
  const {
    data: events,
    isLoading,
    addAsync,
    updateAsync,
    removeAsync
  } = useCalendarItems({
    select: d =>
      d.map(
        (e): EventInput => ({
          ...e,
          id: e.id.toString(),
          url: e.url || '',
          color: null,
          className: 'event-bg-soft-' + e.color,
          extendedProps: { ...e }
        })
      ),
    filters: [
      { first: 'calendarId', second: calendarIds },
      {
        first: 'start',
        comparitor: '>',
        second: dates?.start
      },
      {
        first: 'start',
        comparitor: '<',
        second: dates?.end
      }
    ],
    useFilter: !!calendarIds?.length && !!dates
  });
  const handleAdd = e => {
    addAsync(e);
  };
  const handleUpdate = e => {
    updateAsync({ id: e.id, data: e });
  };
  const handleRemove = e => {
    removeAsync(e.id);
  };

  return (
    <>
      <Calendar
        isLoading={isLoading}
        config={{
          ...config,
          datesSet: handleDatesSet,
          onAdd: handleAdd,
          onChange: handleUpdate,
          onDelete: handleRemove,
          defaultValues: { calendarId: calendarIds?.[0] }
        }}
        events={events}
        {...rest}
      />
    </>
  );
};
