import DetailPage from 'components/common/detail/DetailPage';
import React from 'react';
import useResourceGroups from './useResourceGroups';
import DomainDetail from 'components/common/DomainDetail';
import DetailCard from 'components/common/detail/DetailCard';
import WizardInput from 'components/wizard/WizardInput';
import { Col, Row } from 'react-bootstrap';
import ResourceGroupPlanner from '../resourcing/ResourceGroupPlanner';
import { useParams } from 'react-router-dom';

export default () => {
  const { resourceGroupId } = useParams();
  return (
    <DomainDetail domain="resource-group" crudHook={useResourceGroups}>
      <DetailCard id="planner" title="Planner">
        <ResourceGroupPlanner resourceGroupId={Number(resourceGroupId)} />
      </DetailCard>
      <DetailCard title="Info" id="info">
        <Row>
          <Col md={2}>
            <WizardInput name="icon" type="icon" />
          </Col>
          <Col md={10}>
            <WizardInput name="name" />
          </Col>
        </Row>
        <WizardInput
          type="radio"
          name="unitsOfResource"
          label="Units"
          instruction="How resource is allocated. Projects will say 'I want x amount of these' and employees, suppliers etc. will say 'I can give you x amount of these'"
          options={['shifts', 'hours', 'interviews']}
        />
        <WizardInput
          registerProps={{ required: false }}
          name="description"
          type="textarea"
        />
      </DetailCard>
    </DomainDetail>
  );
};
