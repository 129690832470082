import DomainTable from 'components/common/DomainTable';
import React from 'react';
import { Badge } from 'react-bootstrap';
import useCalendars from './useCalendars';

export default () => {
  return (
    <DomainTable
      crudHook={useCalendars}
      columns={[
        'name',
        {
          id: 'color',
          formatter: v => <Badge bg={v()}>{v()}</Badge>
        }
      ]}
      domain="calendar"
    />
  );
};
