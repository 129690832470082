import { useEffect, useState } from "react";
export default ({
  onInterval,
  onClear,
  intervalMs = 1e3,
  clearAfterMs
}) => {
  const [intervalId, setIntervalId] = useState();
  const [start, setStart] = useState();
  useEffect(() => {
    setStart(Date.now());
  }, []);
  useEffect(() => {
    if (!start) return;
    const interval = setInterval(() => {
      const now = Date.now();
      onInterval(now - start);
      console.log("interval running", now - start, clearAfterMs);
      if (clearAfterMs && now - start > clearAfterMs) {
        console.log("clearing interval");
        clearInterval(interval);
        onClear?.();
      }
    }, intervalMs);
    setIntervalId(interval);
    return () => clearInterval(interval);
  }, [start]);
  console.log("interval", intervalId);
  return {
    clear: () => clearInterval(intervalId)
  };
};
