import DomainTable from 'components/common/DomainTable';
import React from 'react';
import useShifts from './useShifts';
import {
  AvailabilityRulesForm,
  convertShiftRulesFromForm,
  convertShiftRulesToForm,
  FormAvailabilityRule
} from './AvailabilityRules';
import useResourceGroups from 'components/app/pm/projects/resourceGroups/useResourceGroups';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { weekdays } from 'helpers/dates';

export default () => {
  const { data, isLoading } = useResourceGroups({
    useFilter: true,
    select: d =>
      d.map(r => ({
        label: r.name,
        icon: r.icon,
        crudFilter: { resourceGroupId: r.id }
      }))
  });
  return (
    <DomainTable
      isLoading={isLoading}
      tabs={data}
      crudHook={useShifts}
      domain="shift"
      title="Shifts"
      columns={[
        { id: 'resourceGroupId', domain: 'resource-group', visible: false },
        'startTime',
        'endTime',
        {
          id: 'availabilityRules',
          formatter: v => v()?.length,
          accessorFn: d => convertShiftRulesToForm(d?.availabilityRules),
          editor: ({ inputProps }) => {
            return <AvailabilityRulesForm {...inputProps} />;
          }
        },
        { id: 'createdBy', domain: 'user' }
      ]}
      // editorProps={{
      //   saveMutator: d => ({
      //     ...d,
      //     availabilityRules: convertShiftRulesFromForm(
      //       d.id,
      //       d?.availabilityRules as unknown as FormAvailabilityRule[]
      //     )
      //   }),
      //   loadMutator: d => ({
      //     ...d,
      //     availabilityRules: convertShiftRulesToForm(d.availabilityRules)
      //   })
      // }}
      // editFields={[
      //   'startTime',
      //   'endTime',
      //   'resourceGroupId',
      //   'availabilityRules'
      // ]}
    />
  );
};
