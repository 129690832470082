import React, { useRef } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';
import ThisPolicy from './ThisPolicy';
import ThirdParties from './ThirdParties';
import YourRights from './YourRights';
import General from './General';
import YourData from './YourData';

const PrivacyPolicy = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });
  const Link = ({ index, children }) => {
    return (
      <Nav.Item>
        <Nav.Link
          href={'#' + index}
          className="nav-link px-0 py-1"
          active={activeSection === Number(index)}
        >
          {children}
        </Nav.Link>
      </Nav.Item>
    );
  };
  return (
    <>
      <PageHeader
        title="Privacy policy"
        // description="A privacy policy is a statement or legal document that discloses some or all of the ways a party gathers, uses, discloses, and manages a customer or client's data."
        className="mb-3"
      />
      <Row className="g-0">
        <Col lg={8} className="pe-lg-2 order-1 order-lg-0">
          <div id="0" ref={sectionRefs[0]}>
            <ThisPolicy />
          </div>
          <div id="1" ref={sectionRefs[1]}>
            <YourData />
          </div>
          <div id="2" ref={sectionRefs[2]}>
            <ThirdParties />
          </div>
          <div id="3" ref={sectionRefs[3]}>
            <YourRights />
          </div>
          <div id="4" ref={sectionRefs[4]}>
            <General />
          </div>
          {/* <YourData id="1" ref={sectionRefs[1]} />
          <ThirdParties id="2" ref={sectionRefs[2]} />
          <YourRights id="3" ref={sectionRefs[3]} />
          <General id="4" ref={sectionRefs[4]} /> */}
        </Col>
        <Col lg={4} className="ps-lg-2 mb-3">
          <div className="sticky-sidebar">
            <Card className="sticky-top">
              <Card.Header className="border-bottom">
                <h6 className="mb-0 fs-0">On this page</h6>
              </Card.Header>
              <Card.Body>
                <div
                  id="terms-sidebar"
                  className="terms-sidebar nav flex-column fs--1"
                >
                  <Link index="0">This Policy</Link>
                  <Link index="1">Your Data</Link>
                  <Link index="2">Third Parties</Link>
                  <Link index="3">Your Rights</Link>
                  <Link index="4">General</Link>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PrivacyPolicy;
