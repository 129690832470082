import api from ".";
import CrudApi from "./CrudApi";
const base = "/users/";
export const usersApi = new CrudApi("/users");
export const checkUserEmails = (email) => api.post("/users/email-check", { email }).then((d) => d.data);
export const getUserGroups = ({
  filters,
  id
}) => api.get(base + "groups/" + (id || ""), { params: { filters } }).then((d) => d.data);
export const updateUserGroup = (id, data) => api.patch(base + "groups/" + id, { data }).then((d) => d.data);
export const addUserGroup = (data) => api.post(base + "groups/", { data }).then((d) => d.data);
export const updateUserMemberships = (userId, groupIds) => api.put(base + "/" + userId + "/memberships", { groupIds });
export const updateGroupMemberships = (groupId, userIds) => api.put(base + "groups/" + groupId + "/memberships", { userIds });
export const userAccessGroupApi = new CrudApi(base + "access");
export const accessDelegationApi = {
  user: new CrudApi(base + "access/delegation/user"),
  item: new CrudApi(base + "access/delegation/item")
};
export const getDomainUserAccess = (domain, itemId) => {
  return api.get(base + "domain-access/" + domain + "/" + itemId).then((d) => d.data);
};
export const userPreferenceApi = new CrudApi(
  base + "preferences"
);
