import {
  addDays,
  format,
  setHours,
  setMinutes,
  startOfISOWeek
} from "date-fns";
export const getDateTime = (date, time) => {
  const timestring = typeof time === "string" && time.length < 9 ? time : new Date(time).getFullYear() > 1970 ? new Date(time).toLocaleTimeString() : (/* @__PURE__ */ new Date(
    format(new Date(date), "yyyy-MM-dd") + "T" + time.split("T")[1]
  )).toLocaleTimeString();
  return setMinutes(
    setHours(new Date(date), Number(timestring.toString().split(":")[0])),
    time.toString().split(":")[1]
  );
};
export const formatDateToISO = (_date) => {
  const date = new Date(_date);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
export const getTime = (time, timeFormat = "HH:mm") => {
  if (!time) return "00:00";
  const datetime = getDateTime(/* @__PURE__ */ new Date(), time);
  return format(datetime, timeFormat);
};
export const getStartOfWeek = (date) => startOfISOWeek(new Date(date));
export const getWeekdayIndex = (date) => {
  const day = new Date(date).getDay();
  return day === 0 ? 6 : day - 1;
};
export const weekdaysIn = (date) => Array(7).fill(0).map((x, i) => {
  const dte = addDays(startOfISOWeek(new Date(date)), i);
  return dte;
});
export const getWeekday = (date) => weekdays[getWeekdayIndex(date)];
export const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
];
export const getTimeOfDay = (date = /* @__PURE__ */ new Date()) => {
  const now = new Date(date);
  const hour = now.getHours();
  return hour < 12 ? "morning" : hour < 17 ? "afternoon" : "evening";
};
export const timeToNumber = (s) => parseInt(s.split(":")[0]) * 60 + parseInt(s.split(":")[1]);
