import React, { ReactNode } from 'react';
import DetailPage from 'components/common/detail/DetailPage';
import useTrainingCourses from '../../hooks/useTrainingCourses';
import DetailCard from 'components/common/detail/DetailCard';
import WizardInput from 'components/wizard/WizardInput';
import { useFieldArray, useWatch } from 'react-hook-form';
import FormPicker from 'components/common/customForms/widgets/FormPicker';
import { useNavigate, useParams } from 'react-router-dom';
import { TrainingCourse, TrainingResource } from 'apis/flex/hr';
import { Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import InputConfig from 'components/wizard/InputConfig';
import VideoUploader from 'components/wizard/inputs/VideoUploader';
import { useGuard } from 'hooks/useGuard';
import TrainingCoursePicker from '../../widgets/TrainingCoursePicker';
import TrainingAttempts from '../TrainingAttempts';
import CustomTabs from 'components/common/CustomTabs';
import useCustomForm from 'components/common/customForms/hooks.js/useCustomForm';
import { Form } from 'apis/flex/customForms';
import { validateIframeEmbedCode } from 'helpers/strings';
const WithChosenForm = ({
  index,
  children
}: {
  index: number;
  children: (form: Form, isLoading: boolean) => ReactNode;
}) => {
  const formId = useWatch({ name: `resources.${index}.formId` });
  const { form, isLoading } = useCustomForm({ formId });
  console.log('with chosen form', form, isLoading);
  return children(form, isLoading);
};
const Resource = ({ index, onRemove }) => {
  const type = useWatch({ name: `resources.${index}.type` });
  const confirmationTypes = useWatch({
    name: `resources.${index}.confirmationTypes`
  });
  return (
    <div className="border mb-2 p-3 position-relative rounded-3 pt-4 card">
      <Row>
        <Col xs={12} md={12} xxl={4}>
          <WizardInput name={`resources.${index}.name`} label="Name" />
        </Col>
      </Row>
      <>
        <Row>
          <Col xs={12} lg={6}>
            <WizardInput
              name={`resources.${index}.type`}
              label="Resource"
              instruction={'Will be presented to the trainee to review'}
              type="select"
              options={['Video', 'File', 'URL', 'Embed']}
            />
          </Col>
          <Col xs={12} lg={6} className="align-content-end">
            {type === 'Video' && (
              <VideoUploader
                label="Video"
                hideLabel
                breakpoint="xs"
                required={type === 'Video'}
                fileIdField={`resources.${index}.videoId`}
                urlField={`resources.${index}.videoUrl`}
              />
            )}
            {type === 'File' && (
              <WizardInput
                hideLabel
                name={`resources.${index}.fileId`}
                label="File"
                type="file"
                formControlProps={{ height: 60 }}
              />
            )}
            {type === 'URL' && (
              <>
                <WizardInput
                  hideLabel
                  name={`resources.${index}.url`}
                  label="URL"
                />
              </>
            )}
            {type === 'Embed' && (
              <>
                <WizardInput
                  hideLabel
                  name={`resources.${index}.embedCode`}
                  label="Embed code"
                  type="textarea"
                  placeholder="Paste the embed code here"
                  formControlProps={{
                    onClick: e => {
                      e.target.focus();
                      e.target.select();
                    }
                  }}
                  registerProps={{
                    validate: v =>
                      validateIframeEmbedCode(v) ||
                      'It looks like that embed code is not valid. Please check and try again.'
                  }}
                />
              </>
            )}
          </Col>
          <Col xs={12}>
            <WizardInput
              name={`resources.${index}.description`}
              label="Description"
              registerProps={{ required: false }}
              type="texteditor"
              formControlProps={{ height: 160 }}
            />
          </Col>
        </Row>
        <Row className="w-100">
          <Col xs={12} md={6}>
            <WizardInput
              name={`resources.${index}.confirmationTypes`}
              label="Confirmation type"
              instruction={
                'How to assess whether the trainee has passed this section'
              }
              type="select"
              multiple
              options={['Signature', 'Quiz', 'Checkbox']}
            />
          </Col>
          <Col xs={12}>
            {confirmationTypes?.includes('Quiz') && (
              <>
                <FormPicker
                  instruction={
                    <>
                      If you choose a form with screening logic, that will be
                      used to automatically decide whether the trainee can pass
                      the module.
                    </>
                  }
                  name={`resources.${index}.formId`}
                  label="Quiz"
                />
                <WithChosenForm index={index}>
                  {form => {
                    const hasScreening = form?.sections?.some(s =>
                      s.questions?.some(q => q.screening?.length)
                    );
                    console.log('form has screening', hasScreening);
                    return (
                      form && (
                        <WizardInput
                          disabled={!hasScreening}
                          name={`resources.${index}.allowedAttempts`}
                          type="number"
                          formControlProps={{ min: 1 }}
                          label="Allowed attempts"
                          instruction={
                            'If the form used for the quiz has screening logic, how many times can the trainee re-attempt it'
                          }
                        />
                      )
                    );
                  }}
                </WithChosenForm>
              </>
            )}
            {(confirmationTypes?.includes('Checkbox') ||
              confirmationTypes?.includes('Signature')) && (
              <WizardInput
                name={`resources.${index}.confirmationText`}
                label="Confirmation text"
                pluginProps={{ height: '10rem', basic: true }}
                type="texteditor"
              />
            )}
            <WizardInput
              name={`resources.${index}.autoApprove`}
              label="Auto approve"
              registerProps={{ required: false }}
              instruction={
                'If turned off, this module will need to be approved manually by a manager before being marked as complete'
              }
              type="switch"
            />
          </Col>
        </Row>
      </>
      <Button
        variant="falcon-danger"
        size="sm"
        className="end-0 m-2 position-absolute top-0"
        onClick={onRemove}
      >
        <FontAwesomeIcon icon="trash" />
      </Button>
    </div>
  );
};
const ResourceEditor = ({ defaultResource }) => {
  const { fields, append, remove, move } = useFieldArray<
    {
      id: string;
      resources: TrainingResource[];
    },
    'resources'
  >({ name: 'resources' });
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    move(result.source.index, result.destination.index);
  };
  const handleAdd = () => {
    const index = fields.length;
    append({ ...defaultResource, index });
  };
  const handleRemove = (i: number) => () => {
    remove(i);
  };
  return (
    // <Col xs={12}>
    <DragDropContext onDragEnd={onDragEnd}>
      <CustomTabs
        droppable
        items={fields?.map((r, i) => ({
          id: r.id,
          index: r.index,
          formField: `resources.${i}`,
          title: v => v.name,
          content: <Resource index={i} onRemove={handleRemove(i)} />,
          draggable: true
        }))}
        addBtn={{
          fn: handleAdd
        }}
      />
    </DragDropContext>
    // </Col>
  );
};
const EditCourse = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const { data, isLoading, upsert, isUpserting } =
    useTrainingCourses<TrainingCourse>({
      id,
      select: d => d[0]
    });
  const { canEdit } = useGuard({
    roles: ['training-course'],
    itemIds: [Number(id)]
  });
  const defaultResource = {
    index: 0,
    name: '',
    type: '',
    videoId: '',
    formId: '',
    allowedAttempts: 1,
    confirmationTypes: [],
    url: '',
    confirmationText:
      'I confirm I have read and understood the information presented herein.',
    fileId: '',
    videoUrl: '',
    autoApprove: true
  };
  return (
    <>
      <InputConfig disabled={!canEdit}>
        <DetailPage
          data={data}
          domain="training-course"
          isLoading={!!id && isLoading}
          title={data?.name || 'New Training Course'}
          background={{
            fieldName: 'profileBackground',
            disabled: !canEdit
          }}
          saveMutator={d => ({
            ...d,
            prerequisites: d?.prerequisites?.map(p => ({ prerequisiteId: p }))
          })}
          loadMutator={d => ({
            ...d,
            prerequisites: d?.prerequisites?.map(p => p.prerequisiteId) || []
          })}
          onSave={upsert}
          isSaving={isUpserting}
          afterSave={() => nav('/hr/training/courses')}
          defaultValues={{
            isPublic: false,
            enforceOrder: true,
            prerequesites: [],
            resources: [defaultResource]
          }}
          sidebar={
            <>
              <WizardInput
                type="switch"
                label="Public"
                name="isPublic"
                registerProps={{ required: false }}
              />
              <WizardInput
                type="switch"
                label="Enforce module order"
                instruction={
                  "If selected, trainees can't skip modules. If they fail one, they won't be able to progress"
                }
                name="enforceOrder"
                registerProps={{ required: false }}
              />
              <TrainingCoursePicker
                name={`prerequisites`}
                label="Prerequisites"
                multiple
                instruction="Select the training courses that must be completed before this course can be taken."
                registerProps={{ required: false }}
                filter={t => t.id !== Number(id)}
              />
            </>
          }
        >
          {id && (
            <Col xs={12}>
              <TrainingAttempts courseId={id && Number(id)} />
            </Col>
          )}
          <DetailCard id="course-info" title="Course Information">
            <WizardInput name="name" label="Course Title" />
            <WizardInput
              name="description"
              label="Course Description"
              type="texteditor"
              registerProps={{ required: false }}
            />
            {/* <WizardInput name="categoryId" type="select" options={[]} /> */}
          </DetailCard>
          <DetailCard
            id="modules"
            title="Modules"
            subtitle={`
            Trainees will be shown the resource for the module, asked to review it, then must confirm that they have understood it, either by a signature, checkbox, or by passing a quiz.
            Module resources can be a file, document, video or anything available online.`}
          >
            <ResourceEditor defaultResource={defaultResource} />
          </DetailCard>
        </DetailPage>
      </InputConfig>
    </>
  );
};
export default EditCourse;
