import classNames from 'classnames';
import React from 'react';
import { Spinner } from 'react-bootstrap';

export default ({ message = 'Loading...', className = '' }) => {
  return (
    <div>
      <div
        className={classNames(
          className,
          'position-absolute top-0 start-0 h-100 w-100 bg-light opacity-75'
        )}
      ></div>
      <div
        className={classNames(
          'position-absolute top-0 start-0 h-100 w-100 d-flex flex-column justify-content-center align-items-center'
        )}
        style={{ minHeight: '300px' }}
      >
        <Spinner />
        <h6 className="mt-1">{message}</h6>
      </div>
    </div>
  );
};
