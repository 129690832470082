import React from 'react';
import FixedShiftsTable from './FixedSchedulesTable';

export default () => {
  return (
    <>
      <FixedShiftsTable />
    </>
  );
};
