import DetailPage from 'components/common/detail/DetailPage';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTickets, { useClosedTickets, useOpenTickets } from './useTickets';
import DetailCard from 'components/common/detail/DetailCard';
import WizardInput from 'components/wizard/WizardInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import LoadingButton from 'components/common/LoadingButton';
import useTicketComments from './useTicketComments';
import useMediaForm from 'hooks/useMediaForm';
import { FormProvider, useWatch } from 'react-hook-form';
import IconButton from 'components/common/IconButton';
import { Ticket, TicketComment } from 'apis/flex/support';
import TicketComments from './TicketComments';
import UserSelector from '../users/widgets/selector/UserSelector';
import InputConfig from 'components/wizard/InputConfig';
import { Guard } from 'hooks/useGuard';
import TagSelector from 'components/common/TagSelector';
const TicketReply = ({ ticket }: { ticket: Ticket }) => {
  const [showForm, setShowForm] = useState(false);
  const { add, isAdding } = useTicketComments<TicketComment>({});
  const methods = useMediaForm<TicketComment>();
  return (
    <FormProvider {...methods}>
      {showForm && (
        <div className="my-3 text-start">
          <h5 className="mb-3">Reply</h5>
          <WizardInput type="texteditor" name="comment" hideLabel />
          <WizardInput
            type="file"
            multiple
            name="files"
            registerProps={{ required: false }}
            label="Attachments"
          />
          <div className="text-end p-2">
            <Button
              size="sm"
              variant="link"
              className="me-2"
              onClick={() => {
                setShowForm(false);
                methods.reset({});
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isAdding}
              onClick={methods.handleSubmit(vals => {
                add(
                  {
                    ticketId: ticket?.id,
                    ...vals
                  },
                  {
                    onSuccess: () => {
                      methods.reset({});
                      setShowForm(false);
                    }
                  }
                );
              }, console.error)}
              size="sm"
              variant="falcon-primary"
            >
              Send
            </LoadingButton>
          </div>
        </div>
      )}
      {!showForm && (
        <IconButton
          size="sm"
          variant="falcon-default"
          icon="reply"
          onClick={() => setShowForm(true)}
        >
          <span className="d-none d-sm-inline-block ms-1">Reply</span>
        </IconButton>
      )}
    </FormProvider>
  );
};
export const ticketTypes = ['Feature request', 'Bug/error', 'Other'];
export const ticketPriorities = ['Low', 'Medium', 'High', 'Emergency'];
const TicketCommonForm = () => {
  return (
    <>
      <WizardInput
        type="select"
        name="priority"
        registerProps={{ required: false }}
        options={ticketPriorities}
      />
      <WizardInput type="select" name="type" options={ticketTypes} />
    </>
  );
};
export const ticketStatusOptions = [
  { label: 'To do', value: 'To do' },
  { label: 'Working on it', value: 'Doing' },
  { label: 'Completed', value: 'Done' }
];
const Sidebar = () => {
  return (
    <Guard show roles={['ticket.edit']} itemIds={[useWatch({ name: 'id' })]}>
      <WizardInput type="text" name="title" />
      <UserSelector
        label="Support agent"
        name="agentId"
        registerProps={{ required: false }}
      />
      <TicketCommonForm />
      <WizardInput
        type="select"
        name="status"
        registerProps={{ required: false }}
        options={ticketStatusOptions}
      />
      <WizardInput
        registerProps={{ required: false }}
        type="checkbox"
        name="notifyCreator"
        label="Notify creator on updates"
      />
      <TagSelector tableName="Tickets" label="Tags" />
    </Guard>
  );
};
export default () => {
  const { id, status } = useParams();
  const {
    data: allTickets,
    isLoading,
    upsert,
    isUpserting
  } = (
    status === 'open'
      ? useOpenTickets
      : status === 'closed'
      ? useClosedTickets
      : useTickets
  )({
    useFilter: true
  });
  const nav = useNavigate();
  const dataIndex = allTickets?.findIndex(d => d.id === Number(id));
  const nextId = allTickets?.[dataIndex + 1]?.id;
  const prevId = allTickets?.[dataIndex - 1]?.id;
  const data = allTickets?.[dataIndex];
  return (
    <DetailPage
      allData={allTickets}
      tags={[
        {
          text: data?.status,
          bg:
            data?.status === 'Done'
              ? 'success'
              : data?.status === 'Doing'
              ? 'primary'
              : 'secondary'
        }
      ]}
      baseUrl={'/support/tickets/' + (status || '')}
      itemId={Number(id)}
      title={'Ticket #' + id}
      description={
        <div className="d-flex flex-column">
          <h6>
            Source page:{' '}
            <a target="_blank" rel="noreferrer" href={data?.currentPage}>
              {data?.currentPage}
            </a>
          </h6>
          <h6>
            Work item:{' '}
            {data?.workItemId ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://dev.azure.com/matt0580/Flex/_workitems/edit/${data?.workItemId}/`}
              >
                #{data?.workItemId}
              </a>
            ) : (
              'N/A'
            )}
          </h6>
          <h6>
            Event info:{' '}
            <span dangerouslySetInnerHTML={{ __html: data?.eventInfo }} />
          </h6>
        </div>
      }
      domain="ticket"
      isLoading={isLoading}
      isSaving={isUpserting}
      onSave={(vals, done) => upsert(vals, done)}
      afterSave={d => {
        if (!data?.id) {
          nav('/support/tickets/' + d.id);
        } else {
          nav('/support/tickets/' + (status ? status + '/' : '') + nextId);
        }
      }}
      sidebar={<Sidebar />}
    >
      <DetailCard
        id="comments"
        title={
          <h5 className="mb-0">
            <FontAwesomeIcon icon="envelope" className="me-2" />
            {data?.title}
          </h5>
        }
        buttons={<TicketReply ticket={data} />}
      >
        <TicketComments ticket={data} comments={data?.comments || []} />
      </DetailCard>
    </DetailPage>
  );
};
