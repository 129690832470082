import { faVial } from '@fortawesome/free-solid-svg-icons';
import { Campaign } from 'apis/flex/recruitment';
import {
  CustomQuestionRules,
  FieldArrayList
} from 'components/common/customForms/Editor/CustomFormQuestionEditor';
import useCustomForm from 'components/common/customForms/hooks.js/useCustomForm';
import WizardInput from 'components/wizard/WizardInput';
import React, { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import useStages from '../../applicants/hooks/useStages';
import Flex from 'components/common/Flex';
import StageForm from '../../stages/StageForm';
import SettingsBox from 'components/common/SettingsBox';
import { AcceptForm } from '../../applicants/AcceptApplicant';
import { CustomRule } from 'helpers/validation/validate';
import IconButton from 'components/common/IconButton';
import usePreviewWindow from 'hooks/usePreviewWindow';
import { RejectForm } from '../../applicants/RejectApplicant';
import Skeleton from 'react-loading-skeleton';

export type PipelineAction = {
  id: number;
  pipelineId: number;
  type: 'accept' | 'reject' | 'stage';
  stageId?: number;
  stage?: {
    sendEmail?: boolean;
    notificationTemplateId?: number;
    dueDate?: string;
  };
  accept?: {
    prescreening?: string[];
    contract?: any;
    managerId?: number;
    newStarterFormId?: number;
    trainingCourseIds?: number[];
    notificationTemplateId?: number;
  };
  reject?: {
    notificationTemplateId?: number;
  };
  isElseAction: boolean;
};
export type Pipeline = {
  id: number;
  campaignId: number;
  stageId?: number;
  stageFormId?: number;
  actions?: PipelineAction[];
  elseActions?: PipelineAction[];
  questionFilters?: CustomRule;
  trigger?: 'Application' | 'Stage';
  name: string;
};
const PipelineActionItem = ({
  name,
  otherActions,
  campaignId
}: {
  name: `pipelines.${number}.${string}`;
  otherActions: PipelineAction[];
  campaignId: number;
}) => {
  const type = useWatch({ name: `${name}.type` });
  const { data: stages, isLoading } = useStages({
    enabled: type === 'stage',
    select: d => d.map(s => ({ label: s.name, value: s.id })),
    filters: { campaignId },
    useFilter: !!campaignId
  });
  const hasSoloAction = otherActions.some(
    a => a.type === 'accept' || a.type === 'reject' || a.type === 'stage'
  );
  return (
    <div>
      <WizardInput
        name={`${name}.type`}
        type="select"
        label="Action"
        hideLabel
        flush
        options={[
          {
            label: 'Accept applicant',
            value: 'accept',
            isDisabled: hasSoloAction
          },
          {
            label: 'Reject applicant',
            value: 'reject',
            isDisabled: hasSoloAction
          },
          { label: 'Move to stage', value: 'stage', isDisabled: hasSoloAction }
        ]}
      />
      {type === 'stage' && (
        <Flex className="flex-wrap" direction="column">
          <WizardInput
            name={`${name}.stageId`}
            type="select"
            loading={isLoading}
            options={stages}
            label="Stage"
          />
          {isLoading ? (
            <Skeleton count={3} />
          ) : (
            <StageForm namePrefix={`${name}.stage.`} />
          )}
        </Flex>
      )}
      {isLoading ? (
        <>
          <Skeleton count={3} />
        </>
      ) : (
        <>
          {type === 'accept' && <AcceptForm name={`${name}.accept.`} />}
          {type === 'reject' && <RejectForm name={`${name}.reject.`} />}
        </>
      )}
    </div>
  );
};
const PipelineActions = ({
  name,
  pipelineId,
  campaignId,
  defaultValues
}: {
  name: `pipelines.${number}.${'actions' | 'elseActions'}`;
  pipelineId: number;
  campaignId: number;
  defaultValues?: Partial<PipelineAction>;
}) => {
  const { fields, append, remove } = useFieldArray<
    Campaign & { pipelines: Pipeline[] },
    typeof name
  >({
    name
  });
  return (
    <FieldArrayList
      fields={fields}
      defaultValues={{
        id: undefined,
        pipelineId,
        type: '',
        stageid: undefined,
        stage: {
          sendEmail: false,
          dueDate: null,
          notificationTemplateid: undefined
        },
        accept: {
          prescreening: ['Right to work', 'Identity'],
          contract: null,
          managerid: undefined,
          newStarterFormid: undefined,
          trainingCourseIds: []
        },
        ...defaultValues
      }}
      item={(field, index) => (
        <>
          <PipelineActionItem
            name={`${name}.${index}`}
            key={field.id}
            campaignId={campaignId}
            otherActions={fields.filter((f, i) => i !== index)}
          />
        </>
      )}
      remove={remove}
      append={f => append(f)}
    />
  );
};
const TestButton = ({ index, formId }: { index: number; formId: number }) => {
  const pipeline = useWatch({ name: 'pipelines.' + index });
  const p = usePreviewWindow({
    targetHref: '/previewer/form-pipeline/' + formId,
    targetStoreName: 'pipeline',
    data: pipeline
  });
  useEffect(() => {
    p.hide();
  }, [location]);
  return (
    <IconButton
      icon={faVial}
      variant="falcon-primary"
      onClick={() => (p.isShown ? p.hide() : p.show())}
    >
      Test
    </IconButton>
  );
};
const CampaignPipeline = ({
  campaignId,
  index
}: {
  campaignId: number;
  index: number;
}) => {
  const stageFormId = useWatch({ name: `pipelines.${index}.stageFormId` });
  const trigger = useWatch({ name: `pipelines.${index}.trigger` });
  const applicationFormId = useWatch({ name: `formId` });
  const { setValue } = useFormContext();
  const { form: applicationForm, isLoading: formLoading } = useCustomForm({
    formId: applicationFormId,
    enabled: trigger === 'Application',
    staleTime: 1000 * 60
  });
  const { data: stages, isFetching: stagesLoading } = useStages({
    enabled: trigger === 'Stage',
    filters: { campaignId },
    useFilter: !!campaignId
  });
  const stageOptions = stages?.map(s => ({ label: s.name, value: s.id }));
  const { form: stageForm, isLoading: stageFormLoading } = useCustomForm({
    formId: stageFormId,
    enabled: !!stageFormId
  });
  const isFormLoading =
    (stageFormLoading && trigger === 'Stage') ||
    (formLoading && trigger === 'Application');
  const form = useMemo(
    () => stageForm || applicationForm,
    [stageForm, applicationForm]
  );
  return (
    <>
      <WizardInput type="text" label="Name" name={`pipelines.${index}.name`} />
      <SettingsBox title="Trigger" description="When to apply the action">
        <WizardInput
          label="Trigger"
          type="select"
          hideLabel
          flush
          options={[
            { label: 'Application form submitted', value: 'Application' },
            { label: 'Stage form submitted', value: 'Stage' }
          ]}
          name={`pipelines.${index}.trigger`}
        />
        {trigger === 'Stage' && (
          <WizardInput
            label="Stage"
            type="select"
            loading={stagesLoading}
            options={stageOptions}
            name={`pipelines.${index}.stageId`}
            registerProps={{
              onChange: e => {
                setValue(
                  `pipelines.${index}.stageFormId`,
                  stages.find(s => s.id === e.target.value)?.formId,
                  { shouldDirty: true }
                );
              }
            }}
          />
        )}
        <WizardInput
          type="number"
          name={`pipelines.${index}.delayMins`}
          label="Delay (mins)"
          registerProps={{ required: false }}
          instruction={
            <>
              Useful if you don't want applicants to get multiple emails in
              quick succession, or if you want time to review manually first.
            </>
          }
        />
      </SettingsBox>
      <SettingsBox
        title="Filters"
        description="Only apply the action to certain form responses"
      >
        {isFormLoading ? (
          <Skeleton count={1} height={200} />
        ) : (
          !!form && (
            <>
              <CustomQuestionRules
                name={`pipelines.${index}.questionFilters`}
                questions={form.sections.flatMap(s => s.questions)}
              />
              <TestButton index={index} formId={form?.id} />
            </>
          )
        )}
      </SettingsBox>
      <ActionSettings index={index} campaignId={campaignId} />
    </>
  );
};
const ActionSettings = ({ index, campaignId }) => {
  const id = useWatch({ name: `pipelines.${index}.id` });
  const filters = useWatch({ name: `pipelines.${index}.questionFilters` });
  return (
    <>
      <SettingsBox
        title={'Actions' + (filters?.length ? ' if filters pass' : '')}
        description={
          'What to do with the applicant' +
          (filters?.length ? ' if they fulfil the above criteria' : '')
        }
      >
        <PipelineActions
          name={`pipelines.${index}.actions`}
          campaignId={campaignId}
          pipelineId={id}
          defaultValues={{ isElseAction: false }}
        />
      </SettingsBox>
      {filters?.length && (
        <SettingsBox
          title="Actions if filters fail"
          description={
            <>
              What to do with the applicant if they <b>do not</b> fulfil the
              above criteria
            </>
          }
        >
          <PipelineActions
            name={`pipelines.${index}.elseActions`}
            campaignId={campaignId}
            pipelineId={id}
            defaultValues={{ isElseAction: true }}
          />
        </SettingsBox>
      )}
    </>
  );
};

export default () => {
  const campaignId = useWatch({ name: 'id' });
  // const { getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray<
    Campaign & { pipelines: Pipeline[] },
    `pipelines`
  >({ name: 'pipelines' });
  // const addBtn = useMemo(
  //   () => ({
  //     label: 'Add',
  //     fn: () =>
  // append({
  //   id: undefined,
  //   campaignId,
  //   name: 'Pipeline #' + (fields.length + 1)
  // });
  //   }),
  //   [campaignId]
  // );
  // const items = useMemo(
  //   () =>
  //     fields.map((p, i) => ({
  //       id: p.id,
  //       formField: `pipelines`,
  //       title: (
  //         <>
  //           #{i + 1}
  //           <CardDropdown
  //             buttonClass="h-100"
  //             className="position-absolute top-0 end-0 h-100"
  //           >
  //             <Dropdown.Item onClick={() => remove(i)}>
  //               <FontAwesomeIcon icon={faTrash} className="me-2 text-danger" />
  //               Remove pipeline
  //             </Dropdown.Item>
  //             <Dropdown.Item
  //               onClick={() => append(getValues('pipelines.' + i))}
  //             >
  //               <FontAwesomeIcon icon={faCopy} className="me-2" />
  //               Duplicate pipeline
  //             </Dropdown.Item>
  //           </CardDropdown>
  //         </>
  //       ),
  //       content: () =>
  //     })),
  //   [fields]
  // );
  return (
    <FieldArrayList
      tabs
      defaultValues={{ name: '', isActive: true }}
      fields={fields}
      append={() =>
        append({
          id: undefined,
          campaignId,
          name: 'Pipeline #' + (fields.length + 1)
        })
      }
      remove={remove}
      item={(f, i) => <CampaignPipeline campaignId={campaignId} index={i} />}
    />
  );
  // return <CustomTabs addBtn={addBtn} items={items} />;
};
