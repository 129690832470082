import SoftBadge from 'components/common/SoftBadge';
import WizardInput from 'components/wizard/WizardInput';
import { camelToSentence } from 'helpers/utils';
import React from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import useShifts, {
  AvailableShiftConstraints,
  useAvailableShifts
} from './useShifts';
import useEmployees from '../staff/hooks/useEmployees';
import {
  formatDateToISO,
  getTime,
  getWeekdayIndex,
  timeToNumber,
  weekdays,
  weekdaysIn
} from 'helpers/dates';
import { addDays, areIntervalsOverlapping, format, isSameDay } from 'date-fns';
import { Shift, ShiftPreference } from 'apis/flex/hr';
import {
  getMaxShiftHours,
  getMinShiftHours,
  ShiftValue
} from 'helpers/validation/validate';
import { uniqBy } from 'lodash';
import WizardInputWrapper, {
  WizardInputWrapperProps
} from 'components/wizard/WizardInputWrapper';
import { BookingType } from 'apis/flex/projects';
import QueryStateDisplay from 'components/common/QueryStateDisplay';
import useShiftPreferences from './shiftPreferences/useShiftPreferences';
import { ShiftCalendar } from './shiftPreferences/ShiftCalendarInput';
import ShiftSelector, { AvailableShiftsSelector } from './ShiftSelector';
import { DomainItemSelectorProps } from 'components/common/DomainItemSelector';
import { ColProps } from '@fullcalendar/core/internal';

// const ShiftCheck = ({
//   shift,
//   name,
//   employeeId,
//   date,
//   resourceGroupId,
//   currentPreferences
// }: {
//   name: string;
//   shift: Shift;
//   employeeId: number;
//   date: Date;
//   resourceGroupId: number;
//   currentPreferences?: ShiftPreference[];
// }) => {
//   const { add, remove, isAdding, isRemoving } = useShiftPreferences();
//   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.checked) {
//       add({ shiftId: shift.id, employeeId, date, resourceGroupId });
//     } else {
//       const prefWithId = currentPreferences.find(
//         p =>
//           p.shiftId === shift.id &&
//           p.resourceGroupId === resourceGroupId &&
//           isSameDay(new Date(p.date), new Date(date)) &&
//           p.employeeId === employeeId
//       );
//       if (prefWithId) {
//         remove(prefWithId.id);
//       }
//     }
//   };
//   return (
//     <WizardInput
//       registerProps={{ required: false, onChange: handleChange }}
//       type={'checkbox'}
//       label={shift.startTime + '-' + shift.endTime}
//       loading={isAdding || isRemoving}
//       name={name + '.' + shift.id}
//     />
//   );
// };
export const getShiftName = (shift: {
  startTime: Date | string;
  endTime: Date | string;
}) => getTime(shift.startTime) + '-' + getTime(shift.endTime);
const ShiftWeekday = ({
  name,
  shifts,
  permitted,
  weekday,
  type
}: {
  name: string;
  shifts: Shift[];
  permitted: Shift[];
  weekday: string;
  type: BookingType;
}) => {
  const val = useWatch({ name });
  const options = shifts
    ?.map(s => ({
      label: getTime(s.startTime) + '-' + getTime(s.endTime),
      value: s.id,
      isDisabled: !permitted?.map(s => s.id).includes(s.id),
      description:
        !permitted?.map(s => s.id).includes(s.id) && 'No longer available'
    }))
    .filter(
      o => val?.includes(o.value) || !o.isDisabled //might show disabled shifts in some way at some point. For now cleaner not to
    );
  return (
    !!options?.length && (
      <Accordion.Item eventKey={weekday}>
        <Accordion.Header>
          {camelToSentence(weekday)}
          {!!val?.length && (
            <SoftBadge className="ms-2" bg={'primary'}>
              {val.length}
            </SoftBadge>
          )}
        </Accordion.Header>
        <Accordion.Body>
          {type === 'shifts' ? (
            <WizardInput
              registerProps={{ required: false }}
              type={'checkbox'}
              options={options}
              label={'Shifts'}
              multiple
              name={name}
            />
          ) : (
            <WizardInput
              type="number"
              label={camelToSentence(type)}
              name={name}
            />
          )}
        </Accordion.Body>
      </Accordion.Item>
    )
  );
};
export default ({
  weekCommencing,
  name,
  employeeId,
  minHours = 0,
  maxHours,
  label,
  type,
  resourceGroupId,
  ...rest
}: {
  weekCommencing: Date;
  name: string;
  employeeId: number;
  minHours?: number;
  maxHours?: number;
  resourceGroupId: number;
  type: BookingType;
} & Partial<Omit<WizardInputWrapperProps, 'type'>>) => {
  const { data: employee, isLoading: isEmployeeLoading } = useEmployees({
    id: employeeId,
    select: d => d[0]
  });
  const { data: shifts, isLoading: isShiftsLoading } = useShifts({
    filters: { resourceGroupId },
    includeDeleted: true,
    useFilter: true
  });
  const { data: permitted, isLoading: isPermittedLoading } = useAvailableShifts(
    {
      employeeIds: [employeeId],
      departmentIds: [employee?.departmentId],
      trainingIds: employee?.completedTrainingCourseIds,
      contractIds: [employee?.contractId],
      startDate: weekCommencing,
      endDate: addDays(weekCommencing, 6),
      resourceGroupId
    }
  );
  const dates = weekdaysIn(weekCommencing).map(
    dte => formatDateToISO(dte) as `${string}-${string}-${string}`
  );
  const shiftTypes = shifts
    ? uniqBy<Shift>(
        Object.keys(shifts).flatMap(d => shifts[d]),
        'id'
      )
    : [];
  const shiftLookup = new Map<number, Shift>(shiftTypes.map(s => [s.id, s]));
  return (
    <QueryStateDisplay
      isLoading={isEmployeeLoading || isShiftsLoading || isPermittedLoading}
    >
      <WizardInputWrapper
        id={name}
        name={name}
        label={label}
        hideLabel={!label}
        {...rest}
        controllerProps={{
          defaultValue: [],
          rules: {
            validate: (
              v: Record<`${string}-${string}-${string}`, number[] | number>
            ) => {
              if (type === 'shifts') {
                const selectedShifts = dates.reduce((a, dte) => {
                  if (typeof v[dte] === 'number') return a;
                  a[weekdays[getWeekdayIndex(dte)]] = (v[dte] as number[])?.map(
                    id =>
                      getTime(shiftLookup.get(id)?.startTime) +
                      '-' +
                      getTime(shiftLookup.get(id)?.endTime)
                  );
                  return a;
                }, {} as ShiftValue);
                const max = getMaxShiftHours(selectedShifts);
                const min = getMinShiftHours(selectedShifts);
                if (min < minHours)
                  return 'Minimum hours must be at least ' + minHours;

                // people can say they're available for more shifts than are possible
                // if (maxHours && max > maxHours)
                //   return 'Maximum hours must be at most ' + maxHours;
              }
              if (type === 'hours') {
                const selectedHours = dates.reduce((a, dte) => {
                  if (typeof v[dte] !== 'number') return a;
                  return a + Number(v[dte]);
                }, 0);
                if (selectedHours < minHours) {
                  return 'Minimum hours must be at least ' + minHours;
                }
                if (maxHours && selectedHours > maxHours) {
                  return 'Maximum hours must be at most ' + maxHours;
                }
              }
              return true;
            }
          }
        }}
      >
        {({ label, input, form }) => (
          <>
            {label}
            {input(
              <Accordion className="w-100">
                {dates.map(dte => (
                  <ShiftWeekday
                    key={dte}
                    weekday={weekdays[getWeekdayIndex(dte)]}
                    shifts={shifts}
                    permitted={permitted?.[dte]}
                    name={name + '.' + dte}
                    type={type}
                  />
                ))}
              </Accordion>
              // <ShiftCalendar
              //   shifts={shifts}
              //   permitted={permitted}
              //   weekCommencing={weekCommencing}
              //   {...form.field}
              // />
            )}
          </>
        )}
      </WizardInputWrapper>
    </QueryStateDisplay>
  );
};
const validateDayShifts =
  (shifts: Shift[], allowOverlap?: boolean) => (selected: number[]) => {
    if (!selected) return;
    const isOverlap = selected?.some(id => {
      const shift = shifts.find(s => s.id === id);
      const otherShifts = selected
        .filter(otherId => otherId !== id)
        .map(otherId => shifts.find(s => s.id === otherId));
      console.log(id, otherShifts, shift);
      return otherShifts.some(s =>
        areIntervalsOverlapping(
          {
            start: timeToNumber(s.startTime),
            end: timeToNumber(s.endTime)
          },
          {
            start: timeToNumber(shift?.startTime),
            end: timeToNumber(shift?.endTime)
          }
        )
      );
    });
    return allowOverlap || !isOverlap || 'Shifts cannot overlap';
  };
export const DayShiftsPicker = ({
  registerProps,
  allowOverlap,
  ...props
}: Partial<DomainItemSelectorProps<Shift>> & {
  allowOverlap?: boolean;
}) => {
  const { data: shifts } = useShifts({ useFilter: true });
  return (
    <ShiftSelector
      multiple
      registerProps={{
        validate: validateDayShifts(shifts, allowOverlap),
        ...registerProps
      }}
      {...props}
    />
  );
};
/** Will create a form value structure of {'2020-01-01': [shiftId, shiftId], '2020-01-02': [shiftId]} */
export const AvailableDayShiftsPicker = ({
  registerProps,
  availableTo,
  formatDate = date => format(new Date(date), 'EEEE dd/MMM'),
  colProps,
  allowOverlap,
  name,
  ...props
}: Partial<DomainItemSelectorProps<Shift>> & {
  availableTo: AvailableShiftConstraints;
  formatDate?: (date: Date) => string;
  colProps?: ColProps;
  allowOverlap?: boolean;
}) => {
  const { data: shifts } = useShifts({ useFilter: true });
  return (
    <Row>
      <AvailableShiftsSelector
        multiple
        name={name}
        render={(input, date) => (
          <Col key={date} xs={12} md={4} xl={3} {...colProps}>
            {input({ label: formatDate(new Date(date)) })}
          </Col>
        )}
        availableTo={availableTo}
        registerProps={{
          validate: validateDayShifts(shifts, allowOverlap),
          ...registerProps
        }}
        {...props}
      />
    </Row>
  );
};
