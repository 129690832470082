import React, { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import JobTitleSelect from 'components/app/users/widgets/selector/JobTitleSelect';
import { useFormContext, useWatch } from 'react-hook-form';
import WizardInput from 'components/wizard/WizardInput';

const JobInformation = () => {
  const { setValue, register } = useFormContext();
  const asap = useWatch({ name: 'asap' });
  return (
    <>
      <Row className="gx-2 gy-3">
        <Col md="6">
          <JobTitleSelect name="jobTitleId" label="Job title" />
        </Col>
        <Col md="6">
          <div className="d-flex flex-column justify-content-between mb-3">
            <Form.Label>Start date</Form.Label>
            <Row className="align-items-baseline">
              <Col md={'auto'}>
                <WizardInput
                  name="jobStartDate"
                  type="date"
                  disabled={asap}
                  hideLabel
                  registerProps={{
                    required: false,
                    validate: v => !v || new Date(v) > new Date()
                  }}
                />
              </Col>
              <Col md={3}>
                <Form.Check id="asap">
                  <Form.Check.Input
                    {...register('asap', {
                      required: false
                      // onChange: e => {
                      //   setValue('jobStartDate', '', {
                      //     shouldDirty: false
                      //   });
                      // }
                    })}
                    type="checkbox"
                  />
                  <Form.Check.Label>ASAP</Form.Check.Label>
                </Form.Check>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* <Card className="mb-3">
        <Card.Header as="h5">
          Job Advert
          <Card.Subtitle className="form-text mt-2 text-muted">
            Optional. Create a job advert that can be shared on social media.
          </Card.Subtitle>
        </Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col xs="12">
              <WizardInput
                name="advertTitle"
                registerProps={{ required: false }}
              />
            </Col>
            <Col xs="12">
              <AiFormAssistant
                enabled={!!jobTitleId}
                name="advertDescription"
                preprompt={`Create a job advert for a ${
                  jobTitles?.find(t => t.value === jobTitleId)?.label
                } position in a market research data collection company${
                  title ? ' with the title "' + title + '"' : ''
                }. Use full html markup to make the job advert look eye catching. Use "Poppins" font, and #344050 color for all text. Do not include links. Respond with just the HTML.`}
              >
                <WizardInput
                  name="advertDescription"
                  type="texteditor"
                  registerProps={{ required: false }}
                />
              </AiFormAssistant>
            </Col>
          </Row>
          <Row className="gx-0 gy-0">
            <Col xs="12">
              <WizardInput
                type="currencyrange"
                name="annualRate"
                registerProps={{
                  required: false,
                  min: 0,
                  validate: (v, d) =>
                    !d.hourlyRateMin ||
                    'Cannot specify both hourly and annual rates'
                }}
                label="Annual Salary"
              />
            </Col>
            <Divider>Or</Divider>
            <Col xs="12">
              <WizardInput
                type="currencyrange"
                name="hourlyRate"
                registerProps={{
                  required: false
                }}
                label="Hourly rate"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card> */}
    </>
  );
};

JobInformation.propTypes = {};

export default JobInformation;
