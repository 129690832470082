import {
  faFile,
  faFileArchive,
  faFileAudio,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord
} from "@fortawesome/free-solid-svg-icons";
import companyConfig from "companyConfig";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { isArray, isObject } from "lodash";
import React from "react";
dayjs.extend(duration);
export const isIterableArray = (array) => Array.isArray(array) && !!array.length;
export const countDecimalPlaces = (number) => {
  const numberString = number.toString();
  const match = numberString.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  return match ? Math.max(0, (match[1] || "").length) : 0;
};
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};
export const objectHasArrayProperty = (object = {}, dotNotatedString = "") => {
  const keys = dotNotatedString.split(".");
  const level = object[keys[0]];
  if (!level) {
    return void 0;
  }
  if (!Array.isArray(level) && keys[1] !== void 0) {
    return void 0;
  }
  return keys[1] === void 0 || level.includes(keys[1]);
};
export function getPropertyByDotNotation(obj, dotNotationString) {
  const keys = dotNotationString.split(".");
  return keys.reduce((acc, key) => {
    const index = parseInt(key, 10);
    if (!isNaN(index) && Array.isArray(acc)) {
      return acc[index];
    }
    return acc && acc[key];
  }, obj);
}
export function flattenObject(obj = {}, parentKey = "", result = {}) {
  for (const key in obj) {
    if (obj[key] !== void 0) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (isObject(obj[key]) && !isArray(obj[key])) {
        flattenObject(obj[key], newKey, result);
      } else {
        result[newKey] = obj[key];
      }
    }
  }
  return result;
}
export const moveArrayItem = (arr, sourceIndex, destIndex) => {
  const arrCopy = [...arr];
  arrCopy.splice(destIndex, 0, arrCopy.splice(sourceIndex, 1)[0]);
  return arrCopy;
};
export const objectWalker = (obj, onPrimitive, onEveryLevel, parentKey = "") => {
  for (const key in obj) {
    if (key in obj) {
      onEveryLevel?.(key, obj[key], parentKey);
      if (isObject(obj[key])) {
        objectWalker(
          obj[key],
          onPrimitive,
          onEveryLevel,
          (parentKey ? parentKey + "." : "") + key
        );
      } else {
        onPrimitive?.(key, obj[key], parentKey);
      }
    }
  }
};
export const flattenObjectIntoArrayOfDotNotationStrings = (obj) => {
  const result = [];
  objectWalker(obj, (key, value, p) => {
    result.push(`${p ? p + "." : ""}${key}`);
  });
  return result;
};
export function setPropertyByDotNotation(obj, dotNotation, newValue) {
  const keys = dotNotation.split(".");
  const newObject = { ...obj };
  let currentObject = newObject;
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (currentObject[key] === void 0 || currentObject[key] === null || typeof currentObject[key] !== "object" && !Array.isArray(currentObject[key])) {
      currentObject[key] = {};
    } else {
      currentObject[key] = Array.isArray(currentObject[key]) ? [...currentObject[key]] : { ...currentObject[key] };
    }
    currentObject = currentObject[key];
  }
  currentObject[keys[keys.length - 1]] = newValue;
  return newObject;
}
export const getMediaStreamUrl = (id) => {
  if ((Array.isArray(id) ? id[0] : id)?.startsWith("http")) return id;
  const str = Array.isArray(id) ? id[0] : id;
  const idWithoutExt = str.split(".").shift();
  const ext = str.split(".").pop();
  return companyConfig.apiUrl + "/api/helpers/media/stream?id=" + idWithoutExt + "&ext=" + ext;
};
export function camelToSentence(input = "") {
  const withSpaces = input.replace(/([a-z])([A-Z])/g, "$1 $2");
  const words = withSpaces.toLowerCase().split(" ");
  const sentenceCase = words[0].charAt(0).toUpperCase() + words[0].slice(1) + (words.length > 1 ? " " + words.slice(1).join(" ") : "");
  return sentenceCase;
}
export const hyphenatedToSentence = (str) => {
  return camelToSentence(str).replace(/-/g, " ");
};
export const blobIcon = (blob) => {
  const icon = mimeIcon(blob.type);
  console.log("getting icon for file", blob, icon);
  return icon;
};
export const mimeIcon = (mimeType) => {
  if (mimeType.startsWith("image")) {
    return faFileImage;
  }
  if (mimeType.startsWith("video")) {
    return faFileVideo;
  }
  if (mimeType.startsWith("audio")) {
    return faFileAudio;
  }
  if (mimeType.startsWith("text") || mimeType.includes("msword") || mimeType.includes("rtf") || mimeType.includes("wordprocessingml")) {
    return faFileWord;
  }
  if (mimeType.includes("zip") || mimeType.includes("compressed")) {
    return faFileArchive;
  }
  if (mimeType.includes("csv")) {
    return faFileCsv;
  }
  if (mimeType.includes("excel") || mimeType.includes("spreadsheet") || mimeType.includes("sheet")) {
    return faFileExcel;
  }
  if (mimeType.includes("powerpoint") || mimeType.includes("presentation") || mimeType.includes("slide")) {
    return faFilePowerpoint;
  }
  if (mimeType.includes("pdf")) {
    return faFilePdf;
  }
  return faFile;
  return faFile;
};
export const cleanFilePath = (str) => {
  if (!str || typeof str !== "string") return "";
  return str.replace(/^\/+|\/+$/g, "");
};
export const getCommonDataValues = (objectsArray) => {
  if (!Array.isArray(objectsArray) || objectsArray.length === 0) return {};
  const commonFieldsAndValues = {};
  for (const key in objectsArray[0]) {
    if (typeof objectsArray[0][key] === "object") {
      commonFieldsAndValues[key] = getCommonDataValues(
        objectsArray.map((o) => o[key])
      );
      continue;
    }
    let isCommon = true;
    const commonValue = objectsArray[0][key];
    for (let i = 1; i < objectsArray.length; i++) {
      if (objectsArray[i][key] === void 0 || objectsArray[i][key] !== commonValue) {
        isCommon = false;
        break;
      }
    }
    if (isCommon) commonFieldsAndValues[key] = commonValue;
  }
  return convertIfArray(commonFieldsAndValues);
};
const convertIfArray = (obj) => {
  if (!obj || typeof obj !== "object") return obj;
  const keys = Object.keys(obj);
  if (!keys.every((k, i) => Number(k) === i)) return obj;
  return Object.keys(obj).map((key) => obj[key]);
};
export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return store.getItem(key) === null ? defaultValue : JSON.parse(store.getItem(key));
  } catch {
    return store.getItem(key) || defaultValue;
  }
};
export const setItemToStore = (key, payload, store = localStorage) => store.setItem(key, JSON.stringify(payload));
export const getStoreSpace = (store = localStorage) => parseFloat(
  (escape(encodeURIComponent(JSON.stringify(store))).length / (1024 * 1024)).toFixed(2)
);
export const getCookieValue = (name) => {
  const value = document.cookie.match(
    "(^|[^;]+)\\s*" + name + "\\s*=\\s*([^;]+)"
  );
  return value ? value.pop() : null;
};
export const createCookie = (name, value, cookieExpireTime) => {
  const date = /* @__PURE__ */ new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + value + expires + "; path=/";
};
export const numberFormatter = (number, fixed = 2) => {
  return Math.abs(Number(number)) >= 1e9 ? (Math.abs(Number(number)) / 1e9).toFixed(fixed) + "B" : (
    // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1e6 ? (Math.abs(Number(number)) / 1e6).toFixed(fixed) + "M" : (
      // Three Zeroes for Thousands
      Math.abs(Number(number)) >= 1e3 ? (Math.abs(Number(number)) / 1e3).toFixed(fixed) + "K" : Math.abs(Number(number)).toFixed(fixed)
    )
  );
};
export const hexToRgb = (hexValue) => {
  let hex;
  hexValue.indexOf("#") === 0 ? hex = hexValue.substring(1) : hex = hexValue;
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)
  ] : null;
};
export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`;
export const colors = [
  "#2c7be5",
  "#00d97e",
  "#e63757",
  "#39afd1",
  "#fd7e14",
  "#02a8b5",
  "#727cf5",
  "#6b5eae",
  "#ff679b",
  "#f6c343"
];
export const themeColors = {
  primary: "#2c7be5",
  secondary: "#748194",
  success: "#00d27a",
  info: "#27bcfd",
  warning: "#f5803e",
  danger: "#e63757",
  light: "#f9fafd",
  dark: "#0b1727"
};
export const grays = {
  white: "#fff",
  100: "#f9fafd",
  200: "#edf2f9",
  300: "#d8e2ef",
  400: "#b6c1d2",
  500: "#9da9bb",
  600: "#748194",
  700: "#5e6e82",
  800: "#4d5969",
  900: "#344050",
  1e3: "#232e3c",
  1100: "#0b1727",
  black: "#000"
};
export const darkGrays = {
  white: "#fff",
  1100: "#f9fafd",
  1e3: "#edf2f9",
  900: "#d8e2ef",
  800: "#b6c1d2",
  700: "#9da9bb",
  600: "#748194",
  500: "#5e6e82",
  400: "#4d5969",
  300: "#344050",
  200: "#232e3c",
  100: "#0b1727",
  black: "#000"
};
export const getGrays = (isDark) => isDark ? darkGrays : grays;
export const rgbColors = colors.map((color) => rgbColor(color));
export const rgbaColors = colors.map((color) => rgbaColor(color));
export const getColor = (name, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();
};
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});
export const calculateSale = (base, less = 0) => base - base * (less / 100);
export const getTotalPrice = (cart, baseItems) => cart.reduce((accumulator, currentValue) => {
  const { id, quantity } = currentValue;
  const { price, sale } = baseItems.find((item) => item.id === id);
  return accumulator + calculateSale(price, sale) * quantity;
}, 0);
export const getSubtotal = (items) => items.reduce((acc, curr) => curr.price * curr.quantity + acc, 0);
export const getDiscountPrice = (total, discount) => total - total * (discount / 100);
export const getProductsQuantity = (products) => products.reduce((acc, product) => product.quantity + acc, 0);
export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};
export const capitalize = (str) => (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, " ");
export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
    if (+match === 0) return "";
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};
export const dashed = (str) => {
  return str.toLowerCase().replaceAll(" ", "-");
};
export const flatRoutes = (childrens) => {
  const allChilds = [];
  const flatChild = (childrens2, level) => {
    childrens2.forEach((child) => {
      if (child.children) {
        allChilds.push({ ...child, hadChild: true, level });
        flatChild(child.children, level + 1);
      } else {
        allChilds.push({ ...child, level });
      }
    });
  };
  flatChild(childrens, 0);
  return allChilds;
};
export const getFlatRoutes = (children) => children.reduce(
  (acc, val) => {
    if (val.children) {
      return {
        ...acc,
        [camelize(val.name)]: flatRoutes(val.children)
      };
    } else {
      return {
        ...acc,
        unTitled: [...acc.unTitled, val]
      };
    }
  },
  { unTitled: [] }
);
export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map((route) => {
    if (route.children) {
      return route.children.map((item) => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });
  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};
export const getPageName = (pageName) => {
  return window.location.pathname.split("/").slice(-1)[0] === pageName;
};
export const copyToClipBoard = (textFieldRef) => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand("copy");
};
export function copyTextToClipboard(textToCopy) {
  if (navigator?.clipboard?.writeText) {
    return navigator.clipboard.writeText(textToCopy);
  }
  return Promise.reject("The Clipboard API is not available.");
}
export const reactBootstrapDocsUrl = "https://react-bootstrap.github.io";
export const pagination = (currentPage, size) => {
  const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let prev = currentPage - 1 - Math.floor(size / 2);
  if (currentPage - 1 - Math.floor(size / 2) < 0) {
    prev = 0;
  }
  if (currentPage - 1 - Math.floor(size / 2) > pages.length - size) {
    prev = pages.length - size;
  }
  const next = prev + size;
  return pages.slice(prev, next);
};
export const tooltipFormatter = (params) => {
  let tooltipItem = ``;
  params.forEach((el) => {
    tooltipItem = tooltipItem + `<div class='ms-1'> 
        <h6 class="text-700"><span class="fas fa-circle me-1 fs--2" style="color:${el.borderColor ? el.borderColor : el.color}"></span>
          ${el.seriesName} : ${typeof el.value === "object" ? el.value[1] : el.value}
        </h6>
      </div>`;
  });
  return `<div>
            <p class='mb-2 text-600'>
              ${dayjs(params[0].axisValue).isValid() ? dayjs(params[0].axisValue).format("MMMM DD") : params[0].axisValue}
            </p>
            ${tooltipItem}
          </div>`;
};
export const addIdField = (items) => {
  return items.map((item, index) => ({
    id: index + 1,
    ...item
  }));
};
export const getSize = (size) => {
  if (size < 1024) {
    return `${size} Bytes`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
};
export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};
export const getDates = (startDate, endDate, interval = 1e3 * 60 * 60 * 24) => {
  const duration2 = endDate - startDate;
  const steps = duration2 / interval;
  return Array.from(
    { length: steps + 1 },
    (v, i) => new Date(startDate.valueOf() + interval * i)
  );
};
export const getPastDates = (duration2) => {
  let days;
  switch (duration2) {
    case "week":
      days = 7;
      break;
    case "month":
      days = 30;
      break;
    case "year":
      days = 365;
      break;
    default:
      days = duration2;
  }
  const date = /* @__PURE__ */ new Date();
  const endDate = date;
  const startDate = new Date((/* @__PURE__ */ new Date()).setDate(date.getDate() - (days - 1)));
  return getDates(startDate, endDate);
};
export const addId = (items) => items.map((item, index) => ({
  id: index + 1,
  ...item
}));
export const getTimeDuration = (startDate, endDate, format = "") => {
  return dayjs.duration(endDate.diff(startDate)).format(format);
};
export const getPercentage = (number, percent) => {
  return Number(number) / 100 * Number(percent);
};
export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};
export const slugifyText = (str) => str.toLowerCase().replace(/\s+/g, "-").replace(/[\u0300-\u036f]/g, "").replace(/--+/g, "-").replace(/^-+/, "").replace(/-+$/, "");
export const downloadCSV = (data, title, headerMapper = (k) => k) => {
  const items = data;
  const replacer = (value) => {
    if (value === null || value === void 0) {
      return "";
    }
    if (/[",\n\r]/.test(value)) {
      value = value.replace(/"/g, '""');
      value = `"${value}"`;
    }
    return value;
  };
  const header = Object.keys(items[0]);
  const csvData = [
    header.map((h) => replacer(h)).map(headerMapper).join(","),
    // header row first
    ...items.map(
      (row) => header.map(
        (fieldName) => replacer(
          typeof row[fieldName] === "object" ? JSON.stringify(row[fieldName]) : row[fieldName] || ""
        )
      ).join(",")
    )
  ].join("\r\n");
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = title;
  document.body.appendChild(a);
  a.click();
  a.remove();
};
export function isReactRenderable(value) {
  if (value === null || value === void 0) {
    return true;
  }
  if (typeof value === "string" || typeof value === "number") {
    return true;
  }
  if (React.isValidElement(value)) {
    return true;
  }
  if (Array.isArray(value)) {
    return value.every(isReactRenderable);
  }
  return false;
}
export const ensureArray = (val) => {
  if (Array.isArray(val)) {
    return val;
  }
  if (val === void 0) return void 0;
  return [val];
};
export const callIfFunction = (param, ...fnArgs) => {
  if (typeof param === "function") {
    return param(...fnArgs);
  } else {
    return param || void 0;
  }
};
