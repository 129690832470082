import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';
import { Section } from './helpers';

export default forwardRef<HTMLDivElement, { id?: string }>((props, ref) => {
  return (
    <Card className="mb-3" id={props.id} ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">This Policy</h5>
      </Card.Header>
      <Card.Body>
        <Section title="Who are we">
          This Privacy Policy tells you what to expect when we collect and
          process your personal information through your use of
          www.flexwms.co.uk (the ‘Site’). Researchers at Crown Ltd T/A
          Teamsearch, a company registered in England and Wales with Company
          Number 3921280 and registered office at Floor 2, 1850 Mill, Shaw Lodge
          Mills, Shaw Lan, Halifax HX3 9ET.
        </Section>
        <Section title="What this policy does not apply to">
          This Privacy Policy does not apply to any data collected, processed or
          otherwise dealt with in accordance with the EULA. The EULA applies
          when you use our cloud workforce management solution.
        </Section>
        <Section title="What this policy applies to">
          Where we refer to Data Protection Law below this means;{' '}
          <ul>
            <li>
              unless and until the GDPR is no longer directly applicable in the
              UK, the General Data Protection Regulation ((EU) 2016/679) and any
              national implementing laws, regulations and secondary legislation,
              as amended or updated from time to time, in the UK, and then
            </li>
            <li>
              any successor legislation to the GDPR or the Data Protection Act
              2018.
            </li>
          </ul>
          Your privacy is important to us and we will process your data in
          accordance with Data Protection Law. This says that the personal
          information we hold about you must be:
          <ul>
            <li>Used lawfully, fairly and in a transparent way.</li>
            <li>
              Collected only for valid purposes that we have clearly explained
              to you and not used in any way that is incompatible with those
              purposes.
            </li>
            <li>
              Relevant to the purposes we have told you about and limited only
              to those purposes.
            </li>
            <li>Accurate and kept up to date.</li>
            <li>
              Kept only as long as necessary for the purposes we have told you
              about.
            </li>
            <li>Kept securely.</li>
          </ul>
        </Section>
      </Card.Body>
    </Card>
  );
});
