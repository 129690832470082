import React, { useRef } from 'react';
import PageHeader from 'components/common/PageHeader';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';
import { Section } from '../privacy-policy/helpers';

const TandC = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });
  const Link = ({ index, children }) => {
    return (
      <Nav.Item>
        <Nav.Link
          href={'#' + index}
          className="nav-link px-0 py-1"
          active={activeSection === Number(index)}
        >
          {children}
        </Nav.Link>
      </Nav.Item>
    );
  };
  return (
    <>
      <PageHeader
        title="Terms and Conditions"
        // description="A privacy policy is a statement or legal document that discloses some or all of the ways a party gathers, uses, discloses, and manages a customer or client's data."
        className="mb-3"
      />
      <Row className="g-0">
        <Col lg={8} className="pe-lg-2 order-1 order-lg-0">
          <Card className="mb-3">
            <Card.Header className="bg-light">
              <h5 className="mb-0 text-primary">Third Parties</h5>
            </Card.Header>
            <Card.Body>
              <Section id="0" title="Disclaimer" ref={sectionRefs[0]}>
                The Information on this website is intended to be helpful and
                informative. However, neither nor any of its employees, agents
                or sub-contractors make any warranty, express or implied, or
                assume any legal liability or responsibility for the accuracy or
                completeness of any information contained on this site or any
                web site referred to. Those who access the site or any site
                referred to should take appropriate steps to verify all such
                information. Researchers at Crown Ltd T/A Teamsearch and its
                subsidiaries cannot be held responsible for the contents of any
                pages referred to by an external link. A reference on this site
                to any persons, products, web sites or services does not
                constitute or imply their endorsements, recommendation or
                favouring by Teamsearch, its employees, agents or
                sub-contractors.
              </Section>

              <Section id="1" title="1. Definitions" ref={sectionRefs[1]}>
                1.1 “This Site” refers to all internet pages in the
                flexwms.co.uk domain.1.2 “The Company” refers to any
                organisation involved in any part of the design, development,
                management, response-handling, marketing, promotion or other
                similar aspects of this Site. 1.3 “User” refers to any person
                viewing or interacting with this Site, including “you”. 1.4
                “Content” refers to any text, photograph or other image
                presented on this Site to Users by either flexwms.co.uk or
                Users.
              </Section>

              <Section id="2" title="2. Terms" ref={sectionRefs[2]}>
                2.1 By using this site, you agree to these terms of use. If you
                do not agree to these terms of use, disconnect from this site
                and do not use this site further.
              </Section>
              <Section id="3" title="3. Summary" ref={sectionRefs[3]}>
                3.1 Except where otherwise expressed, all opinions, advice,
                statements, offers, or other information content made available
                through this Site are those of third parties and not of The
                Company and should not be relied upon. The Company assumes no
                responsibility for any opinions, advice, statements, offers or
                other information provided on this Site. The Company does not
                guarantee the accuracy, completeness, or usefulness of any
                information on this Site and neither adopts nor endorses nor is
                responsible for the accuracy or reliability of any opinion,
                advice or statement made. The Company is not responsible for any
                Content that is illegal, offensive or otherwise actionable.
                Under no circumstances will The Company be responsible for any
                loss or damage resulting from anyone’s reliance on information
                or other Content posted on this Site or transmitted to Users.
                3.2 The Company reserves the right, but has no obligation, to
                edit or remove any Content without question, reason or notice.
              </Section>
              <Section id="4" title="4. Site contents" ref={sectionRefs[4]}>
                4.1 The Content made available in this Site is provided “as is”
                without warranties of any kind, either express or implied,
                including but not limited to all implied warranties of
                satisfactory quality, fitness for a particular purpose, title,
                or non-infringement of any third party rights including, without
                limitation, intellectual property rights. The Company does not
                warrant that the Content will be error free, nor free of
                viruses, nor free from defamatory, offensive, infringing or
                other harmful matter. You assume the entire cost of any
                necessary service, repair, or correction. The Content may
                include inaccuracies or typographical errors that will be
                corrected as they are discovered at The Company’s sole
                discretion. You acknowledge that The Company has not undertaken
                any verification of Content featured on this Site. 4.2 By
                submitting a photograph or image to this Site, you grant The
                Company permission to use it, either in its original format or
                altered or cropped, on this Site in any way deemed appropriate
                by The Company.
              </Section>
              <Section id="5" title="5. Site links" ref={sectionRefs[5]}>
                5.1 This Site links to websites operated by third parties. The
                Company does not control these third party websites and is not
                responsible for their content. The presence on this Site of a
                link to a website operated by a third party does not imply any
                endorsement by The Company of that third party or of any
                products or services advertised, or other information or content
                featured on that website. Nor does it constitute any assurance
                by The Company that that website has in place security measures
                to protect information which you may submit online.
              </Section>
              <Section id="6" title="6. Conduct of users" ref={sectionRefs[6]}>
                6.1 You acknowledge that you are solely responsible for
                interactions with other Users of this Site and that you are
                solely responsible for all materials that you publish or post to
                this Site or transmit to other Users of this Site. 6.2 You agree
                that by interacting with this Site or providing materials to The
                Company that you are granting The Company permission to use that
                material on this Site. 6.3 You agree not to post, distribute or
                reproduce in any way any copyright material, trade marks or
                other proprietary information without obtaining the prior
                consent of the owner of such proprietary rights. 6.4 You will
                not include material on this Site, which contains offensive
                anatomical or sexual references, or offensive language, and you
                will not post any photograph or image containing nudity or
                personal information. The Company reserves the right, but has no
                obligation, to reject any text, photograph or other image that
                does not comply with these prohibitions. 6.5 You agree not to
                transmit any chain letters, spam letters, or junk email to other
                Users, not to participate in mischievous or malicious behaviour
                which causes damage or may cause damage to The Company or this
                Site or any of the computer systems on which this Site resides.
                You agree not to attempt to bypass any of the security measures
                implemented on this Site or on any of the computer systems on
                which this Site resides.
              </Section>
              <Section
                id="7"
                title="7. Marketing and Publicity"
                ref={sectionRefs[7]}
              >
                7.1 The Company may from time to time obtain publicity for this
                Site in the marketing press, internet trade press and general
                popular press, as well as newspapers, magazines and other
                publications both national and international. You agree that
                these publications may without The Company knowledge obtain
                screenshots or quotations or other publicly accessible material
                from this Site to be used in such publications that may feature
                your The Company entry, profile and/or photograph. 7.2 You agree
                to release all rights concerning such material as referenced in
                to The Company and agree that your entry, profile and/or
                photograph may be used for such purposes. The Company agrees
                that it will contact you for your comments in all cases where it
                is aware that your information is contained in any such
                screenshot. The Company will endeavour to do this prior to any
                deadline for which the screenshot may be required.
              </Section>
              <Section
                id="8"
                title="8. Disclaimer of warranty and limitation of liability"
                ref={sectionRefs[8]}
              >
                8.1 Under no circumstances shall The Company, nor affiliates,
                staff, agents or suppliers, be liable for any damages, including
                without limitation, direct, indirect, incidental, special,
                punitive, consequential, or other damages (including without
                limitation lost profits, lost revenues, or similar economic
                loss), whether in contract, tort, or otherwise, arising out of
                the use or inability to use the Content available in this Site
                or any linked site, even if The Company is advised of the
                possibility thereof, nor for any claim by a third party. 8.2 The
                Company hereby expressly disclaims any and all warranties,
                including without limitation, any warranties to the
                availability, accuracy, or information, products or services,
                and any warranties of satisfactory quality or fitness for a
                particular purpose. 8.3 The Company shall in no event be liable
                for any failure of performance, error, omission, interruption,
                deletion, defect, delay in operation or transmission,
                communications line failure, theft or destruction or
                unauthorized access to, alteration of, or use of records for any
                cause of action.
              </Section>
              <Section id="9" title="9. Indemnity" ref={sectionRefs[9]}>
                9.1 You agree to defend, fully indemnify and keep fully
                indemnified at all times, and hold harmless The Company, its
                officers, directors, employees, agents and third parties, for
                any losses, costs, liabilities, damages and expenses (including
                reasonable fees) relating to or arising out of your use of this
                Site including, without limitation, any infringement of any
                third party’s rights (including without limitation intellectual
                property rights and rights of privacy and publicity) and any
                unlawful material you submit to this Site (including without
                limitation material which is or could be deemed defamatory,
                obscene, offensive, inaccurate, blasphemous, threatening or
                illegal), including any breach by you of the terms of this
                agreement.
              </Section>
              <Section id="10" title="10. Severability" ref={sectionRefs[10]}>
                10.1 In the event any term or condition of this agreement is
                prohibited by local or other law, this agreement shall be
                construed in such a manner as to give force and effect to every
                lawful provision.
              </Section>
              <Section id="11" title="11. Notice" ref={sectionRefs[11]}>
                11.1 The terms of use shall be governed in accordance with the
                laws of England. The terms and conditions of this agreement are
                subject to change by The Company at any time on a prospective
                basis upon notice to Users. Any User’s use of this Site
                following such notice shall indicate the User’s acceptance of
                the new terms and conditions may provide notice to Users by
                email or by a general posting on this Site.
              </Section>
              <Section
                id="12"
                title="12. Personal data protection"
                ref={sectionRefs[12]}
              >
                12.1 The Company will store all data including your personal
                information submitted to this Site. If you do not want your
                personal information to be stored by The Company please
                disconnect from this Site now. By using this Site, you consent
                to the collection and use by The Company of your personal
                information for the purpose of effectively meeting the
                objectives of The Company. You may withdraw this consent at any
                time by emailing info@flexwms.co.uk12.2 The Company may contact
                you via email or telephone from time to time to update you about
                functionality changes and new services on offer. You can opt out
                of these communications by emailing info@flexwms.co.uk 12.3 The
                Company is not responsible for privacy practices of third party
                websites linked to this Site. The Company undertakes not to
                disclose any of your personal information to third parties
                without your consent. The Company follows strict security
                procedures in the storage of your personal information to
                prevent unauthorised access.
              </Section>
              <Section
                id="13"
                title="13. Copyrights and trademarks"
                ref={sectionRefs[13]}
              >
                13.1 All Content and materials on this Site, save where
                otherwise expressed, are subject to copyright and are protected
                by worldwide copyright laws and treaty provisions. No logo,
                graphic, text, sound or image from this website may be copied,
                reproduced, modified, published, uploaded, posted, transmitted,
                or distributed in any way, without The Company’s prior written
                permission. Except as expressly provided herein, The Company
                does not grant any express or implied right to you under any
                patents, copyrights or trademarks. Copyright Researchers @ Crown
                Ltd T/A Teamsearch Registered office: Floor 2, 1850 Mill, Shaw
                Lodge Mills, Halifax, HX3 9ET Company Reg No. 3921280.
              </Section>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className="ps-lg-2 mb-3">
          <div className="sticky-sidebar">
            <Card className="sticky-top">
              <Card.Header className="border-bottom">
                <h6 className="mb-0 fs-0">On this page</h6>
              </Card.Header>
              <Card.Body>
                <div
                  id="terms-sidebar"
                  className="terms-sidebar nav flex-column fs--1"
                >
                  <Link index="0">Disclaimer</Link>
                  <Link index="1">Definitions</Link>
                  <Link index="2">Terms</Link>
                  <Link index="3">Summary</Link>
                  <Link index="4">Site contents</Link>
                  <Link index="5">Site links</Link>
                  <Link index="6">Conduct of users</Link>
                  <Link index="7">Marketing and publicity</Link>
                  <Link index="8">
                    Disclaimer of warranty and limitation of liability
                  </Link>
                  <Link index="9">Indemnity</Link>
                  <Link index="10">Severability</Link>
                  <Link index="11">Notice</Link>
                  <Link index="12">Personal data protection</Link>
                  <Link index="13">Copyrights and trademarks</Link>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TandC;
