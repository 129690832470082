import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getOneTimeTours, setTourDone } from 'apis/flex/helpers';

export default ({ name } = {}) => {
  const queryClient = useQueryClient();
  const { data } = useQuery({
    queryKey: ['one-time-tours'],
    queryFn: () => getOneTimeTours(),
    select: d => !d.find(d => d.name === name),
    staleTime: Infinity,
    enabled: !!name
  });
  const { mutate } = useMutation({
    mutationFn: ({ name: _name = name } = {}) => {
      queryClient.invalidateQueries(['one-time-tours']);
      setTourDone(_name);
    }
  });
  return { isNotDone: data === true, setDone: mutate };
};
