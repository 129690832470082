import React from 'react';
import { WizardInputComponent } from '../WizardInput';
import WizardSelect from './WizardSelect';
import { Badge } from 'react-bootstrap';
const variants = [
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'light',
  'dark'
];
const colorRender = ({ value }) => {
  return (
    <Badge
      className="w-100"
      bg={value}
      text={value === 'light' ? 'dark' : 'light'}
    >
      {value}
    </Badge>
  );
};
const WizardVariant: WizardInputComponent = ({
  renderProps,
  formControlProps,
  multiple,
  pluginProps
}) => {
  return (
    <WizardSelect
      renderProps={{
        ...renderProps,
        form: {
          ...renderProps.form,
          field: {
            ...renderProps.form.field,
            value: renderProps.form.field.value,
            onChange: v => renderProps.form.field.onChange(v)
          }
        }
      }}
      formControlProps={formControlProps}
      options={variants.map(i => ({
        value: i,
        label: i
      }))}
      multiple={multiple}
      placeholder="Pick an color"
      pluginProps={{
        grid: { cols: 2, width: 250 },
        formatOptionLabel: colorRender,
        styles: {
          option: {
            padding: '5px !important',
            fontSize: '1rem !important'
          }
        },
        ...pluginProps
      }}
    />
  );
};

export default WizardVariant;
