import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Flex from './Flex';
import PropTypes from 'prop-types';
const ConfirmButton = ({
  children,
  onClick,
  className = '',
  message = 'Are you sure?',
  ...rest
}) => {
  const [prompting, setPrompting] = useState();
  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    if (prompting) {
      onClick();
    } else {
      setPrompting(true);
    }
  };
  return (
    // <div className="position-relative">
    <Button
      {...rest}
      style={{ zIndex: 6, minWidth: '4em' }}
      onClick={handleClick}
      className={classNames(className, 'position-relative')}
    >
      <span
        className="bg-100 fs--1 p-1 position-absolute rounded-3 start-50 text-danger text-nowrap transition transition-base translate-middle-x"
        style={{
          opacity: prompting ? 1 : 0,
          zIndex: prompting ? 5 : -1,
          top: prompting ? '-100%' : '0%'
        }}
      >
        {message || 'Are you sure?'}
      </span>
      {prompting && (
        <>
          <Flex
            className="position-absolute start-0 top-0 w-100 h-100 bg-700 fs--2 text-100 rounded"
            style={{ zIndex: 6 }}
          >
            <Button
              size="sm"
              variant="success"
              className="px-1 w-50"
              style={{ minWidth: '2em' }}
              onClick={handleClick}
            >
              <FontAwesomeIcon icon={faCheck} size="sm" />
            </Button>
            <Button
              size="sm"
              variant="secondary"
              className="px-1 w-50"
              style={{ minWidth: '2em' }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setPrompting(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="sm" />
            </Button>
          </Flex>
        </>
      )}
      {children}
    </Button>
    // </div>
  );
};
ConfirmButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
  message: PropTypes.string
};
export default ConfirmButton;
