import React, { useMemo } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { SparkLine } from 'components/common/widgets/SimpleLine';
import { groupBy } from 'lodash';
import { format } from 'date-fns';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { Campaign, CampaignApplicant } from 'apis/flex/recruitment';
import Flex from 'components/common/Flex';
import { Row } from '@tanstack/react-table';
import CustomTooltip from 'components/common/Tooltip';
import useCampaignLink from './hooks/useCampaignLink';
import DomainTable from 'components/common/DomainTable';

const ApplicantsLine = ({
  applicants
}: {
  applicants?: CampaignApplicant[];
}) => {
  const lineData = useMemo(() => {
    const groupedData = groupBy(
      applicants,
      d =>
        typeof d === 'object' &&
        'appliedDate' in d &&
        format(new Date(d.appliedDate), 'dd/MM/yyyy')
    );
    return Object.keys(groupedData).map(dte => groupedData[dte]?.length || 0);
  }, [applicants]);
  return (
    <Flex>
      <div className={'fw-semi-bold me-2'}>{applicants?.length || 0}</div>
      <SparkLine style={{ height: 25, width: 100 }} data={lineData} />
    </Flex>
  );
};
const CampaignsTable = () => {
  // const {
  //   data: campaigns,
  //   isLoading,
  //   error,
  //   clone,
  //   isCloning,
  //   remove,
  //   isRemoving
  // } = useCampaigns({ useFilter: true });
  // const nav = useNavigate();
  const { copy } = useCampaignLink();
  const handleCopy = (row: Row<Campaign>) => {
    copy(row.original.id);
  };
  // const [cloneIndices, setCloneIndices] = useState<number[]>([]);
  // const handleClone = (row: Row<Campaign>) => {
  //   clone(
  //     row.original.id,
  //     { onSuccess: () => setCloneIndices(t => t.filter(i => i !== row.index)) },
  //     true
  //   );
  //   setCloneIndices(t => t.concat(row.index));
  // };
  return (
    <DomainTable<Campaign>
      domain="recruitment-campaign"
      title="Campaigns"
      initialSort={[{ id: 'startDate', desc: true }]}
      actions={[
        {
          name: 'Copy link',
          icon: faLink,
          onClick: r => handleCopy(r)
        }
      ]}
      columns={[
        'name',
        'jobTitle',
        {
          id: 'applicants',
          sortValue: v => v.length,
          formatter: v => {
            return <ApplicantsLine applicants={v()} />;
          }
        },
        { id: 'startDate', visible: false },
        { id: 'endDate', visible: false },
        {
          id: 'progress',
          accessorFn: d => [d.startDate, d.endDate],
          sortValue: (v, row) => {
            return Math.min(
              1,
              (new Date().valueOf() -
                new Date(row.original.startDate).valueOf()) /
                (new Date(row.original.endDate).valueOf() -
                  new Date(row.original.startDate).valueOf())
            );
          },
          formatter: v =>
            !!(v()[0] && v()[1]) && (
              <CustomTooltip
                content={
                  <>
                    {Math.min(
                      1,
                      Math.round(
                        ((new Date().valueOf() - new Date(v()[0]).valueOf()) /
                          (new Date(v()[1]).valueOf() -
                            new Date(v()[0]).valueOf())) *
                          100
                      )
                    )}
                    % ({format(new Date(v()[0]), 'dd/MM/yyyy')}-
                    {format(new Date(v()[1]), 'dd/MM/yyyy')})
                  </>
                }
              >
                <div>
                  <ProgressBar
                    min={new Date(v()[0]).valueOf()}
                    max={new Date(v()[1]).valueOf()}
                    now={new Date().valueOf()}
                    style={{ height: 5 }}
                    className="rounded-pill align-middle"
                    variant="progress-gradient"
                  />
                </div>
              </CustomTooltip>
            )
        },
        { id: 'createdBy', domain: 'user' }
      ]}
    />
  );
};

export default CampaignsTable;
