import React, { Ref, useCallback, useEffect, useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import { ProcessedCell, useAdvanceTable } from './AdvanceTableProvider';
import { Row as RowType } from '@tanstack/react-table';
import SimpleBar from 'simplebar-react';
import DropdownContextWrapper from '../DropdownContextWrapper';
import PageSlider from './PageSlider';
import ReactTable, { BaseReactTableProps } from './ReactTable';
export type ConfigCallbackProps = {
  data: any;
  cells: ProcessedCell[];
  row: RowType<any>;
  onRowClick: (row: RowType<any>) => void;
};

const SelectionListener = ({ onSelectionChange }) => {
  const { getSelectedRowModel } = useAdvanceTable();
  const [selected, setSelected] = useState([]);
  // // console.log('selectedRowModel', getSelectedRowModel, getSelectedRowModel?.());
  const s = getSelectedRowModel?.().rows.map(r => r.id);
  useEffect(() => {
    if (!s) return;
    if (
      s.every(ss => selected?.includes(ss)) &&
      selected?.every(ss => s.includes(ss))
    )
      return;
    setSelected(s);
  }, [s]);
  useEffect(() => {
    onSelectionChange(selected);
  }, [selected]);
  return <></>;
};
const TableView = ({
  isLoading,
  emptyPlaceholder,
  ...rest
}: BaseReactTableProps & { emptyPlaceholder?: string }) => {
  const {
    RowContextMenu,
    getRowModel,
    pagination,
    id,
    dataId,
    animateDirection,
    getOnRowClick,
    getHeaderGroups,
    // resetRowSelection,
    filters
  } = useAdvanceTable();
  const rows = useMemo(() => {
    const r = getRowModel().rows;
    // // console.log('dataId', dataId, r);
    return r;
  }, [getRowModel(), dataId]);
  const [menuRow, setMenuRow] = useState();
  const handleContext = row => e => {
    e.preventDefault();
    setMenuRow(row);
  };
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const tableId = isLoading
    ? id + '-loading'
    : id + '-page-' + pagination?.pageIndex;
  // useEffect(() => {
  //   resetRowSelection();
  // }, [dataId]);
  const onPinChange = useCallback(() => setRefreshTrigger(prev => !prev), []);
  // console.log('rows', rows, getHeaderGroups());
  return (
    <div
      // layout
      className="table-responsive overflow-visible"
      style={{ minHeight: 200 }}
    >
      <SelectionListener
        onSelectionChange={() => setRefreshTrigger(!refreshTrigger)}
      />
      {rows?.length === 0 && !isLoading ? (
        <div className="w-100 text-center p-3 fw-semi-bold text-500 fs-2">
          {emptyPlaceholder}
        </div>
      ) : (
        <DropdownContextWrapper
          menuItems={
            RowContextMenu && menuRow && <RowContextMenu row={menuRow} />
          }
        >
          <PageSlider
            motionKey={tableId}
            direction={
              pagination?.isForward === false || animateDirection === 'backward'
                ? 'backward'
                : 'forward'
            }
          >
            <ReactTable
              isLoading={isLoading}
              onContextMenu={handleContext}
              getHeaderGroups={getHeaderGroups}
              pagination={pagination}
              onPinChange={onPinChange}
              rows={rows}
              getOnRowClick={getOnRowClick}
              refreshTrigger={refreshTrigger}
              filters={filters}
              {...rest}
            />
          </PageSlider>
        </DropdownContextWrapper>
      )}
    </div>
  );
};
const AdvanceTable = ({
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  isLoading,
  onRowClick,
  emptyPlaceholder = 'No records to show',
  tableRef
}: // state = {}
{
  headerClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  tableProps?: any;
  isLoading?: boolean;
  onRowClick?: any;
  emptyPlaceholder?: string;
  tableRef?: Ref<HTMLTableElement>;
}) => {
  const {
    getHeaderGroups,
    getRowModel,
    isLoading: providerLoading,
    getOnRowClick
  } = useAdvanceTable();
  const anyLoading = isLoading || providerLoading;
  const anyRowClick = useCallback(
    () => onRowClick || getOnRowClick() || function () {},
    [onRowClick, getOnRowClick]
  );
  return (
    <Card.Body className="p-0 overflow-visible">
      <SimpleBar className="overflow-visible">
        <TableView
          {...{
            tableRef,
            headerClassName,
            bodyClassName,
            rowClassName,
            tableProps,
            isLoading: anyLoading,
            getOnRowClick: anyRowClick,
            getRowModel,
            getHeaderGroups,
            emptyPlaceholder
          }}
        />
      </SimpleBar>
    </Card.Body>
  );
};
export default AdvanceTable;
