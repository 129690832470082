import { useQuery } from "@tanstack/react-query";
import {
  getResourceGroupSchedule,
  getResourcePlan
} from "apis/flex/projects";
export default ({
  filters,
  select
}) => {
  return useQuery({
    queryKey: ["resourcePlan", filters],
    queryFn: () => getResourcePlan({ filters }),
    select
  });
};
export const useResourceGroupSchedule = (id) => {
  return useQuery({
    queryKey: ["resourceGroupSchedule", id],
    queryFn: () => getResourceGroupSchedule({ id })
  });
};
