import React from 'react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { createContext } from 'react';
import { WizardInputProps } from './WizardInput';
import { Control, useFormContext } from 'react-hook-form';
const InputContext = createContext({});
type InputConfigProps = Partial<WizardInputProps> & { _control?: Control };
const InputConfig = ({
  children,
  ...rest
}: InputConfigProps & { children: React.ReactNode }) => {
  const props = { ...rest };
  try {
    const { control } = useFormContext();
    props._control = control;
  } catch (e) {
    // noop
  }
  return (
    <InputContext.Provider value={{ ...rest }}>
      {children}
    </InputContext.Provider>
  );
};
InputConfig.propTypes = {
  children: PropTypes.node
};
export const useInputConfig = () => {
  const { _control, ...context } = useContext<InputConfigProps>(InputContext);
  try {
    const { control } = useFormContext();
    if (control === _control) return context;
  } catch (e) {
    return context;
  }
};
export default InputConfig;
