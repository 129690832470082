import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { flexLogin } from 'apis/flex/auth';
import { User } from 'components/app/users/types';
import { LoginContext, RefreshUserContext, UserContext } from 'context/Context';
import { useContext, useState } from 'react';
export const useUser = () => useContext(UserContext);
export const useRefreshUser = () => useContext(RefreshUserContext);

export const UserProvider = ({
  user: userProp,
  children
}: {
  user: User;
  children: React.ReactNode;
}) => {
  const [user, setUser] = useState<User>(userProp);
  const { mutate } = useMutation({
    mutationFn: () => flexLogin().then(d => setUser(d.data))
  });
  const [settings, setSettings] = useState<any>({});
  return (
    <UserContext.Provider value={user}>
      <LoginContext.Provider value={{ settings, setSettings }}>
        <RefreshUserContext.Provider value={mutate}>
          {children}
        </RefreshUserContext.Provider>
      </LoginContext.Provider>
    </UserContext.Provider>
  );
};
