import React, { forwardRef } from 'react';
import { Card } from 'react-bootstrap';
import { Section } from './helpers';

export default forwardRef<HTMLDivElement, { id?: string }>((props, ref) => {
  return (
    <Card className="mb-3" id={props.id} ref={ref}>
      <Card.Header className="bg-light">
        <h5 className="mb-0 text-primary">Your Data</h5>
      </Card.Header>
      <Card.Body>
        <Section title="The kind of information we hold about you">
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data). We
          will collect, store, and use the following categories of personal
          information about you:
          <ul>
            <li>Identity Data such as first name, last name and title.</li>
            <li>
              Contact Data such as addresses, telephone numbers, and personal
              email addresses.
            </li>
            <li>
              Technical Data such as internet protocol (IP) address, your login
              data, browser type and version, time zone setting and location,
              browser plug-in types and versions, operating system and platform
              and other technology on the devices you use to access this Site.
            </li>
            <li>
              Usage Data such as information about how you use our Site,
              products and services.
            </li>
          </ul>
        </Section>
        <Section title="If you fail to provide personal information">
          Where we need to collect personal data by law, or under the terms of a
          contract we have with you, and you fail to provide that data when
          requested, we may not be able to perform the contract we have or are
          trying to enter into with you (for example, to provide you with goods
          or services). In this case, we may have to cancel a product or service
          you have with us but we will notify you if this is the case at the
          time.
        </Section>

        <Section title="How do we collect your personal information?">
          You may give us your personal data by filling in forms or by
          corresponding with us by phone, email or otherwise. This includes
          personal data you provide when you:
          <ul>
            <li>Respond to forms, including job applications.</li>
            <li>Request email notifications.</li>
            <li>Signing up, or logging into our Site</li>
          </ul>
          As you interact with our Site, we may automatically collect Technical
          Data about your equipment, browsing actions and patterns. We will NOT
          receive Technical Data about you from third parties, nor will we share
          Technical Data with third parties.
        </Section>

        <Section title="How do we use your personal information?">
          We will only use your personal information when the law allows us to.
          To administer and protect our business and Site (including
          troubleshooting, data analysis, testing, system maintenance, support,
          reporting and hosting of data) we will use your personal information
          where it is necessary to comply with a legal obligation or for our
          legitimate interests (running our business, provision of
          administration and IT services, network security, to prevent fraud and
          in the context of a business reorganisation or group restructuring
          exercise), or those of a third party, but only where your interests
          and fundamental rights do not override our interests. To use data
          analytics to improve our Site, services, marketing, customer
          relationships and experiences where it is necessary to comply with a
          legal obligation or for our legitimate interests (to define types of
          customers for our products and services, to keep our Site updated and
          relevant, to develop our business and to inform our marketing
          strategy), but only where your interests and fundamental rights do not
          override our interests. To make suggestions and recommendations to you
          about services that may be of interest to you where it is necessary
          for our legitimate interests (to develop our products/services and
          grow our business), but only where your interests and fundamental
          rights do not override our interests. We may also use your personal
          information in the following situations, which are likely to be rare:
          <ul>
            <li>
              where we need to protect your interests (or someone else's
              interests); or
            </li>
            <li>where it is needed in the public interest.</li>
          </ul>
        </Section>

        <Section title="Situations in which we will use your personal information">
          We need all the categories of information we keep about you primarily
          to allow us to use your personal information to pursue legitimate
          interests of our own, provided your interests and fundamental rights
          do not override those interests. The situations in which we will
          process your personal information are listed below.
          <ul>
            <li>
              To provide the relevant service or feature that you have
              requested.
            </li>
            <li>To manage your preferences.</li>
            <li>
              To gather statistics around email opening and clicks using
              industry standard technologies to help us monitor and improve our
              newsletters and email subscriptions.
            </li>
            <li>
              To ensure network and information security, including preventing
              unauthorised access to our computer and electronic communications
              systems and preventing malicious software distribution.
            </li>
            <li>
              To conduct data analytics studies to review and better understand
              how and when people use our Site.
            </li>
            <li>To help us to improve our Site and search functionality.</li>
          </ul>
          Some of the above grounds for processing will overlap and there may be
          several grounds which justify our use of your personal information.
        </Section>

        <Section title="Change of purpose">
          We will only use your personal information for the purposes for which
          we collected it, unless we reasonably consider that we need to use it
          for another reason and that reason is compatible with the original
          purpose. If we need to use your personal information for an unrelated
          purpose, we will notify you and we will explain the legal basis which
          allows us to do so. Please note that we may process your personal
          information without your knowledge or consent, in compliance with the
          above rules, where this is required or permitted by law.
        </Section>
        <Section title="Data retention">
          We will only retain your personal information for as long as necessary
          to fulfil the purposes we collected it for, including for the purposes
          of satisfying any legal, accounting, or reporting requirements. To
          determine the appropriate retention period for personal data, we
          consider the amount, nature, and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal requirements.
          <br /> In some circumstances we may anonymise your personal
          information so that it can no longer be associated with you, in which
          case we may use such information without further notice to you. We
          will retain and securely destroy your personal information in
          accordance with our data retention rules that are available on
          request.
        </Section>
      </Card.Body>
    </Card>
  );
});
