import React from 'react';
import { ContractHours, ContractPay, ContractPdf } from '../ContractForm';
import DetailCard from 'components/common/detail/DetailCard';
import WizardInput from 'components/wizard/WizardInput';
import DomainDetail from 'components/common/DomainDetail';
import { useParams } from 'react-router-dom';
import { Contract } from 'apis/flex/hr';
const ContractDetail = () => {
  const { contractId } = useParams();
  return (
    <DomainDetail<Contract>
      itemId={Number(contractId)}
      domain="contract"
      sidebar={
        <>
          <WizardInput type="text" name="name" />
          <WizardInput
            type="switch"
            name="isActive"
            registerProps={{ required: false }}
            label="Active"
          />
          <WizardInput
            registerProps={{ required: false }}
            type="select"
            options={[
              { label: '1 month', value: 1 },
              { label: '6 months', value: 6 },
              { label: '12 months', value: 12 },
              { label: '18 months', value: 18 },
              { label: '24 month', value: 24 },
              { label: 'Rolling', value: 0 }
            ]}
            name="term"
          />
        </>
      }
      defaultValues={{ name: '', fileId: [], fields: [], isActive: true }}
      // title={data?.name || 'Create Contract'}
    >
      <DetailCard id="contract" title="Contract">
        <ContractPdf />
      </DetailCard>

      <DetailCard id="pay" title="Pay">
        <ContractPay />
      </DetailCard>

      <DetailCard id="hours" isLast title="Shifts">
        <ContractHours />
      </DetailCard>
    </DomainDetail>
  );
};
export default ContractDetail;
