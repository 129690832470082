import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileId } from 'apis/types';
import classNames from 'classnames';
import LightboxImage from 'components/common/LightboxImage';
import useMediaBlob from 'hooks/useMediaBlob';
import React from 'react';
import { Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
export default ({
  fileId,
  className,
  ...rest
}: {
  disabled?: boolean;
  fileId?: FileId | FileId[];
} & React.ImgHTMLAttributes<HTMLImageElement>) => {
  const { data: blobs, isLoading, error } = useMediaBlob({ fileId });
  return isLoading ? (
    <Skeleton
      count={typeof fileId === 'string' ? 1 : fileId?.length}
      className={classNames('mb-3', className)}
      height={200}
      {...rest}
    />
  ) : error ? (
    <ImageLoadError className={classNames('mb-3', className)} {...rest} />
  ) : (
    blobs?.map(file => {
      return (
        <LightboxImage
          key={file.preview}
          src={file.preview}
          {...rest}
          className={classNames('mb-3', className)}
        />
      );
    })
  );
};
export const ImageLoadError = ({
  className,
  onRetry,
  ...rest
}: { className?: string; onRetry?: () => void } & any) => {
  return (
    <div {...rest} className={classNames('text-muted bg-200', className)}>
      <div className="d-flex flex-center flex-column h-100 w-100">
        <FontAwesomeIcon className="fs-5" icon="exclamation-triangle" />
        <span className="m-2 text-muted">Error loading image</span>
        {onRetry && (
          <Button variant="link" onClick={onRetry}>
            Try again
          </Button>
        )}
      </div>
    </div>
  );
};
