import React from 'react';
import useJobRoles from './useJobRoles';
import { ResponsiveModal } from 'components/common/Modals';
import { Button, Modal } from 'react-bootstrap';
import { defaultJobRoleValues, JobRoleForm } from './JobRoleDetail';
import { FormProvider, useForm } from 'react-hook-form';
import { JobRole } from 'apis/flex/hr';
import LoadingButton from 'components/common/LoadingButton';
import { FormPlaceholder } from 'components/wizard/FormWizard';
export default ({
  show,
  setShow,
  id,
  onUpserted,
  defaultValues = defaultJobRoleValues
}: {
  id?: number;
  onUpserted?: (d: JobRole) => void;
  show: boolean;
  setShow: (show: boolean) => void;
  defaultValues?: Partial<JobRole>;
}) => {
  const { upsert, isUpserting, data, isLoading } = useJobRoles({
    id,
    select: d => d[0]
  });
  const methods = useForm<JobRole>({
    defaultValues: defaultValues,
    values: data
  });
  return (
    <ResponsiveModal show={show} onHide={() => setShow(false)}>
      <FormProvider {...methods}>
        <Modal.Body>
          {isLoading ? (
            <FormPlaceholder />
          ) : (
            <>
              <JobRoleForm />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <LoadingButton
            loading={isUpserting}
            onClick={methods.handleSubmit(v =>
              upsert(v, d => onUpserted && onUpserted(d))
            )}
          >
            Save
          </LoadingButton>
        </Modal.Footer>
      </FormProvider>
    </ResponsiveModal>
  );
};
