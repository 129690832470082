import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button, Card, Dropdown, ListGroup } from 'react-bootstrap';
import { isIterableArray } from 'helpers/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getUserNotifications } from 'apis/flex/notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Notification, {
  NotificationPlaceholder
} from 'components/notification/Notification';
import useActionNotification from 'components/notification/useActionNotification';
import useNotificationCounts from 'components/notification/useNotificationCounts';
import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
export const useInfiniteNotifications = () => {
  return useInfiniteQuery({
    queryKey: ['notifications'],
    queryFn: ({ pageParam = 1 }) => getUserNotifications(pageParam),
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length === 10 ? allPages.length + 1 : undefined
  });
};
const NotificationMenu = ({ isOpen, setIsOpen }) => {
  const { data, isFetching, isFetchingNextPage, fetchNextPage } =
    useInfiniteNotifications();
  const allData = data?.pages?.flat() || [];
  const newNotifications = allData.filter(d => !d.actioned);
  const earlierNotifications = allData.filter(d => d.actioned);
  const { setSeen, setActioned } = useActionNotification();
  // State

  useEffect(() => {
    const unseenNotifications = allData.filter(d => !d.seen);
    setSeen({ id: unseenNotifications.map(notification => notification.id) });
  }, [data]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  const markAllRead = e => {
    e.preventDefault();
    setActioned({
      id: newNotifications.flatMap(notification => notification.ids)
    });
    setIsOpen(false);
  };
  const handleNotificationClick = notification => {
    return e => {
      e.preventDefault();
      setActioned({ id: [notification.id] });
      setIsOpen(!isOpen);
    };
  };
  const listRef = useRef(null);
  const handleScroll = isFetchingNextPage => () => {
    const container = listRef.current;
    if (
      //scrolled to bottom
      container.scrollTop + container.clientHeight === container.scrollHeight &&
      !isFetchingNextPage
    ) {
      fetchNextPage();
    }
  };
  useEffect(() => {
    listRef.current?.addEventListener(
      'scroll',
      handleScroll(isFetchingNextPage)
    );
    return () =>
      listRef.current?.removeEventListener(
        'scroll',
        handleScroll(isFetchingNextPage)
      );
  }, [listRef.current, isFetchingNextPage]);
  return (
    <Card
      className="dropdown-menu-notification dropdown-menu-end shadow-none"
      style={{ maxWidth: '20rem' }}
    >
      <FalconCardHeader
        className="card-header"
        title="Notifications"
        titleTag="h6"
        light={false}
        endEl={
          <Button
            variant="link"
            size="sm"
            className="card-link px-0 fw-normal"
            onClick={markAllRead}
          >
            Mark all as read
          </Button>
        }
      />
      <ListGroup
        variant="flush"
        className="fw-normal fs--1 scrollbar"
        ref={listRef}
        style={{ maxHeight: '19rem' }}
      >
        <div className="list-group-title">NEW</div>{' '}
        {isFetching && !isFetchingNextPage && !data?.pages?.[0]?.length
          ? [0, 0, 0, 0].map((_, i) => (
              <ListGroup.Item key={i}>
                <NotificationPlaceholder flush />
              </ListGroup.Item>
            ))
          : null}
        {isIterableArray(newNotifications) &&
          newNotifications.map(notification => (
            <ListGroup.Item
              key={notification.id}
              onClick={handleNotificationClick(notification)}
            >
              <Notification notification={notification} flush>
                {notification.messages?.title}
              </Notification>
            </ListGroup.Item>
          ))}
        <div className="list-group-title">EARLIER</div>
        {isIterableArray(earlierNotifications) &&
          earlierNotifications.map(notification => (
            <ListGroup.Item
              key={notification.id}
              onClick={handleNotificationClick(notification)}
            >
              <Notification notification={notification} flush>
                {notification.messages?.title}
              </Notification>
            </ListGroup.Item>
          ))}
        {(isFetching || isFetchingNextPage) && !!data?.pages?.[0]?.length ? (
          <ListGroup.Item>
            <NotificationPlaceholder flush />
          </ListGroup.Item>
        ) : null}
      </ListGroup>

      <FalconCardFooterLink
        title="All Notifications"
        to="/my/notifications"
        size="sm"
      />
    </Card>
  );
};
const NotificationDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: counts } = useNotificationCounts();
  return (
    <Dropdown
      navbar={true}
      as="li"
      show={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
    >
      <Dropdown.Toggle
        variant="link"
        bsPrefix="toggle"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-primary':
            counts && Object.keys(counts).reduce((a, b) => a + counts[b], 0) > 0
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        {isOpen && <NotificationMenu isOpen={isOpen} setIsOpen={setIsOpen} />}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
