import React, { forwardRef, ReactNode } from 'react';

export const Subtitle = ({ children }: any) => <h6>{children}</h6>;
export const Body = ({ children }: any) => (
  <p className="mb-0 ps-3">{children}</p>
);
export const Separator = () => <hr className="my-4" />;
export const Section = forwardRef<
  HTMLDivElement,
  { title: ReactNode; children: ReactNode; id?: string }
>(({ children, title, id }, ref) => (
  <div ref={ref} id={id}>
    <Subtitle>{title}</Subtitle>
    <Body>{children}</Body>
    <Separator />
  </div>
));
