import React from 'react';
import useUsers from '../../hooks/useUsers';
import ItemSelector, {
  ItemSelectorProps,
  SelectorItem
} from 'components/common/ItemSelector';
import { User } from '../../types';
import ItemSelectorGroup, {
  ItemSelectorGroupProps
} from 'components/common/ItemSelectorGroup';
import { domainConfigs } from 'components/notification/config';
import DomainItemIcon, {
  DomainIconItemProps
} from 'components/common/DomainItemIcon';
import { CrudFilters } from 'hooks/useDefaultCrud';
import { UserView } from 'apis/flex/users';
import {
  getDomainItemSelector,
  getSelectedDomainItems
} from 'components/common/DomainItemSelector';
const useUserItems = ({
  userFilter = () => true,
  mutator = () => null,
  filters
}: {
  filters?: CrudFilters<UserView>;
  userFilter?: (user: any) => boolean;
  name?: string;
  mutator?: (user: User) => any;
} = {}) => {
  return useUsers<SelectorItem[]>({
    getPublic: true,
    useFilter: true,
    staleTime: 1000 * 60 * 2,
    filters,
    select: d =>
      d
        // .filter(u => showInactive || u.isActive)
        .filter(userFilter)
        .map(u => ({
          label: u.firstName + ' ' + u.surname,
          value: u.id,
          avatar: u.avatar,
          info: {
            // email: u.email,
            jobTitle: u.jobTitle
          },
          group: u.departmentName,
          ...mutator(u)
        }))
  });
};
export type DomainItemSelectorProps = {
  filter?: (user: any) => boolean;
  name?: string;
  mutator?: (user: User) => any;
} & Omit<ItemSelectorProps, 'data' | 'name'>;
export default getDomainItemSelector<User>('user', { onNewClick: false });

export type SelectedDomainItemsProps = Omit<
  ItemSelectorGroupProps,
  'data' | 'color' | 'icon'
> & {
  ids: number[];
  filter?: (user: any) => boolean;
};
export const SelectedUsers = getSelectedDomainItems('user');
export type DomainIconProps = {
  ids?: number[];
  filter?: (user: any) => boolean;
} & Omit<DomainIconItemProps, 'data' | 'domain'>;
export const UsersIcon = ({ ids = [], filter, ...rest }: DomainIconProps) => {
  const { data } = useUserItems({
    filters: { id: ids }
  });
  return <DomainItemIcon domain="user" data={data} {...rest} />;
};
