import WizardInput from 'components/wizard/WizardInput';
import useMediaForm from 'hooks/useMediaForm';
import { FormProvider } from 'react-hook-form';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import React from 'react';
import useFileLoader from 'components/wizard/hooks/useFileLoader';
import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import is from 'is_js';
import { WizardFilePluginProps } from '../wizard/inputs/WizardFile';
import { RoleDomain } from 'apis/flex/users';
import useFileUpload, {
  FileProcessingSettings,
  FileUploadOptions,
  FileWithPreview
} from 'hooks/useFileUpload';
import FileUploader from 'components/files/FileUploader';
import { FileId } from 'apis/types';
import useArray from 'hooks/useArray';

export const UploadableMedia = ({
  value,
  onChange,
  children,
  settings,
  authItemCollection,
  authItemId,
  type,
  disabled,
  isPublic
}: {
  type: 'image' | 'video' | 'audio';
  value?: FileId[];
  onChange?: (fileId: FileId[]) => void;
  // inputProps?: any;
  settings?: FileProcessingSettings;
  authItemCollection?: RoleDomain;
  authItemId?: number;
  // className?: string;
  // name?: string;
  // onClick?: any;
  children?: any;
  // upload?: boolean;
  disabled?: boolean;
  isPublic?: boolean;
}) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const fileId = useArray(value);
  return (
    <>
      <UploadOnClick
        type={type}
        options={{ settings, authItemCollection, authItemId, isPublic }}
        value={fileId?.[0]}
        onChange={fileIds => onChange?.(fileIds)}
        disabled={disabled}
      >
        {children}
      </UploadOnClick>

      <Modal show={confirmModal} onHide={() => setConfirmModal(false)}>
        <Modal.Body>
          Are you sure you want to reset this back to default?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" onClick={() => setConfirmModal(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onChange(null);
              setConfirmModal(false);
            }}
          >
            Reset
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const UploadOnClick = ({
  value: inputSrc,
  onChange,
  children,
  options,
  type,
  disabled
}: {
  value: string;
  onChange: (fileId: FileId[]) => void;
  children:
    | ReactNode
    | (({ isLoading, src }: { isLoading: boolean; src: string }) => ReactNode);
  options: FileUploadOptions;
  type: 'image' | 'video' | 'audio';
  disabled?: boolean;
}) => {
  const { mutate, isLoading } = useFileUpload({
    ...options,
    onSuccess: d => onChange(d['0']),
    onError: () => {}
  });
  const accepts = {
    image: { ['image/*']: [] },
    video: { ['video/*']: [] },
    audio: { ['audio/*']: [] }
  };
  const [src, setSrc] = useState(null);
  const handleUpload = (files: FileWithPreview[]) => {
    if (disabled) return;
    mutate({
      files
    });
    setSrc(files[0].preview);
  };
  useEffect(() => {
    if (inputSrc) setSrc(inputSrc);
  }, [inputSrc]);
  console.log('src', inputSrc, src);
  return (
    <FileUploader
      dropzoneProps={{ maxFiles: 1 }}
      accept={accepts[type]}
      onChange={handleUpload}
    >
      {typeof children === 'function' ? children({ isLoading, src }) : children}
    </FileUploader>
  );
};
