import React, { useEffect } from 'react';
import { ResponsiveModal } from 'components/common/Modals';
import { Button, Card, CloseButton, Form, Modal } from 'react-bootstrap';
import { useState } from 'react';
import PrescreeningWizard from './PrescreeningWizard';
import Success from 'components/wizard/Success';
import { Screening } from 'apis/flex/hr';
import AuthorStamp from 'components/common/AuthorStamp';
import Screenedout from 'components/wizard/Screenedout';
import { useOneIdRedirectData } from './OneIdRightToWorkCheck';

export default ({ screening }: { screening: Screening }) => {
  return (
    <ResponsiveModal wide backdrop="static" show>
      <Modal.Header>
        <Modal.Title>Documents</Modal.Title>
        <h6>
          <AuthorStamp
            label="Requested by"
            userId={screening?.addedBy}
            date={screening?.addedDate}
          />
        </h6>
      </Modal.Header>
      <Modal.Body>
        <Prescreening screening={screening} />
      </Modal.Body>
    </ResponsiveModal>
  );
};
export const Prescreening = ({ screening }: { screening: Screening }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isReviewing, setIsReviewing] = useState(false);
  const extId = useOneIdRedirectData();
  useEffect(() => {
    if (extId && !isInProgress && !isReviewing) {
      setIsInProgress(true);
    }
  }, [extId]);
  return isInProgress || screening?.checks?.some(c => c.submittedDate) ? (
    <>
      {isReviewing ? (
        <>
          <CloseButton
            className="position-absolute top-0 end-0 m-2"
            onClick={() => setIsReviewing(false)}
          />
          <PrescreeningWizard review id={screening?.id} />
        </>
      ) : screening?.checks?.some(c => c.rejectedDate) ? (
        <>
          <Screenedout
            title="Document check failed"
            description="Unfortunately, the documents you submitted have failed our checks. Please contact your supervisor for more information."
          />
          {screening.addedBy === screening.userId && (
            <Button
              className={'mt-4 mx-auto d-block'}
              onClick={() => setIsReviewing(true)}
            >
              Review
            </Button>
          )}
        </>
      ) : isSubmitted || screening?.checks?.every(c => c.submittedDate) ? (
        <>
          <Success
            className="mb-4"
            title="Thank you"
            description="Your documents have been submitted. We will notify you by email when checks are complete"
          />

          {screening.addedBy === screening.userId && (
            <Button
              className={'mt-4 mx-auto d-block'}
              onClick={() => setIsReviewing(true)}
            >
              Review
            </Button>
          )}
        </>
      ) : (
        <PrescreeningWizard
          onSubmitted={() => setIsSubmitted(true)}
          id={screening?.id}
        />
      )}
    </>
  ) : (
    <div className="p-4">
      <Form.Label className="mb-3 fs-0 text-center">
        It looks like we need a few documents from you. These documents need to
        be checked internally before you can continue. But don't worry! As long
        as you provide the right information, it shouldn't take long...
      </Form.Label>
      <Button
        className={'mt-4 mx-auto d-block'}
        onClick={() => setIsInProgress(true)}
      >
        Begin
      </Button>
    </div>
  );
};
export const PrescreeningCard = ({ screening }) => (
  <Card>
    <Card.Header>
      <Card.Title>ID Documents</Card.Title>
      <h6>
        <AuthorStamp
          label="Requested by"
          userId={screening?.addedBy}
          date={screening?.addedDate}
        />
      </h6>
    </Card.Header>
    <Card.Body>
      <Prescreening screening={screening} />
    </Card.Body>
  </Card>
);
