import {
  faFolderPlus,
  faPlus,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AiButton } from 'components/common/AIFormAssistant';
import DragHandle from 'components/common/DragHandle';
import Flex from 'components/common/Flex';
import WizardInput, { WizardInputOptions } from 'components/wizard/WizardInput';
import React, { ReactNode, useEffect, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder
} from 'react-beautiful-dnd';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

export default ({
  name,
  instructions = '',
  label = 'Options',
  index,
  ...rest
}: OptionGroupProps & {
  instructions?: ReactNode;
  label?: ReactNode;
  index: number;
}) => {
  const questionText = useWatch({ name: `questions.${index}.questionText` });
  const multiple = useWatch({ name: `questions.${index}.multiple` });
  const options = useWatch({ name: `questions.${index}.options` });
  return (
    <div className="mb-3">
      <Form.Label>{label}</Form.Label>
      {instructions && (
        <div className="form-text mb-3 mt-n2">{instructions}</div>
      )}
      <AiButton
        name={name}
        content="json"
        jsonShape={{
          label: 'string',
          value: 'string'
        }}
        preprompt={`Create an array of ${
          multiple ? 'multi-select' : 'single-select'
        } form options to answer the question "${questionText}".${
          options?.filter(v => v?.value && v?.label)?.length
            ? ` My existing options are: ${JSON.stringify(options)}.`
            : ''
        }`}
        buttonProps={{ className: 'mb-3' }}
      >
        Generate
      </AiButton>
      <OptionGroup {...rest} name={name} />
    </div>
  );
};
type OptionGroupProps = {
  name: string;
  level?: number;
  onRemove?: any;
  optionPlaceholder?: string;
  groupPlaceholder?: string;
};
const OptionGroup = ({
  name,
  level = 0,
  onRemove = null,
  optionPlaceholder = 'Option label',
  groupPlaceholder = 'Group label'
}) => {
  const prefix = name;
  const defaultOption = useMemo(
    () => ({ value: crypto.randomUUID(), label: '' }),
    []
  );
  const { fields, remove, replace, insert, move } = useFieldArray<
    { options: WizardInputOptions },
    'options'
  >({
    name: prefix
  });
  const { setFocus, getValues } = useFormContext();
  useEffect(() => {
    setFocus(`${prefix}.${fields.length - 1}`);
  }, [fields]);
  const handleAdd = i => {
    insert(i + 1, defaultOption);
  };
  const handleRemove = i => {
    //blank it in case that same index gets re-added and the form data hasn't been updated
    // update(i, '');
    remove(i);
  };
  const optValues = useWatch({ name: `${prefix}` });
  useEffect(() => {
    console.log('checking optValues', optValues, fields);
    if (
      // (optValues?.length !== fields?.length || !optValues?.length) &&
      !optValues?.length &&
      level === 0
    ) {
      // console.log(
      //   'syncing fields because',
      //   optValues?.length,
      //   '!=',
      //   fields?.length,
      //   'or',
      //   optValues?.length,
      //   '==0'
      // );
      // if (optValues?.length) {
      //   const mapped = optValues?.map(o =>
      //     typeof o === 'string' && o ? { label: o, value: o } : o
      //   );
      //   console.log('resetting with optValues', mapped);
      //   return replace(mapped);
      // }
      console.log('resetting with default option', defaultOption);
      return replace([defaultOption]);

      // replace(
      //   optValues?.length === 0 && level === 0
      //     ? [defaultOption]
      //     : optValues?.map(o =>
      //         typeof o === 'string' && o ? { label: o, value: o } : o
      //       ) || [defaultOption]
      // );
    }
    if (level > 0 && optValues?.length === 0) {
      onRemove?.();
    }
  }, [optValues, fields]);
  const handleAddGroup = i => {
    insert(i + 1, { label: '', options: [defaultOption] });
  };
  const droppableId = `${prefix}`;
  const handleDrag: OnDragEndResponder = (result, provided) => {
    if (
      !result.destination ||
      (result.destination.droppableId !== droppableId &&
        result.source.droppableId !== droppableId)
    )
      return;
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    )
      return;
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.source.droppableId === droppableId
    ) {
      move(result.source.index, result.destination.index);
      return;
    }
    if (result.source.droppableId === droppableId) {
      handleRemove(result.source.index);
      return;
    }
    const data = JSON.parse(result.draggableId);
    insert(result.source.index, data);
  };
  return (
    <DragDropContext onDragEnd={handleDrag}>
      <Droppable
        direction="vertical"
        droppableId={droppableId}
        type="droppableItem"
      >
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {/* {provided.placeholder} */}
            {fields.map((field, i) => (
              <OptionField
                key={field.id}
                handleRemove={handleRemove}
                handleAdd={handleAdd}
                handleAddGroup={handleAddGroup}
                getValues={getValues}
                prefix={prefix}
                i={i}
                level={level}
                field={field}
                optionPlaceholder={optionPlaceholder}
                groupPlaceholder={groupPlaceholder}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
const OptionField = ({
  handleRemove,
  handleAdd,
  handleAddGroup,
  getValues,
  prefix,
  i,
  level,
  field,
  optionPlaceholder,
  groupPlaceholder
}) => {
  const { setFocus } = useFormContext();
  const inputProps = {
    handleRemove: () => handleRemove(i),
    handleAdd: () => handleAdd(i),
    getFields: () => getValues(prefix),
    handleAddGroup: () => handleAddGroup(i),
    handleNext: () => setFocus(`${prefix}.${i + 1}`),
    handlePrev: () => setFocus(`${prefix}.${i - 1}`)
  };
  const draggableId = useMemo(() => JSON.stringify(field), [field]);
  return (
    <Draggable draggableId={draggableId} index={i}>
      {provided => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <OptionRow
              index={i}
              {...inputProps}
              level={level}
              dragHandleProps={provided.dragHandleProps}
            >
              {typeof field !== 'string' && field.options ? (
                <GroupInput
                  name={`${prefix}.${i}`}
                  level={level}
                  groupPlaceholder={groupPlaceholder}
                  optionPlaceholder={optionPlaceholder}
                  {...inputProps}
                />
              ) : (
                <OptionInput
                  name={`${prefix}.${i}.label`}
                  placeholder={optionPlaceholder}
                  {...inputProps}
                />
              )}
            </OptionRow>
          </div>
        );
      }}
    </Draggable>
  );
};
const OptionRow = ({
  index,
  handleRemove,
  handleAdd,
  handleAddGroup,
  children,
  level,
  dragHandleProps
}) => {
  return (
    <Flex
      className={'w-100 mb-1 gap-2'}
      justifyContent={'between'}
      alignItems={'start'}
    >
      {/* <span className="me-1 mt-1">{index + 1}. </span> */}
      <DragHandle dragHandleProps={dragHandleProps} />
      <div className="flex-1">{children}</div>
      <ButtonGroup>
        <Button
          variant="falcon-default"
          className="h-100"
          title="Add option"
          size="sm"
          onClick={handleAdd}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
        <Button
          onClick={handleAddGroup}
          title="Add group"
          variant="falcon-default"
          className="h-100"
          size="sm"
        >
          <FontAwesomeIcon icon={faFolderPlus} />
        </Button>
        {(index > 0 || level > 0) && (
          <Button
            title="Remove option"
            variant="falcon-danger"
            size="sm"
            className="h-100"
            onClick={() => handleRemove(index)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        )}
      </ButtonGroup>
    </Flex>
  );
};
const GroupInput = ({
  name,
  handleAdd,
  handleRemove,
  handleAddGroup,
  getFields,
  level,
  groupPlaceholder,
  handlePrev,
  handleNext,
  ...rest
}) => {
  return (
    <div className="flex-1">
      <OptionInput
        handleAdd={handleAdd}
        handleRemove={handleRemove}
        handleAddGroup={handleAddGroup}
        getFields={getFields}
        name={name + '.label'}
        placeholder={groupPlaceholder}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
      <div
        className={'bg-' + (level + 1) * 200 + ' p-3 rounded-3 rounded-top-0'}
      >
        <OptionGroup
          name={name + '.options'}
          level={level + 1}
          onRemove={handleRemove}
          {...rest}
        />
      </div>
    </div>
  );
};
const OptionInput = ({
  name,
  handleAdd,
  handleRemove,
  handleNext,
  handlePrev,
  getFields,
  ...rest
}) => {
  return (
    <>
      <WizardInput
        name={name}
        hideLabel
        formGroupProps={{ className: 'flex-1 mb-auto' }}
        formControlProps={{
          className: 'form-control-sm',
          onKeyDown: e => {
            console.log(e.key);
            if (e.key === 'Enter') {
              e.preventDefault();
              handleAdd();
            }
            if (e.key === 'ArrowDown') {
              e.preventDefault();
              handleNext();
            }
            if (e.key === 'ArrowUp') {
              e.preventDefault();
              handlePrev();
            }
          }
        }}
        registerProps={{
          onBlur: e => {
            if (e.target.value === '' && getFields().length > 1) {
              handleRemove();
            }
          }
        }}
        {...rest}
      />
    </>
  );
};
