import { faCamera, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { IDCheck } from 'apis/flex/hr';
import { FileId } from 'apis/types';
import FormWizard from 'components/wizard/FormWizard';
import WizardInput from 'components/wizard/WizardInput';
import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { FiCamera } from 'react-icons/fi';
import * as faceapi from 'face-api.js';
import { DetectedFace } from 'components/common/ImgAnalysed';
import CircleProgress from 'components/common/CircleProgress';

export const IDDocumentForm = () => {
  const { setValue, setError, clearErrors } = useFormContext();
  return (
    <>
      {/* <WizardInput
        type="select"
        options={docTypes}
        name={`docType`}
        label="Document type"
      /> */}
      <WizardInput
        type="image"
        pluginProps={{
          placeholder: <FiCamera />,
          height: 300,
          faceDetectorProps: {
            drawLandmarks: false
          },
          onFaceDetected: faces => {
            if (faces.length === 0) {
              return setError('photoId', {
                type: 'manual',
                message:
                  'We could not detect any faces in this document. Please ensure you are uploading a photo ID document, and that the photo of your face is clear.'
              });
            } else {
              clearErrors('photoId');
            }
            const labelled = new faceapi.LabeledFaceDescriptors(
              'employee',
              faces.map(d => d.descriptor)
            );
            setValue('faceSignature', labelled.descriptors);
          }
        }}
        name={`photoId`}
        formControlProps={{ width: 'auto' }}
        label="Front"
        instruction={
          'Please take a picture of the front of your document. Ensure all corners are visible and there is no blur.'
        }
      />
    </>
  );
};
export const LivenessPage = ({ review = false }) => {
  const { getValues, setValue } = useFormContext();
  const handleDetection = (faces: DetectedFace[]) => {
    if ((getValues('likenessScore') || 0) < faces[0]?.likeness) {
      setValue('likenessScore', faces[0]?.likeness);
    }
  };
  const faceFromId = useWatch({ name: 'faceSignature' });
  const score = getValues('likenessScore') * 100;
  return (
    <>
      <WizardInput
        type="liveness"
        name="livenessFileId"
        pluginProps={{
          reference: faceFromId,
          onDetection: handleDetection
        }}
        label="We now need to analyse your face to confirm your identity."
        instruction="Please click the button below to begin."
      />
      {review && (
        <div className="mt-2 text-center">
          <h5>
            Likeness score:
            <CircleProgress
              now={score}
              color={score > 70 ? 'success' : score > 50 ? 'warning' : 'danger'}
              className="ms-2"
            />
          </h5>
        </div>
      )}
    </>
  );
};
export default ({
  onSubmit,
  review = false,
  check
}: {
  check?: IDCheck;
  onSubmit?: (
    data: {
      photoId: FileId[];
      liveness: FileId;
    },
    done: () => void
  ) => void;
  review?: boolean;
}) => {
  return (
    <FormWizard
      fluid
      allowPrev
      data={check}
      readOnly={review}
      onSubmit={({ data, done }) => (onSubmit ? onSubmit(data, done) : done())}
    >
      <FormWizard.Page icon={faFileImage} label="Documents">
        <Form.Label>
          Please upload a photo of your preferred identity document. This must
          be a passport, driving license, or official photo ID, and must not be
          expired
        </Form.Label>
        <IDDocumentForm />
      </FormWizard.Page>
      <FormWizard.Page icon={faCamera} label="Liveness check">
        <LivenessPage review={review} />
      </FormWizard.Page>
    </FormWizard>
  );
};
