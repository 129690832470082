import React, { useEffect } from 'react';
import WizardInput, {
  WizardInputComponent,
  WizardInputOptions
} from '../WizardInput';
import {
  Button,
  Col,
  Container,
  Form,
  ProgressBar,
  Row
} from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import SmallCheck from '../SmallCheck';

const WizardQuickfire: WizardInputComponent<{
  // options: { label: string; value: any }[][];
  msPerGroup: number;
}> = ({ renderProps, pluginProps, options }) => {
  const { form, input, label } = renderProps;
  const handleChange = (val: any[]) => {
    // const current = form.field.value || [];
    // current[index] = val;
    form.field.onChange(val);
  };
  const groupedOptions = options.filter(
    v => typeof v !== 'string' && v.options
  ) as { options: WizardInputOptions; label: string }[];
  const [started, setStarted] = React.useState(false);
  return (
    <>
      {label}
      {input(
        <div className="mb-3 p-4 rounded-5 w-100 d-flex align-items-center justify-content-center">
          {form.field.value?.length ? (
            <h5>
              <SmallCheck size="lg" className="me-3" />
              Done!
            </h5>
          ) : started ? (
            <Quickfire
              setValue={handleChange}
              optionGroups={groupedOptions}
              msPerGroup={pluginProps.msPerGroup}
            />
          ) : (
            <>
              {/* //render a hidden select to ensure the coponent loads before the test starts */}
              <WizardInput
                type="radio"
                name="hidden"
                options={[]}
                registerProps={{ shouldUnregister: true }}
                formGroupProps={{ className: 'd-none' }}
              />
              <Button variant="falcon-primary" onClick={() => setStarted(true)}>
                Begin
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};

const Quickfire = ({
  setValue,
  optionGroups,
  msPerGroup = 2000
}: {
  setValue: (value: any[]) => void;
  optionGroups: { options: WizardInputOptions; label: string }[];
  msPerGroup?: number;
}) => {
  const [index, setIndex] = React.useState(0);
  const methods = useForm();
  const handleFinish = () => {
    setValue(methods.getValues('values'));
  };
  useEffect(() => {
    if (index >= optionGroups.length) {
      handleFinish();
    }
    refreshTimer();
  }, [index]);
  const [timerToggle, setTimerToggle] = React.useState(false);
  const refreshTimer = () => {
    setTimerToggle(!timerToggle);
  };
  const handleNext = () => {
    setIndex(i => i + 1);
    //for testing
    // setIndex(i => (i === 1 ? 0 : 1));
  };
  return (
    <div className="w-100">
      <FormProvider {...methods}>
        <Row className="align-items-center justify-content-center row">
          <Col xs={10} md={7} xl={4}>
            <AnimatePresence initial={false} mode="popLayout">
              {optionGroups.map(
                (group, i) =>
                  index === i && (
                    <motion.div
                      id={i.toString()}
                      transition={{ duration: 0.1 }}
                      initial={{ x: -400, opacity: 0 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{
                        opacity: 0,
                        x: 400
                      }}
                      key={i.toString()}
                    >
                      {group.options?.length ? (
                        <WizardInput
                          type="radio"
                          pluginProps={{ button: true }}
                          name={'values.' + i}
                          options={group.options}
                          label={
                            typeof group.label === 'string' ? group.label : ''
                          }
                          registerProps={{
                            required: false,
                            onChange: e => {
                              handleNext();
                            }
                          }}
                        />
                      ) : (
                        <Form.Label>
                          {typeof group.label === 'string' ? group.label : ''}
                        </Form.Label>
                      )}
                    </motion.div>
                  )
              )}
            </AnimatePresence>
          </Col>
        </Row>
      </FormProvider>
      <Timer key={timerToggle.toString()} ms={msPerGroup} onEnd={handleNext} />
    </div>
  );
};
const Timer = ({ ms, onEnd }) => {
  const [lastChange, setLastChange] = React.useState<number>(Date.now());
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      // console.log('checking progress', lastChange + ms, Date.now());
      setProgress(Date.now() - lastChange);
    }, 50); // Check every 100ms (adjust as needed)

    return () => clearInterval(interval); // Cleanup on unmount
  }, [lastChange, ms, onEnd]);
  useEffect(() => {
    if (progress >= ms) {
      onEnd();
      setLastChange(Date.now()); // Reset the timer
    }
  }, [progress]);
  return (
    <div
      className="overflow-hidden w-100 bg-200 rounded-2"
      style={{ height: 10 }}
    >
      <div
        className="bg-primary h-100"
        style={{ width: `${(progress / ms) * 100}%`, transition: 'width 0.1s' }}
      ></div>
    </div>
    // <ProgressBar
    //   animated={false}
    //   style={{
    //     height: '10px',
    //     transitionDelay: '0s',
    //     transitionDuration: '0s'
    //   }}
    //   //without this it never gets to the end as the animation takes too long
    //   now={progress}
    //   min={0}
    //   max={ms}
    // />
  );
};
export default WizardQuickfire;
