import React, { ReactNode } from 'react';
import { Card, Col } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import PageHeader from '../PageHeader';
import Background from '../Background';
import { UploadableMedia } from '../UploadableMedia';
import profileImg from 'assets/img/team/avatar.png';
import coverSrc from 'assets/img/generic/training.jpg';
import Skeleton from 'react-loading-skeleton';
import { getMediaStreamUrl } from 'helpers/utils';
import classNames from 'classnames';
import { BottomBarAction, Tag } from './DetailPage';
import { ActionButtons } from '../BottomBar';
import { AnimatePresence, motion } from 'framer-motion';
import { EventPrefix } from 'apis/flex/notifications';
import corner from 'assets/img/illustrations/corner-5.png';
import { FileId } from 'apis/types';
import { RoleDomain } from 'apis/flex/users';
const PageAvatar = ({
  fieldName,
  disabled,
  defaultImg,
  isLoading: pageLoading,
  onUpload,
  authItemCollection,
  authItemId
}: PageImageConfig) => {
  const { setValue } = useFormContext();
  const avatar = useWatch({ name: fieldName });
  const handleNewUpload = src => {
    onUpload?.(src);
    setValue(fieldName, src);
  };
  return (
    <div
      className="avatar avatar-5xl ms-3 mt-n8 position-absolute z-2"
      style={{ top: -10, left: 4 }}
    >
      <UploadableMedia
        type="image"
        value={avatar && getMediaStreamUrl(avatar)}
        onChange={handleNewUpload}
        disabled={disabled}
        settings={{ width: 170, cropHeight: 170 }}
        authItemCollection={authItemCollection}
        authItemId={authItemId}
        isPublic
      >
        {({ isLoading, src }) =>
          isLoading || pageLoading ? (
            <Skeleton className="h-100" circle />
          ) : (
            <img
              className={'img-thumbnail shadow rounded-circle'}
              src={src || defaultImg || profileImg}
              alt=""
            />
          )
        }
      </UploadableMedia>
    </div>
  );
};
const PageBackground = ({
  fieldName,
  disabled,
  defaultImg,
  isLoading: pageLoading,
  onUpload,
  authItemCollection,
  authItemId
}: PageImageConfig) => {
  const { setValue } = useFormContext();
  const bg = useWatch({ name: fieldName });
  const handleNewUpload = src => {
    setValue(fieldName, src);
    onUpload?.(src);
  };
  return (
    <div className="z-1">
      <UploadableMedia
        type="image"
        value={bg && getMediaStreamUrl(bg)}
        onChange={handleNewUpload}
        disabled={disabled}
        settings={{ width: 1920, cropHeight: 500 }}
        authItemCollection={authItemCollection}
        authItemId={authItemId}
        isPublic
      >
        {({ isLoading, src }) => (
          <Background
            isLoading={isLoading || pageLoading}
            image={src || defaultImg || coverSrc}
            className="rounded-3"
          />
        )}
      </UploadableMedia>
    </div>
  );
};
const PageHeaderImages = ({ bg, isLoading = false }) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: '-100%' }}
        animate={{ y: '0' }}
        exit={{ y: '-100%' }}
        whileHover={{ zIndex: 2 }}
        id="page-header-images"
      >
        <Card className="mb-0">
          <Card.Header className={'position-relative min-vh-25'}>
            {bg && <PageBackground {...bg} isLoading={isLoading} />}
          </Card.Header>
        </Card>
      </motion.div>
    </AnimatePresence>
  );
};
export type PageImageConfig = {
  fieldName: string;
  disabled?: boolean;
  defaultImg?: any;
  isLoading?: boolean;
  onUpload?: (fileId: FileId) => void;
  authItemCollection?: RoleDomain;
  authItemId?: number;
};
const DetailHeader = ({
  description,
  title,
  isLoading,
  buttons = true,
  avatar,
  background,
  tags,
  createdBy,
  createdDate,
  domain,
  actions,
  itemId
}: {
  description?: ReactNode;
  title: string;
  isLoading: boolean;
  buttons?: boolean;
  avatar?: PageImageConfig;
  background?: PageImageConfig;
  tags?: Tag[];
  createdBy?: number;
  createdDate?: Date;
  domain?: EventPrefix;
  actions?: BottomBarAction[];
  itemId?: number;
}) => {
  const headStyle =
    background || avatar ? { top: '-50px', marginBottom: '-50px' } : {};
  return (
    <>
      {background ? (
        <PageHeaderImages bg={background} isLoading={isLoading} />
      ) : null}

      <AnimatePresence>
        <motion.div
          id="page-header-content"
          initial={avatar || background ? { x: '-100%' } : { y: '-100%' }}
          animate={avatar || background ? { x: '0' } : { y: '0' }}
          exit={avatar || background ? { x: '-100%' } : { y: '-100%' }}
          // className="z-0"
          // style={{ z: 0 }}
        >
          {avatar && <PageAvatar {...avatar} isLoading={isLoading} />}
          <PageHeader
            className={classNames({
              'me-2 ms-2': !!background && !avatar,
              'pt-7': !!avatar
            })}
            style={{
              ...headStyle,
              borderTopLeftRadius: !!background && !!avatar ? 0 : undefined,
              borderTopRightRadius: !!background && !!avatar ? 0 : undefined,
              borderBottomRightRadius:
                !!background && !!avatar
                  ? 'var(--falcon-border-radius-lg)'
                  : undefined,
              borderBottomLeftRadius:
                !!background && !!avatar
                  ? 'var(--falcon-border-radius-lg)'
                  : undefined
            }}
            title={title}
            isLoading={isLoading}
            description={description}
            tags={tags}
            createdBy={createdBy}
            createdDate={createdDate}
            domain={domain}
            itemId={itemId}
          >
            {!buttons ? null : isLoading ? (
              <Skeleton height={50} width={150} />
            ) : (
              <ActionButtons actions={actions} drop="down" />
            )}
          </PageHeader>
        </motion.div>
      </AnimatePresence>
    </>
  );
};
export default DetailHeader;
