import React from 'react';
import useResourcePlan, { useResourceGroupSchedule } from './useResourcePlan';
import { addDays, format } from 'date-fns';
import { getDates } from 'helpers/utils';
import { groupBy } from 'lodash';
import { formatDateToISO } from 'helpers/dates';
import { AllocationEntry, refreshResourcePlan } from 'apis/flex/projects';
import {
  ColumnDef,
  createColumnHelper,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import ReactTable from 'components/common/advance-table-v2/ReactTable';
import { SelectedTargetGroups } from '../targetGroups/TargetGroupDetail';
import CustomTooltip from 'components/common/Tooltip';
import { SelectedEmployees } from 'components/app/hr/staff/widgets/EmployeeSelector';
import LoadingButton from 'components/common/LoadingButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
type DateCol = {
  units: number;
  allocations: AllocationEntry[];
};
export default ({
  resourceGroupId,
  startDate = new Date(),
  endDate = addDays(new Date(), 14)
}: {
  resourceGroupId: number;
  startDate?: Date;
  endDate?: Date;
}) => {
  const dates = getDates(startDate, endDate);
  const { data: schedule } = useResourceGroupSchedule(resourceGroupId);
  const resourcePlanFilters = [
    { first: 'date', second: formatDateToISO(startDate), comparitor: '>' },
    { first: 'date', second: formatDateToISO(endDate), comparitor: '<' },
    { first: 'resourceGroupId', second: resourceGroupId }
  ];
  const { data } = useResourcePlan({
    filters: resourcePlanFilters,
    select: (
      data
    ): {
      targetGroupId: number;
      dates: Record<string, DateCol>;
    }[] => {
      const formatted = data.map(d => ({
        ...d,
        date: formatDateToISO(d.date),
        targetGroupId: Number(d.targetGroupId),
        units: Number(d.units)
      }));
      const targetGroupIds = Object.keys(groupBy(formatted, 'targetGroupId'));
      return targetGroupIds.map(tgId => ({
        targetGroupId: Number(tgId),
        dates: dates.reduce((a, d) => {
          const units = formatted
            .filter(
              a =>
                a.targetGroupId === Number(tgId) &&
                a.date === formatDateToISO(d)
            )
            .reduce((a, b) => a + b.units, 0);
          a[formatDateToISO(d)] = {
            units,
            allocations: formatted.filter(
              a =>
                a.targetGroupId === Number(tgId) &&
                a.date === formatDateToISO(d)
            )
          };
          return a;
        }, {})
      }));
    }
  });
  const queryClient = useQueryClient();
  const { mutate: refresh, isLoading: isRefreshing } = useMutation({
    mutationFn: () => refreshResourcePlan({ startDate, endDate }),
    onSuccess: d => {
      queryClient.setQueryData(
        ['resourcePlan', resourcePlanFilters],
        d.filter(d => d.resourceGroupId === resourceGroupId)
      );
    }
  });
  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const columnHelper = React.useMemo(
    () =>
      createColumnHelper<{
        targetGroupId: number;
        dates: Record<string, DateCol>;
      }>(),
    []
  );
  const table = useReactTable({
    data: data || [],
    columns: [
      // columnHelper.accessor('resourceGroupId', {
      //   header: 'Overall',
      //   cell: info => info.getValue(),
      //   enableGrouping: true
      // }),
      // columnHelper.accessor('targetGroupId', {
      //   header: 'Project',
      //   cell: info => info.getValue(),
      //   enableGrouping: true
      // }),
      // columnHelper.accessor('employeeId', {
      //   header: 'Employee',
      //   cell: info => info.getValue(),
      //   enableGrouping: true
      // }),
      // columnHelper.accessor('units', {
      //   header: 'Allocation',
      //   cell: info => info.getValue()
      // })
      columnHelper.accessor('targetGroupId', {
        header: 'Target Group',
        cell: props => (
          <SelectedTargetGroups
            showIcon={false}
            size="xs"
            ids={[props.getValue()]}
          />
        ),
        size: 200,
        footer: () => (
          <div>
            Needed
            <br />
            Available
            <br />
            Allocated
          </div>
        )
      }),
      ...dates.map(
        (d): ColumnDef<any> =>
          columnHelper.accessor(`dates.${formatDateToISO(d)}`, {
            header: () => (
              <div>
                {format(new Date(d), 'do MMM')}
                <br />
                {format(new Date(d), 'EEE')}
              </div>
            ),
            sortingFn: (a, b) =>
              a.getValue<DateCol>(`${formatDateToISO(d)}`).units -
              b.getValue<DateCol>(`${formatDateToISO(d)}`).units,
            footer: info => {
              console.log('rows', info.table.getRowModel().rows);
              const total = info.table
                .getRowModel()
                .rows.reduce(
                  (sum, row) =>
                    sum +
                    (row.getValue<DateCol>(formatDateToISO(d))?.units || 0),
                  0
                );
              const dateSchedule = schedule?.find(
                s => formatDateToISO(s.date) === formatDateToISO(d)
              );
              return (
                <div>
                  {dateSchedule?.applied}
                  <br />
                  {dateSchedule?.available}
                  <br />
                  {total}
                </div>
              );
            },
            id: formatDateToISO(d),
            maxSize: 75,
            cell: ({ getValue }) => (
              <CustomTooltip
                content={
                  !!getValue()?.allocations?.length && (
                    <div style={{ width: '300px' }}>
                      {getValue()
                        ?.allocations.slice(0, 5)
                        .map((a: AllocationEntry) => (
                          <SelectedEmployees
                            key={a.id}
                            size="xs"
                            ids={[a.employeeId]}
                          />
                        ))}
                      {getValue()?.allocations.length > 5 && (
                        <div>+{getValue()?.allocations.length - 5} more</div>
                      )}
                    </div>
                  )
                }
              >
                <div>{getValue()?.units}</div>
              </CustomTooltip>
            )
          })
      )
    ],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enablePinning: true,
    // getSubRows: row => row.subRows,
    state: {
      expanded
    },
    onExpandedChange: setExpanded,
    debugTable: true
  });
  const cellProps = {
    className: 'px-1 text-center'
  };
  return (
    data && (
      <div>
        <div className="d-flex justify-content-end py-2">
          <LoadingButton
            size="sm"
            compact
            variant="falcon-default"
            loading={isRefreshing}
            onClick={() => refresh()}
          >
            <FontAwesomeIcon icon={faSync} />
          </LoadingButton>
        </div>
        <ReactTable
          headerProps={cellProps}
          footerCellProps={cellProps}
          cellProps={cellProps}
          tableProps={{ className: 'table-sm fs--2' }}
          rows={table.getRowModel().rows}
          {...table}
        />
      </div>
    )
  );
};
