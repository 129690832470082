import { formatDuration, intervalToDuration } from 'date-fns';
import React, { useEffect } from 'react';
export default ({
  endDate,
  checkMs = 1000,
  format = ['days', 'hours', 'minutes', 'seconds'],
  children
}: {
  endDate: Date;
  checkMs?: number;
  format?: string[];
  children?: (duration: string, ms: number) => React.ReactNode;
}) => {
  const [duration, setDuration] = React.useState('');
  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(
        formatDuration(
          intervalToDuration({
            start: new Date(),
            end: new Date(endDate)
          }),
          {
            format
          }
        )
      );
    }, checkMs);
    return () => clearInterval(interval);
  }, [format, endDate, checkMs]);
  //   console.log('countdown', duration);
  return children(duration, new Date(endDate).valueOf() - Date.now());
};
