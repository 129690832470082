import { UseQueryResult } from '@tanstack/react-query';
import Error500 from 'components/errors/Error500';
import React, { JSXElementConstructor } from 'react';
import Skeleton from 'react-loading-skeleton';
export default ({
  error,
  isLoading,
  children,
  LoadingComponent,
  ErrorComponent
}: {
  children: React.ReactNode;
  ErrorComponent?: JSXElementConstructor<{ error: unknown }>;
  LoadingComponent?: JSXElementConstructor<any>;
  error?: any;
  isLoading?: boolean;
}) => {
  const loadingDisplay = LoadingComponent ? <LoadingComponent /> : <Skeleton />;
  const errorDisplay = ErrorComponent ? (
    <ErrorComponent error={error} />
  ) : (
    <Error500 error={error instanceof Error && error} />
  );
  return <>{isLoading ? loadingDisplay : error ? errorDisplay : children}</>;
};
