import React from 'react';
import { EventPrefix } from 'apis/flex/notifications';
import Skeleton from 'react-loading-skeleton';
import { domainIconWidths } from 'config';
export default ({
  isLoading,
  src,
  size = 'md'
}: {
  domain?: EventPrefix;
  isLoading?: boolean;
  src: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}) => {
  const avatarWidth = domainIconWidths[size];
  const borders = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4
  };
  return (
    <div
      style={{ width: avatarWidth, height: avatarWidth }}
      className={`rounded-circle shadow-sm`}
    >
      <div
        style={{ width: avatarWidth, height: avatarWidth }}
        className={`overflow-hidden position-relative rounded-circle`}
      >
        {isLoading ? (
          <Skeleton
            height={avatarWidth}
            width={avatarWidth}
            containerClassName="d-flex"
            className="position-absolute rounded-2 start-50 translate-middle-x"
          />
        ) : (
          <img
            height={avatarWidth}
            className="position-absolute rounded-2 start-50 translate-middle-x"
            src={src}
          />
        )}
        <div
          style={{ width: avatarWidth, height: avatarWidth }}
          className={`position-absolute z-1 start-0 top-0 border border-${borders[size]} border-100 rounded-circle`}
        ></div>
      </div>
    </div>
  );
};
