import DetailCard from 'components/common/detail/DetailCard';
import DomainDetail from 'components/common/DomainDetail';
import WizardInput from 'components/wizard/WizardInput';
import React from 'react';
import { useWatch } from 'react-hook-form';
import RemoteCalendar from './RemoteCalendar';
import { useParams } from 'react-router-dom';
import { Calendar } from 'apis/flex/helpers';

export default () => {
  const { calendarId } = useParams();
  return (
    <DomainDetail<Calendar>
      itemId={calendarId}
      defaultValues={{ color: 'secondary' }}
      domain="calendar"
    >
      <DetailCard title="Info" id="info">
        <WizardInput name="name" label="Name" />
        <WizardInput name="description" label="Description" type="textarea" />
        <WizardInput name="color" label="Color" type="variant" />
      </DetailCard>
      <DetailCard title="Events" id="events">
        <EventsCalendar />
      </DetailCard>
    </DomainDetail>
  );
};
const EventsCalendar = () => {
  const calendarId = useWatch({ name: 'id' });
  return <RemoteCalendar className="shadow-none" calendarIds={[calendarId]} />;
};
