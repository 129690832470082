import { accessDelegationApi } from "apis/flex/users";
import { defaultCrudHookBuilder } from "hooks/useDefaultCrud";
export const useUserAccessDelegation = defaultCrudHookBuilder(
  "UserAccessDelegation",
  accessDelegationApi.user,
  { invalidate: ["Users", "DomainItemAccess"] }
);
export const useItemAccessDelegation = defaultCrudHookBuilder(
  "ItemAccessDelegation",
  accessDelegationApi.item,
  { invalidate: ["Users", "DomainItemAccess"] }
);
