import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import WizardInput, { WizardInputProps } from 'components/wizard/WizardInput';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
export type Setting = {
  label: string;
  onChange: (checked: boolean) => void;
} & Partial<WizardInputProps>;
export default ({ settings }: { settings: Setting[] }) => {
  const methods = useForm({
    values: settings?.reduce(
      (acc, cur) => ({ ...acc, [cur.label]: cur.value || false }),
      {}
    )
  });
  return (
    settings && (
      <FormProvider {...methods}>
        <Dropdown>
          <Dropdown.Toggle
            variant="falcon-default"
            size="sm"
            data-boundary="viewport"
            className={classNames('text-600 dropdown-caret-none')}
          >
            <FontAwesomeIcon icon={faCog} className="fs--2" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {settings.map(setting => (
              // <Dropdown.Item
              //   key={setting.label}
              //   onClick={e => {
              //     e.stopPropagation();
              //     e.preventDefault();
              //   }}
              // >
              <div key={setting.label} className="px-2 text-700">
                <WizardInput
                  type="switch"
                  name={setting.label}
                  formGroupProps={{
                    className: 'mb-auto'
                  }}
                  registerProps={{
                    required: false,
                    onChange: e => {
                      // console.log("setting.onChange", e);
                      setting.onChange(e.target.value);
                    },
                    ...setting.registerProps
                  }}
                  {...setting}
                />
              </div>
              // </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </FormProvider>
    )
  );
};
