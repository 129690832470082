import { CellContext, HeaderContext } from '@tanstack/react-table';
import classNames from 'classnames';
import React, { forwardRef, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { ProcessedColumnDef } from './AdvanceTableProvider';
export const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, className, ...rest }: any, ref: any) => {
    const defaultRef = React.useRef<any>();

    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Form.Check
        type="checkbox"
        className={classNames('form-check fs-0 mb-0', className)}
      >
        <Form.Check.Input type="checkbox" ref={resolvedRef} {...rest} />
      </Form.Check>
    );
  }
);
export default ({
  selection,
  columns
}: {
  selection?: boolean;
  columns: (Omit<ProcessedColumnDef, 'header'> & {
    header: any;
  })[];
}) => {
  const [lastSelected, setLastSelected] = React.useState<string | null>(null);
  return useMemo(() => {
    return selection
      ? [
          {
            id: 'select',
            isSelect: true,
            maxSize: 30,
            headerProps: { className: 'pe-0 ps-2' },
            cellProps: { className: 'pe-0 ps-2' },
            enablePinning: false,
            enableSorting: false,
            enableColumnFilter: false,
            download: false,
            header: ({ table }: HeaderContext<any, any>) => (
              <div className="px-1 tour-select-checkbox">
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler()
                  }}
                />
              </div>
            ),
            cell: ({ row, table }: CellContext<any, any>) => {
              return (
                <div
                  className="px-1 w-100 h-100"
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <IndeterminateCheckbox
                    {...{
                      checked: row.getIsSelected(),
                      disabled: !row.getCanSelect(),
                      // indeterminate: row.getIsSomeSelected(),
                      onChange: row.getToggleSelectedHandler()
                    }}
                  />
                  <div
                    className="w-100 h-100 top-0 start-0 position-absolute"
                    onClick={e => {
                      const isSelected = row.getIsSelected();
                      if (
                        e.shiftKey &&
                        row.getCanMultiSelect() &&
                        !isSelected
                      ) {
                        const rows = table.getRowModel().rows;
                        const start = rows.findIndex(
                          r => r.id === lastSelected
                        );
                        const end = rows.findIndex(r => r.id === row.id);
                        if (start > -1 && end > -1) {
                          const segment = rows.slice(
                            Math.min(start, end),
                            Math.max(start, end) + 1
                          ); // rows.slice(start, end + 1);
                          table.setRowSelection(current => {
                            const newSelection = { ...current };
                            for (const s of segment) {
                              newSelection[s.id] = true;
                            }
                            console.log(
                              'newSelection',
                              segment,
                              newSelection,
                              start,
                              end,
                              Math.min(start, end),
                              Math.max(start, end) + 1
                            );
                            return newSelection;
                          });
                        }
                      } else {
                        row.toggleSelected();
                      }
                      setLastSelected(isSelected ? null : row.id);
                    }}
                  ></div>
                </div>
              );
            }
          } as Omit<ProcessedColumnDef, 'header'> & { header: any }
        ].concat(columns)
      : columns;
  }, [columns, lastSelected]);
};
