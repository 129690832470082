import React from 'react';
import {
  buildConfig,
  canConfigGetData,
  getComponent,
  getFormatterByGroupBy,
  getUniqueAxisValues,
  getValueFormatter,
  GroupByType,
  mapCountDataToAxisData,
  resolveSeriesConfig,
  sqlValueFormatter,
  useHeadlineFigure
} from './helpers';
import useColumnInfo from 'components/common/advance-table-v2/useColumnInfo';
import { getSqlInputType } from 'components/common/advance-table-v2/sql';
import { getReportData, ReportConfig, ReportData } from 'apis/flex/reporting';
import { useQuery } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import useReportConfigs from './useReportConfigs';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import { camelToSentence } from 'helpers/utils';
import ChartLayout from './charts/ChartLayout';

export const ChartPlaceholder = ({ simple, height = null }) => {
  return (
    <>
      <Skeleton height={height || (simple ? 85 : '20rem')} width={'100%'} />
      {/* <div className="p-3">
        <Skeleton count={4} width={'50%'} />
      </div> */}
    </>
  );
};
export default ({
  reportId,
  config: configFromProps,
  simple,
  isLoading: isTileLoading,
  height,
  isEditing,
  variables
}: {
  reportId?: number;
  config?: ReportConfig;
  simple?: boolean;
  isLoading?: boolean;
  height?: number | string;
  isEditing?: boolean;
  variables?: Record<string, any>;
}) => {
  const { data: loadedConfig } = useReportConfigs({
    id: reportId,
    select: data => data[0]
  });
  const config = buildConfig(configFromProps || loadedConfig, variables);
  const { data: sqlCols } = useColumnInfo({
    db: config?.db,
    enabled: !!config
  });
  const getFormatter = (field: string, groupBy?: GroupByType) => {
    const inputType = getSqlInputType(
      sqlCols?.find(
        c => c.COLUMN_NAME === field && c.TABLE_NAME === config?.table
      )
    );
    if (groupBy) {
      return getFormatterByGroupBy(groupBy);
    }
    return sqlValueFormatter(inputType);
  };
  const { data, isLoading } = useQuery<ReportData, any>({
    queryFn: () => getReportData({ config }),
    queryKey: [
      'reportData',
      isEditing
        ? [
            config.db,
            config.table,
            config.filters,
            config.xAxes,
            config.series,
            config.seriesSplitBy,
            config.seriesGroupBy,
            config.compareTo,
            config.aggregation,
            config.aggregationField,
            variables
          ]
        : [reportId, variables]
    ],
    enabled: !!canConfigGetData(config),
    retry: isEditing ? false : 3,
    staleTime: config?.realTime ? 1 : Infinity
  });
  const Component = getComponent(config?.type);
  const countData = mapCountDataToAxisData(data, config);

  const isLoadingMaster = isLoading || !Component || isTileLoading;
  return (
    <>
      <ChartLayout
        config={config}
        isLoading={isLoadingMaster}
        height={height}
        simple={simple}
      >
        <Component
          height={height}
          simple={simple}
          series={resolveSeriesConfig(data, config).flatMap((series, si) =>
            (config?.xAxes || [{ field: null }]).map((_, i) => {
              return {
                name: camelToSentence(series.name),
                //if there are no valid axes, eCharts will error if it can't find the given axis ID
                axisId: config?.xAxes?.length ? i.toString() : undefined,
                data: countData[si]?.[i],
                valueFormatter: getValueFormatter(config)
              };
            })
          )}
          xAxes={config?.xAxes?.map((axis, i) => {
            const formatter = getFormatter(axis.field, axis.groupBy);
            return {
              id: i.toString(),
              data: getUniqueAxisValues(i, config, data).map(formatter)
            };
          })}
          yAxes={[
            {
              id: '0',
              min: undefined,
              max: undefined,
              formatter: getValueFormatter(config)
            }
          ]}
        />
      </ChartLayout>
    </>
  );
};
