import React, { ReactNode, useMemo } from 'react';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Truncate from './Truncate';
import DomainAvatar from './DomainAvatar';
import { FiBox } from 'react-icons/fi';
import DomainIcon from './DomainIcon';
import Skeleton from 'react-loading-skeleton';
import { TagWrapper } from './DomainItemSelector';
import { uniqueId } from 'lodash';
import CustomTooltip from './Tooltip';
import Wrapper from 'helpers/Wrapper';
import { camelToSentence } from 'helpers/utils';
export type SelectorItemData = {
  avatar?: string;
  icon?: ReactNode;
  color?: string;
  label: string;
  info?: Record<string, any>;
  value?: any;
  tags?: ReactNode;
};
export type SelectorItemProps = {
  data: SelectorItemData;
  avatar?: string;
  icon?: ReactNode;
  compact?: boolean;
  isLoading?: boolean;
  color?: string;
  iconWidth?: string | number;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  show?: boolean;
  onNameClick?: (data: SelectorItemData) => void;
  bold?: boolean;
  showIcon?: boolean;
  tooltip?: boolean;
};
export default ({
  data,
  avatar,
  icon = <FiBox />,
  compact,
  isLoading,
  color = 'primary',
  size = 'md',
  show: showName = false,
  onNameClick,
  bold = true,
  showIcon = true,
  tooltip
}: SelectorItemProps) => {
  const margin = compact ? '0.5rem' : '1rem';
  const id = useMemo(() => {
    return uniqueId('item-selector-item');
  }, [isLoading, showName]);
  return (
    <Flex
      className={classNames(
        'align-items-center position-relative d-flex text-start'
      )}
    >
      <CustomTooltip
        // placement="top-start"
        content={
          (!!tooltip || !showName || compact) && (
            <div>
              {data?.label}
              <ItemInfo info={data?.info} />
            </div>
          )
        }
      >
        {showIcon ? (
          <div>
            {data?.avatar || isLoading || !data ? (
              <DomainAvatar
                src={data?.avatar}
                isLoading={isLoading}
                size={size}
              />
            ) : icon || data.icon ? (
              <DomainIcon
                icon={data.icon || icon}
                color={color}
                size={size}
                bg={'100'}
              />
            ) : (
              <DomainAvatar src={avatar} isLoading={isLoading} size={size} />
            )}
          </div>
        ) : (
          <div
            className="position-absolute top-0 start-0 h-100"
            style={{ width: '4rem' }}
          />
        )}
      </CustomTooltip>
      <motion.div
        id={id}
        className="overflow-hidden text-nowrap"
        key={id}
        custom={showName}
        variants={{
          initial: i => {
            // console.log('initial', i);
            return {
              maxWidth: i ? '100%' : 0,
              marginLeft: i ? margin : 0
            };
          },
          animate: i => {
            // console.log('animate', i);
            return {
              maxWidth: i ? '100%' : 0,
              marginLeft: i ? margin : 0
            };
          },
          hover: { maxWidth: '100%', marginLeft: margin }
        }}
      >
        <h6
          className={classNames('d-flex mb-0', {
            'btn-link': !!onNameClick,
            'fw-semi-bold': !!bold,
            'fw-normal': !bold
          })}
          onClick={() => onNameClick && onNameClick(data)}
        >
          {isLoading ? (
            <Skeleton width={120} height={'1rem'} />
          ) : (
            <Truncate length={compact ? 50 : 500}>{data.label}</Truncate>
          )}
        </h6>
        {!compact && size !== 'xs' && (
          <>
            {isLoading ? (
              <TagWrapper className="my-1">
                <Skeleton
                  containerClassName="d-flex gap-1"
                  count={2}
                  width={50}
                />
              </TagWrapper>
            ) : data?.tags ? (
              <TagWrapper className="my-1">{data.tags}</TagWrapper>
            ) : null}
            <ItemInfo info={data?.info} maxItems={1} />
          </>
        )}
      </motion.div>
      {/* )} */}
    </Flex>
  );
};
const ItemInfo = ({
  info,
  maxItems = 5
}: {
  info?: Record<string, any>;
  maxItems?: number;
}) => {
  const validKeys = info && Object.keys(info).filter(name => !!info[name]);
  return validKeys?.slice(0, maxItems).map(name => (
    // <CustomTooltip
    //   key={name}
    //   content={
    //     validKeys?.length > 1 || !show ? (
    //       <div>
    //         {validKeys.map(name => (
    //           <div
    //             key={name}
    //             className="fs--2 text-500 d-flex align-items-center"
    //           >
    //             {camelToSentence(name)}: {info[name]}
    //           </div>
    //         ))}
    //       </div>
    //     ) : null
    //   }
    // >
    <div
      key={name}
      className="fs--2 lh-1 mt-1 text-500 d-flex align-items-center"
    >
      {info[name]}
    </div>
    // </CustomTooltip>
  ));
};
