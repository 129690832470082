import { useMutation } from "@tanstack/react-query";
import { commitMedia, updateMedia, uploadMedia } from "apis/flex/helpers";
import { blobUrlToFile } from "helpers/data";
import {
  getItemFromStore,
  setItemToStore,
  setPropertyByDotNotation
} from "helpers/utils";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
const useMediaForm = ({
  authItemCollection,
  authItemId,
  companyId,
  liveUpload = true,
  awaitCommit = true,
  ...useFormProps
} = {}) => {
  const formMethods = useForm(
    useFormProps
  );
  const { clearErrors, setError, setValue, getValues } = formMethods;
  const [fileUrls, setFileUrls] = useState([]);
  const [fileFields, setFileFields] = useState([]);
  const [fileFieldSettings, setFileFieldSettings] = useState({});
  const getFileValues = useCallback(() => {
    return getValues(fileFields).reduce((a, b, i) => {
      a[fileFields[i]] = b;
      return a;
    }, {});
  }, [getValues, fileFields]);
  const { mutate: mutateFile } = useMutation({
    mutationFn: ({ id, file }) => {
      const type = file.type;
      console.log("converting blob to file", id, file.blob);
      return blobUrlToFile(file.blob, file.path || file.name, {
        type
      }).then((fileObj) => {
        console.log("converted blob to file", id, [fileObj]);
        return uploadMedia(
          { [id]: [fileObj] },
          fileFieldSettings[id],
          !authItemCollection,
          true
        );
      });
    },
    onSuccess: (d, { id, file }) => {
      console.log("Uploaded file for", id, d[id][0]);
      updateFile(file, id, {
        uploading: false,
        uploaded: true,
        uploadPath: d[id][0],
        isTemp: true
      });
    },
    onError: (err, { id, file }) => {
      setError(id.toString(), err);
      updateFile(file, id, {
        uploading: false,
        uploaded: false,
        uploadError: err
      });
    }
  });
  const updateFile = useCallback(
    (file, id, vals) => {
      const files = getValues(id);
      if (!files || !file) return;
      const arr = Array.isArray(files) ? files : [files];
      const fi = arr.findIndex((f) => f?.blob === file.blob);
      if (fi >= 0) {
        Object.assign(arr[fi], vals);
        setValue(id, arr);
      }
    },
    [getValues, setValue]
  );
  const handleFileUpload = useCallback(
    (id, file) => {
      setFileUrls([...fileUrls, file.blob]);
      clearErrors(id);
      updateFile(file, id, {
        uploading: true,
        uploadError: false,
        uploaded: false,
        uploadPath: null
      });
      mutateFile({
        id,
        file
      });
    },
    [fileUrls, clearErrors, mutateFile, setFileUrls, updateFile]
  );
  const getMediaFields = useCallback(() => {
    const vals = getFileValues();
    return Object.keys(vals).reduce((a, k) => {
      const arr = Array.isArray(vals[k]) ? vals[k] : [vals[k]];
      if (vals[k] && arr.some((f) => (f.blob || f.preview) && (f.name || f.path))) {
        a[k] = arr;
      }
      return a;
    }, {});
  }, [getFileValues]);
  const scanForMedia = useCallback(() => {
    const vals = getMediaFields();
    Object.keys(vals).forEach((k) => {
      const toUpload = vals[k].filter((d) => d).map((f, i) => Object.assign(f, { index: i })).filter((f) => {
        return f.blob && !fileUrls.includes(f.blob) && !f.uploading && !f.uploadPath;
      });
      toUpload.map((file) => handleFileUpload(k, file));
    });
  }, [getMediaFields, fileUrls, handleFileUpload, liveUpload]);
  const storedCommittedFiles = useMemo(
    () => getItemFromStore("committedFiles", {}),
    []
  );
  const [commitedFiles, setCommitedFiles] = useState({});
  useEffect(() => {
    setCommitedFiles(storedCommittedFiles);
  }, [storedCommittedFiles]);
  useEffect(() => {
    setItemToStore("committedFiles", {
      ...storedCommittedFiles,
      ...commitedFiles
    });
  }, [commitedFiles]);
  const getCommittedValues = () => {
    const files = getMediaFields();
    const vals = getValues();
    Object.keys(files).forEach((qid) => {
      const qFiles = files[qid].map((f) => {
        return commitedFiles[f.uploadPath] || f.uploadPath;
      });
      setPropertyByDotNotation(vals, qid, qFiles);
    }, {});
    return vals;
  };
  const { mutate: commitAndSubmit } = useMutation({
    mutationFn: async ({ files }) => {
      let vals = getValues();
      const ids = Object.keys(files).flatMap(
        (qid) => files[qid].filter((f) => f.isTemp).map((f) => f.uploadPath)
      );
      const resp = awaitCommit ? await commitMedia(ids, authItemCollection, authItemId) : null;
      const committedLookup = ids.reduce(
        (a, b, i) => ({ ...a, [b]: awaitCommit ? resp.data[i] : ids[i] }),
        {}
      );
      for (const qid of Object.keys(files)) {
        const qFiles = files[qid].map((f) => {
          return committedLookup[f.uploadPath] || f.uploadPath;
        });
        vals = setPropertyByDotNotation(vals, qid, qFiles);
      }
      console.log("setting committed files", committedLookup, vals);
      setCommitedFiles((c) => ({
        ...c,
        ...committedLookup
      }));
      return vals;
    },
    onSuccess: (newVals, { onComplete }) => {
      console.log("submitting with new vals", newVals);
      onComplete({
        ...newVals
      });
    }
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const handleSubmit = useCallback(
    (onComplete, onError) => {
      const startSubmit = () => {
        setSubmitting(true);
        scanForMedia();
        const waitStart = Date.now();
        const checkFiles = () => {
          const files = getMediaFields();
          console.log(
            "checking files for submit",
            files,
            isEmpty(files),
            Object.keys(files).every((k) => files[k].every((file) => file.uploaded))
          );
          if (isEmpty(files) || Object.keys(files).every((k) => files[k].every((file) => file.uploaded))) {
            if (!isEmpty(files)) {
              return commitAndSubmit(
                { files, onComplete },
                {
                  onError: (e) => onError && onError(e),
                  onSettled: () => setSubmitting(false)
                }
              );
            }
            setSubmitting(false);
            return onComplete({
              ...getCommittedValues()
            });
          }
          const error = Object.keys(files).find(
            (k) => files[k].map((file) => file.uploadError).filter((f) => f)[0]
          );
          if (error) {
            console.error("Error uploading files on submit", error);
            if (!onError) {
              return;
            }
            setSubmitting(false);
            return onError(error);
          }
          if (waitStart < Date.now() - 1e4) {
            setSubmitting(false);
            return onError ? onError({ message: "Timed out" }) : new Error("Timed out");
          }
          return setTimeout(checkFiles, 300);
        };
        setTimeout(checkFiles, 300);
      };
      return formMethods.handleSubmit(startSubmit, onError);
    },
    [fileFields]
  );
  useEffect(() => {
    if (!liveUpload) return;
    scanForMedia();
  }, [fileFields]);
  const registerMedia = useCallback(
    (field, settings) => {
      if (!fileFields.includes(field)) {
        console.log("registering media", field, fileFields);
        setFileFields((f) => {
          const fields = /* @__PURE__ */ new Set([...f, field]);
          return [...fields];
        });
        const recursivelyCleanSettings = (settings2, currentLevel = 0) => {
          if (!settings2 || currentLevel > 5) return;
          return Object.keys(settings2).reduce((a, b) => {
            if (["boolean", "string", "number"].includes(typeof settings2[b])) {
              return { ...a, [b]: settings2[b] };
            }
            if (!!settings2[b] && typeof settings2[b] === "object") {
              return {
                ...a,
                [b]: recursivelyCleanSettings(settings2[b], currentLevel + 1)
              };
            }
            return a;
          }, {});
        };
        const cleanSettings = recursivelyCleanSettings(settings);
        setFileFieldSettings({ ...fileFieldSettings, [field]: cleanSettings });
      } else if (liveUpload) {
        scanForMedia();
      }
    },
    [fileFields]
  );
  const { mutate: update } = useMutation({
    mutationFn: ({ authItemId: authItemId2, fileIds }) => {
      return updateMedia(fileIds, { authItemCollection, authItemId: authItemId2 });
    }
  });
  const setAuthItemId = useCallback(
    (commitedFiles2) => (authItemId2, cb = () => {
    }) => {
      const files = getMediaFields();
      const fileIds = Object.keys(files).flatMap(
        (f) => files[f].map(
          (file) => commitedFiles2[file.uploadPath] || file.uploadPath
        )
      );
      if (!authItemId2) {
        throw new Error("Cannot set authItemId as " + authItemId2);
      }
      if (fileIds.length) {
        return update({ authItemId: authItemId2, fileIds }, { onSuccess: cb });
      }
      cb();
    },
    [getMediaFields]
  );
  return {
    ...formMethods,
    handleSubmit,
    registerMedia,
    setAuthItemId: useMemo(
      () => setAuthItemId(commitedFiles),
      [setAuthItemId, commitedFiles]
    ),
    triggerUpload: scanForMedia,
    isSubmitting
  };
};
export default useMediaForm;
