import React, { useMemo } from 'react';
import { Button, Card } from 'react-bootstrap';
import FalconCardHeader, { FalconCardHeaderProps } from '../FalconCardHeader';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import RenderWhenVisible from '../RenderWhenVisible';
import { AnimatePresence, motion } from 'framer-motion';
import { uniqueId } from 'lodash';
import styled from 'styled-components';
import { useDetailPage } from './DetailPage';
import { ErrorBoundary } from '@sentry/react';
import Error500 from 'components/errors/Error500';
import InfoBadge from '../InfoBadge';

const CardPlaceholder = () => (
  <>
    <Skeleton className="mb-2 w-25" />
    <Skeleton className="mb-3 w-75" height={38} />
    <Skeleton className="mb-2 w-25" />
    <Skeleton className="mb-3 w-75" height={38} />
  </>
);
export const ZCol = styled.div`
  :hover {
    z-index: 2;
  }
`;
const DetailCard = ({
  title = null,
  isLoading = false,
  children,
  isLast = false,
  buttons = null,
  subtitle = null,
  colProps = null,
  className = null,
  headerProps,
  id,
  animate = true,
  bodyProps = null,
  info,
  ...rest
}: {
  title?: React.ReactNode;
  isLoading?: boolean;
  children: React.ReactNode;
  isLast?: boolean;
  buttons?: React.ReactNode;
  subtitle?: React.ReactNode;
  colProps?: any;
  className?: string;
  headerProps?: Omit<FalconCardHeaderProps, 'title' | 'subtitle'>;
  id: string;
  animate?: boolean;
  bodyProps?: any;
  info?: React.ReactNode;
}) => {
  const { isLoading: pageLoading } = useDetailPage() || {};
  const uid = useMemo(() => uniqueId(id || 'detail-card'), [id]);
  return (
    <ZCol
      {...colProps}
      className={classNames('col-xs-12', colProps?.className)}
    >
      <AnimatePresence>
        <motion.div
          id={uid}
          initial={!!animate && { x: '-100%' }}
          animate={{ x: '0%' }}
          exit={!!animate && { x: '-100%' }}
        >
          <ErrorBoundary
            fallback={err => (
              <Error500
                {...rest}
                className={classNames({ 'mb-3': !isLast }, className)}
                button={
                  <Button size="sm" onClick={err.resetError}>
                    Retry
                  </Button>
                }
                error={err.error}
              />
            )}
          >
            <Card
              {...rest}
              className={classNames({ 'mb-3': !isLast }, className)}
            >
              {title && (
                <FalconCardHeader
                  subtitle={subtitle}
                  title={title}
                  {...headerProps}
                  className={classNames(
                    'sticky-top z-2 top-navbar-height',
                    headerProps?.className
                  )}
                >
                  {info && <InfoBadge className="fs-1">{info}</InfoBadge>}
                </FalconCardHeader>
              )}
              <Card.Body {...bodyProps}>
                <RenderWhenVisible placeholder={<CardPlaceholder />}>
                  {isLoading || pageLoading ? <CardPlaceholder /> : children}
                </RenderWhenVisible>
              </Card.Body>
              {buttons && (
                <Card.Footer className="text-end">{buttons}</Card.Footer>
              )}
            </Card>
          </ErrorBoundary>
        </motion.div>
      </AnimatePresence>
    </ZCol>
  );
};
export default DetailCard;
