import { getSqlCols } from "apis/flex/adminTable";
import { useQuery } from "@tanstack/react-query";
import { uniqBy } from "lodash";
export default ({
  db,
  tables,
  ...rest
}) => useQuery({
  queryKey: ["ColumnInfo", db, tables],
  queryFn: () => getSqlCols(tables, db).then((d) => d.data),
  staleTime: Infinity,
  select: (d) => uniqBy(d, (c) => c.COLUMN_NAME + " " + c.TABLE_NAME),
  ...rest
});
