import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
const PageLoader = ({
  message,
  title,
  height,
  info
}: {
  message?: ReactNode;
  title?: string;
  height?: number | string;
  info?: ReactNode;
}) => {
  return (
    <div
      className={
        'w-100 vh-' +
        (height || 100) +
        ' d-flex flex-column flex-direction-column justify-content-center align-items-center '
      }
    >
      <div
        className={
          'w-100 d-flex flex-column flex-direction-column justify-content-center align-items-center '
        }
      >
        <Spinner style={{ margin: 'auto' }} animation="border" role="status">
          <span className={'visually-hidden'}>{title || 'Loading...'}</span>
        </Spinner>
        {message && <h6 className="mt-1 text-center">{message}</h6>}
        {info && <div className="fs--2 text-center w-75">{info}</div>}
      </div>
    </div>
  );
};
export default PageLoader;
